import constants from '../../components/constants';

const subscriptionConfirmationTemplate = props => {
  const {first_name, last_name, email} = props || {};

  const pretty =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src=${constants.publicmind_logo} alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <table width="100%" cellpadding="20" cellspacing="0" bgcolor="#f4f4f4">
          <tr>
            <td>
              <p style="text-align: center; margin: 0 64px; line-height: 2;">
                Thank you for subscribing to ${pretty}. You will start to receive updates on their priorites and projects.
              </p>
              <br />
              <p style="text-align: center; margin: 0 64px; line-height: 2;">
                If there are any changes or if you have any questions, please feel free to reach out to us.
              </p>
              <br/>
            </td>
          </tr>
        </table>
        <br />
        <p style="text-align: center;">Sent from PublicMind</p>
        <br />
        <div style="text-align: center;">
          <a href=${constants.help_center} style="margin: 0 8px;">Help Center</a>
          <a href=${constants.privacy_policy} style="margin: 0 8px;">Privacy Policy</a>
          <a href=${constants.terms_of_service} style="margin: 0 8px;">Terms of Service</a>
        </div>
      </body>
    </html>
  `;
};

export default subscriptionConfirmationTemplate;
