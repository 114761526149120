import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import constants from '../../components/constants';
import {
  ArrayFormikTextfield,
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {DragAndDropUploader, Uploader} from '../../components/inputs/uploader';
import {useAuth} from '../../hooks/use-auth';
import './CreateGroup.css';

const CreateGroup = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    state: {id: user_id, profile},
    createGroup,
    updateProfile,
  } = useAuth();

  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      id,
      name: '',
      bio: '',
      image: null,
      type: 'public',
      admins: [user_id],
      members: [user_id],
      tags: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      bio: Yup.string().required('About is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {id: group_id} = values;
        const {group_ids} = profile || {};

        const now = dateToTimestamp();
        const grp = {
          ...values,
          created: now,
          updated: now,
        };
        const {success, error} = await createGroup(grp);

        if (success) {
          const groups = [...(group_ids || []), group_id];
          const {success: profileSuccess, error: profileError} =
            await updateProfile({
              id: user_id,
              group_ids: groups,
            });

          if (profileSuccess) {
            formik.resetForm();
            navigate('/groups');
            track('group_created', {group: grp});
          } else if (profileError) {
            helpers.setStatus({success: false});
            helpers.setErrors({submit: profileError.message});
            helpers.setSubmitting(false);
          }
        } else if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error.message});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!id) {
    return <div className="create-group">Not a valid link.</div>;
  }

  return (
    <div className="page-container">
      <div className="create-group">
        <div className="create-group__header">
          <h4>Create New Group</h4>
        </div>
        <form className="create-group__form">
          <FormikTextfield
            formik={formik}
            name="name"
            type="text"
            header="Group Name"
            className="create-group__input"
          />
          <MultilineFormikTextfield
            rows={5}
            formik={formik}
            name="bio"
            type="text"
            header="About"
            className="create-group__textarea"
          />
          <ArrayFormikTextfield
            formik={formik}
            name="tags"
            type="text"
            header="Tags"
            comma={true}
            className="create-group__input"
          />
          <label className="create-group__label">Attach a Group Logo</label>
          <DragAndDropUploader
            file={file}
            limit={constants.file_20mb}
            setFile={setFile}
            types={[
              ...constants.image_mime_types,
              ...constants.video_mime_types,
            ]}
            className="create-group__file-upload"
          />
          <Uploader
            file={file}
            limit={constants.file_20mb}
            setFile={setFile}
            types={[
              ...constants.image_mime_types,
              ...constants.video_mime_types,
            ]}
            className="create-group__file-input"
          />
          {formik.errors.submit && (
            <p className="create-group__error">{formik.errors.submit}</p>
          )}
          <div className="create-group__submit-container">
            <button
              className="create-group__submit-button"
              disabled={formik.isSubmitting}
              onClick={() => {
                if (file) {
                  formik.setFieldValue('image', file);
                } else {
                  formik.setFieldValue('image', null);
                }
                formik.handleSubmit();
              }}>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroup;
