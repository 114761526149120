import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ChainItem} from '../../components/item-details/chain-item';
import {Messenger} from '../../components/messages/messenger';
import {useAuth} from '../../hooks/use-auth';
import {useMessage} from '../../hooks/use-message';

//TODO: add current opp data to upper right of screen
const MessagesOverview = ({}) => {
  const {state} = useLocation();
  const {
    state: {id},
  } = useAuth();

  const {
    state: {chain_ids, chains, subscriptions, chains_loaded},
    getChains,
    attachChainListener,
  } = useMessage();

  const {chain_id} = state || {};

  const [current, setCurrent] = useState(null);
  const [sortedChains, setSorted] = useState(chain_ids);

  // FETCH CHAINS AND ATTACH LISTENER - ONCE
  useEffect(() => {
    // LISTENER
    if (subscriptions['chains'] === undefined) {
      attachChainListener({
        filter: {
          type: {eq: 'direct'},
          members: {contains: id},
        },
      });
    }
  }, []);

  useEffect(() => {
    if (chains_loaded && chain_ids.length) {
      const sorted = [...chain_ids].sort((a, b) => {
        const a_obj = chains[a];
        const b_obj = chains[b];
        return b_obj.last_timestamp - a_obj.last_timestamp;
      });

      setCurrent(chain_id || sorted[0]);
    }
  }, [chains_loaded]);

  // SORT MESSAGE CHAINS
  useEffect(() => {
    const sorted = [...chain_ids].sort((a, b) => {
      const a_obj = chains[a];
      const b_obj = chains[b];
      return b_obj.last_timestamp - a_obj.last_timestamp;
    });
    setSorted(sorted);
  }, [chain_ids, chains]);

  return (
    <div className="page-container">
      <div className="grid-container">
        <div className="grid-4">
          <p className="text-left text-secondary">Messages</p>
          <MessagesChains
            sortedChains={sortedChains}
            chains={chains}
            current={current}
            setCurrent={setCurrent}
          />
        </div>
        <div className="grid-8">
          <Messenger chain_id={current} organization_id={null} header={true} />
        </div>
      </div>
    </div>
  );
};

const MessagesChains = ({sortedChains, chains, current, setCurrent}) => {
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);

  const handleMouseEnter = () => {
    setShowList(true);
  };

  const handleMouseLeave = () => {
    setShowList(false);
  };

  return (
    <div
      className="message-chain-scrollview"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {sortedChains &&
        sortedChains?.map(id => (
          <ChainItem
            key={id}
            item={chains[id]}
            active={id === current}
            onClick={() => setCurrent(id)}
          />
        ))}
      {!sortedChains && (
        <>
          <p className="text-secondary text-12">
            Messages are started once you connect with another member on an
            opportunity
          </p>
          <button
            onClick={() => {
              navigate('/opportunities');
            }}>
            Go to Inbox
          </button>
        </>
      )}
    </div>
  );
};

export default MessagesOverview;
