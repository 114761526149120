var NUMBER ={
  '零':0,
  '一':1,
  '二':2,
  '兩':2,
  '三':3,
  '四':4,
  '五':5,
  '六':6,
  '七':7,
  '八':8,
  '九':9,
  '十':10,
  '廿':20,
  '卅':30,
};

var WEEKDAY_OFFSET ={
  '天':0,
  '日':0,
  '一':1,
  '二':2,
  '三':3,
  '四':4,
  '五':5,
  '六':6,
};

exports.NUMBER = NUMBER;
exports.WEEKDAY_OFFSET = WEEKDAY_OFFSET;

exports.zhStringToNumber=function(text){
  var number = 0;
  for(var i=0; i<text.length ;i++){
    var char = text[i];
    if(char === '十'){
      number = number=== 0 ? NUMBER[char] : (number * NUMBER[char]);
    }else{
      number += NUMBER[char];
    }
  }
  return number;
};

exports.zhStringToYear=function(text){
  var string = '';
  for(var i=0; i<text.length ;i++){
    var char = text[i];
    string = string + NUMBER[char];
  }
  return parseInt(string);
};
