import {dateToTimestamp} from '../api/dates';
import {
  fetchThread,
  fetchThreadContents,
  fetchThreads,
  fetchThreadSubs,
  removeThreadContent,
  setThread,
  setThreadContent,
  setThreadSub,
  threadListener,
  updateThreadChain,
  updateThreadContentMessage,
  updateThreadSub,
} from '../api/graphql';
import {getFileURL, uploadFile} from '../api/s3';
import {sanitizeMarkdown, sendNotification} from '../utils/utils';
import createDataContext from './create-data-context';

const threadReducer = (state, action) => {
  const {
    id,
    ids,
    mapped_ids,
    thread_id,
    thread,
    threads,
    content,
    contents,
    update,
    thread_subs,
    sub,
    nextToken,
    filter,
  } = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'getSubs':
      return {...state, thread_subs, thread_subs_loaded: true};

    case 'setSub':
      return {
        ...state,
        thread_subs: {
          ...state.thread_subs,
          [thread_id]: {...state.thread_subs?.[thread_id], ...update},
        },
      };
    case 'updateSub':
      const current_sub = state.thread_subs?.[thread_id];
      return {
        ...state,
        thread_subs: {
          ...state.thread_subs,
          [thread_id]: {
            ...current_sub,
            ...update,
            previous_read: current_sub?.last_read,
          },
        },
      };
    case 'getThreads':
      return {
        ...state,
        thread_ids: ids,
        threads: {...state.threads, ...threads},
        threads_loaded: true,
      };
    case 'getContents':
      return {
        ...state,
        content_ids: {...state.content_ids, [thread_id]: mapped_ids},
        thread_content: {...state.thread_content, ...contents},
      };
    case 'setThread':
      return {
        ...state,
        thread_ids: [id, ...state.thread_ids],
        threads: {...state.threads, [id]: thread},
      };
    case 'setContent':
      const {parent} = content;
      const current_thread = {...state.content_ids?.[thread_id]};
      if (parent) {
        current_thread[parent].push(id);
      } else {
        current_thread[id] = [];
      }

      return {
        ...state,
        thread_content: {...state.thread_content, [id]: content},
        content_ids: {
          ...state.content_ids,
          [thread_id]: current_thread,
        },
      };
    case 'deleteThread':
      return {...state, thread_content: {...state.thread_content, [id]: null}};
    case 'getThread':
      return {...state, threads: {...state.threads, [id]: thread}};
    case 'updateThread':
      return {
        ...state,
        threads: {
          ...state.threads,
          [id]: {...(state.threads?.[id] ?? {}), ...update},
        },
      };
    case 'updateContent':
      return {
        ...state,
        thread_content: {
          ...state.thread_content,
          [id]: {...(state.thread_content?.[id] ?? {}), ...update},
        },
      };
    case 'addSub':
      return {
        ...state,
        subscriptions: {...state.subscriptions, [id]: sub},
      };
    case 'paginateThreads':
      return {
        ...state,
        threads: {...state.threads, ...threads},
        thread_token: nextToken,
        thread_search: [...state.thread_search, ...ids],
        thread_filter: filter,
        threads_search_loaded: true,
      };
    case 'searchThreads':
      return {
        ...state,
        threads: {...state.threads, ...threads},
        thread_token: nextToken,
        thread_search: ids,
        thread_filter: filter,
        threads_search_loaded: true,
      };
    default:
      return state;
  }
};

const defaultUpdate = dispatch => update => {
  try {
    dispatch({type: 'default', payload: update});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getThreadSubscriptions = dispatch => async params => {
  try {
    const {items} = await fetchThreadSubs(params);

    const thread_subs = {};

    items.forEach(item => {
      const {thread_id} = item;
      thread_subs[thread_id] = item;
    });

    const payload = {thread_subs};
    dispatch({type: 'getSubs', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateThreadSubscription = dispatch => async update => {
  try {
    const {thread_id} = update;
    await updateThreadSub(update);

    const payload = {thread_id, update};
    dispatch({type: 'updateSub', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createThreadSubscription = dispatch => async update => {
  try {
    const {thread_id} = update;
    await setThreadSub(update);

    const payload = {thread_id, update};
    dispatch({type: 'setSub', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getThreads = dispatch => async params => {
  try {
    const {items} = await fetchThreads(params);

    const sorted = items.sort((a, b) => b?.updated - a?.updated);
    const threads = {};
    const ids = await Promise.all(
      sorted.map(async item => {
        const {id, media} = item;
        if (media?.length) {
          const downloaded = await downloadMedia(media);
          item.media = downloaded;
        }
        threads[id] = item;
        return id;
      }),
    );
    const payload = {ids, threads};
    dispatch({type: 'getThreads', payload});
    return {success: true, error: null, data: ids};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getContents = dispatch => async (params, thread_id) => {
  try {
    const {items} = await fetchThreadContents(params);

    const contents = {};
    const mapped_ids = {};
    const ids = items.map(async item => {
      const {id, parent, media} = item;
      if (media?.length) {
        const downloaded = await downloadMedia(media);
        item.media = downloaded;
      }
      contents[id] = item;
      if (parent) {
        if (mapped_ids[parent] === undefined) {
          mapped_ids[parent] = [];
        }
        mapped_ids[parent].push(id);
      } else {
        if (mapped_ids[id] === undefined) {
          mapped_ids[id] = [];
        }
      }
      return id;
    });

    const payload = {ids, mapped_ids, contents, thread_id};
    dispatch({type: 'getContents', payload});
    return {success: true, error: null, data: ids};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createThread = dispatch => async thread => {
  try {
    const {id, media} = thread;
    if (media?.length) {
      // UPLOAD TO S3
      const mapped_media = [];
      const uploaded_media = await Promise.all(
        media.map(async file => {
          const {name, type, size} = file;
          const key = `${id}/${name}`;
          const response = await uploadFile(key, file, {
            accessLevel: 'public',
            contentType: type,
            onProgress: ({transferredBytes, totalBytes}) => {},
          });
          mapped_media.push({
            key,
            type,
            size,
            url: URL.createObjectURL(file),
          });
          return {key, type, size};
        }),
      );

      await setThread({...thread, media: uploaded_media});
      const payload = {id, thread: {...thread, media: mapped_media}};
      dispatch({type: 'setThread', payload});
    } else {
      await setThread(thread);
      const payload = {id, thread};
      dispatch({type: 'setThread', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createThreadContent = dispatch => async content => {
  try {
    const {id, thread_id, owner_id, media} = content;

    if (media?.length) {
      // UPLOAD TO S3
      const uploaded_media = await Promise.all(
        media.map(async file => {
          const {name, type, size} = file;
          const key = `${id}/${name}`;
          const response = await uploadFile(key, file, {
            accessLevel: 'public',
            contentType: type,
            onProgress: ({transferredBytes, totalBytes}) => {},
          });
          return {key, type, size};
        }),
      );

      await setThreadContent({...content, media: uploaded_media});
    } else {
      await setThreadContent(content);
    }
    const update = {
      id: thread_id,
      updated: dateToTimestamp(),
      last_sender: owner_id,
      last_content: id,
    };
    await updateThreadChain(update);
    const payload = {id, update};
    dispatch({type: 'updateThread', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const deleteThreadContent = dispatch => async content => {
  try {
    const {id, thread_id, owner_id} = content;
    await removeThreadContent(content);
    const payload = {id, content};
    dispatch({type: 'deleteThread', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getThread = dispatch => async id => {
  try {
    const thread = await fetchFullThread(id);

    const payload = {id, thread};
    dispatch({type: 'getThread', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateThread = dispatch => async update => {
  try {
    const {id, media} = update;
    await updateThreadChain(update);
    const payload = {id, update};
    dispatch({type: 'updateThread', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateContent = dispatch => async update => {
  try {
    const {id, media} = update;

    if (media?.length) {
      // DATA FOR CONTEXT
      const mapped = [];
      // DATA FOR DYNAMO
      const uploaded_media = await Promise.all(
        media.map(async file => {
          const {name, type, size} = file;
          // IF ALREADY UPLOADED, RETURN AS IS
          if (file.key) {
            mapped.push(file);
            return {key: file.key, type, size};
          }

          const key = `${id}/${name}`;
          const response = await uploadFile(key, file, {
            accessLevel: 'public',
            contentType: type,
            onProgress: ({transferredBytes, totalBytes}) => {},
          });
          mapped.push({key, type, size, url: URL.createObjectURL(file)});
          return {key, type, size};
        }),
      );
      await updateThreadContentMessage({...update, media: uploaded_media});
      const payload = {id, update: {...update, media: mapped}};
      dispatch({type: 'updateContent', payload});
    } else {
      await updateThreadContentMessage(update);

      const payload = {id, update};
      dispatch({type: 'updateContent', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const attachThreadListener = dispatch => async (vars, thread_id, user_id) => {
  try {
    if (!thread_id) {
      return;
    }
    const onEvent = async ({value}) => {
      const {id, content, owner_id, media} = value;
      if (media?.length) {
        const downloaded = await downloadMedia(media);
        value.media = downloaded;
      }
      // UPDATE MESSAGES
      const payload = {id, thread_id, content: value};
      dispatch({type: 'setContent', payload});
      if (owner_id !== user_id) {
        await sendNotification({
          title: `New Message from ${owner_id.split('@')[0]}`,
          content: sanitizeMarkdown(content),
          thread_id,
          id,
        });
      }
      // UPDATE THREAD OBJECT
      const thread = await fetchThread(thread_id);
      if (thread) {
        const payload = {id: thread.id, thread};
        dispatch({type: 'getThread', payload});
      }
    };
    const sub = threadListener(vars, onEvent);
    const payload = {sub, id: thread_id};
    dispatch({type: 'addSub', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const searchThreads = dispatch => async (filter_obj, options) => {
  try {
    const {limit, nextToken} = options || {};
    if (!nextToken) {
      dispatch({
        type: 'default',
        payload: {thread_search: [], thread_filter: filter_obj},
      });
    } else {
      dispatch({type: 'default', payload: {thread_filter: filter_obj}});
    }

    const filter = threadToFilter(filter_obj);

    const {items, nextToken: first_token} = await fetchThreads({
      ...options,
      filter,
    });

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    while (all_items.length < limit && token && i < 100) {
      const {items, nextToken} = await fetchThreads({
        limit,
        nextToken: token,
        filter,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const threads = {};
    const ids = await Promise.all(
      all_items.map(async thread => {
        const {id} = thread;

        threads[id] = thread;
        return id;
      }),
    );

    const payload = {ids, threads, filter: filter_obj, nextToken: token};

    if (nextToken) {
      dispatch({type: 'paginateThreads', payload});
    } else {
      dispatch({type: 'searchThreads', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const downloadMedia = async media => {
  try {
    if (media?.length) {
      const downloaded = await Promise.all(
        media.map(async file => {
          const {key} = file;
          const {expiresAt, url} = await getFileURL(key);
          return {...file, url};
        }),
      );
      return downloaded;
    }
    return [];
  } catch (err) {
    throw err;
  }
};

const fetchFullThread = async (id, options) => {
  try {
    const thread = await fetchThread(id, options);
    if (thread) {
      const {media} = thread;
      // FETCH THE PROFILE URL
      if (media?.length) {
        const downloaded = await downloadMedia(media);
        thread.media = downloaded;
      }
      return thread;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
};

const default_thread_filter = {
  title: '',
  content: '',
  type: 'thread',
};

const defaultValues = {
  thread_ids: [],
  threads: {},
  content_ids: {},
  thread_content: {},
  thread_subs: {},
  subscriptions: {},
  threads_loaded: false,
  thread_subs_loaded: false,
  thread_filter: default_thread_filter,
  thread_search: [],
  thread_token: null,
  threads_search_loaded: false,
  error: null,
};

export const {Provider, Context} = createDataContext(
  threadReducer,
  {
    defaultUpdate,
    getThreadSubscriptions,
    updateThreadSubscription,
    createThreadSubscription,
    getThreads,
    getContents,
    createThread,
    createThreadContent,
    deleteThreadContent,
    getThread,
    updateThread,
    updateContent,
    attachThreadListener,
    searchThreads,
  },
  defaultValues,
);

const handleErrors = (err, dispatch) => {
  const {data, errors, code} = err;
  console.log('THREAD ERROR', err);
  let error = 'Something went wrong.';

  if (code) {
    switch (code) {
      default:
        break;
    }
  }

  const top_error = errors && errors[0];
  if (top_error) {
    switch (top_error.errorType) {
      case 'DynamoDB:ConditionalCheckFailedException':
        error = 'Item already exists.';
        break;
      default:
        break;
    }
  }

  dispatch({
    type: 'error',
    payload: error,
  });
  return {success: false, error};
};

const threadToFilter = obj => {
  const filter = {and: [{security_level: {ne: 'secure'}}]};
  const fields = Object.keys(obj);
  fields.forEach(field => {
    const values = obj[field];

    // IGNORE NULL
    if (!values) {
      return;
    }

    switch (field) {
      case 'title':
      case 'content':
        filter.and.push({[field]: {contains: values}});
        break;
      case 'type':
        filter.and.push({[field]: {eq: values}});
        break;
      default:
        return;
    }
  });
  return filter;
};
