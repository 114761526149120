import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import {fetchPreview} from '../../api/previews';
import './MagicMarkdown.css';
function convertUrlsToMarkdown(text) {
  if (typeof text !== 'string') {
    return String(text || '');
  }

  const urlRegex =
    /\b(?!(?:e\.g\.|i\.e\.|etc\.|vs\.|fig\.|eq\.)(?:\s|$))(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const codeBlockRegex = /(`[^`\n]+`|```[\s\S]+?```)/g;

  const specialPatterns = [
    {regex: mentionRegex, handler: match => match},
    {regex: emailRegex, handler: match => match},
    {regex: markdownLinkRegex, handler: match => match},
    {regex: codeBlockRegex, handler: match => match},
  ];

  // Function to process URLs
  const processUrl = match => {
    let url = match.startsWith('http') ? match : `https://${match}`;
    url = url.replace(/([^:/?#]+)([.,;:!?]+)$/, '$1');
    return `[${match}](${url})`;
  };

  // Function to handle special cases
  const handleSpecialCases = text => {
    return text
      .replace(/\b(e\.g\.|i\.e\.|etc\.|vs\.|fig\.|eq\.)\s/gi, match =>
        match.toLowerCase(),
      )
      .replace(/(\d+)\.(\d+)/g, '$1.$2')
      .replace(/(?<=\S)\/(?=\S)/g, '\\/')
      .replace(/\[([\w.-]+)\]\(https:\/\/\1\)/g, '$1');
  };

  // Main processing function
  const processText = text => {
    let result = text;
    let placeholders = {};
    let placeholderCounter = 0;

    // Replace special patterns with placeholders
    specialPatterns.forEach(({regex, handler}) => {
      result = result.replace(regex, match => {
        const placeholder = `__PLACEHOLDER_${placeholderCounter++}__`;
        placeholders[placeholder] = handler(match);
        return placeholder;
      });
    });

    // Convert URLs to markdown links
    result = result.replace(urlRegex, match => {
      return match.startsWith('__PLACEHOLDER_') ? match : processUrl(match);
    });

    // Restore placeholders
    Object.entries(placeholders).forEach(([placeholder, value]) => {
      result = result.replace(placeholder, value);
    });

    return handleSpecialCases(result);
  };

  try {
    return processText(text);
  } catch (error) {
    console.error('Error in convertUrlsToMarkdown:', error);
    return text; // Return original text if an error occurs
  }
}

const MagicMarkdown = ({content}) => {
  const [links, setLinks] = useState([]);

  const processedContent = useMemo(
    () => convertUrlsToMarkdown(content),
    [content],
  );

  useEffect(() => {
    const newLinks = new Set();
    // Regex to match both markdown links and raw URLs
    const regex = /(?:\[([^\]]+)\]\(([^)]+)\))|(\bhttps?:\/\/\S+\b)/g;

    const isValidUrl = url => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };

    let match;
    while ((match = regex.exec(processedContent)) !== null) {
      let url = match[2] || match[3]; // match[2] for markdown links, match[3] for raw URLs

      if (url && !url.startsWith('/feed/profiles/detail/')) {
        if (isValidUrl(url)) {
          newLinks.add(url);
        }
      }
    }

    setLinks(Array.from(newLinks));
  }, [processedContent]);

  const NewTabLink = useCallback(({href, children}) => {
    const isExternal = /^https?:\/\//.test(href);
    const isMention = href.startsWith('/feed/profiles/detail/');

    if (isExternal && !isMention) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-link">
          {children}
        </a>
      );
    } else if (isExternal || isMention) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    } else {
      return <a href={href}>{children}</a>;
    }
  }, []);

  const components = useMemo(() => ({a: NewTabLink}), [NewTabLink]);

  return (
    <div className="magic-markdown-container">
      <ReactMarkdown components={components}>{processedContent}</ReactMarkdown>
      {links.length > 0 && (
        <div className="link-previews-container">
          {links.map((url, index) => (
            <LinkPreview key={index} url={url} />
          ))}
        </div>
      )}
    </div>
  );
};

const LinkPreview = ({url}) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        // Unescape the URL
        const unescapedUrl = url.replace(/\\/g, '');

        // Updated Loom regex to handle the new URL format
        const loomMatch = unescapedUrl.match(
          /loom\.com\/(share|embed)\/([a-zA-Z0-9]+)/,
        );
        if (loomMatch) {
          setPreview({type: 'loom', id: loomMatch[2]});
          setLoading(false);
          return;
        }

        const youtubeMatch = unescapedUrl.match(
          /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
        );
        if (youtubeMatch) {
          setPreview({type: 'youtube', id: youtubeMatch[1]});
          setLoading(false);
          return;
        }

        const {success, error, preview} = await fetchPreview({
          url: unescapedUrl,
        });

        if (success) {
          setPreview({type: 'generic', data: preview});
        } else {
          setError(error);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [url]);

  const toggleExpand = () => setExpanded(!expanded);

  if (loading) {
    return <div className="link-preview-skeleton" />;
  }

  if (error || !preview) {
    return null;
  }

  switch (preview.type) {
    case 'loom':
      return (
        <LoomPreview
          videoId={preview.id}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    case 'youtube':
      return (
        <YouTubePreview
          videoId={preview.id}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    case 'generic':
      return (
        <GenericLinkPreview
          url={url}
          preview={preview.data}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    default:
      return null;
  }
};

const LoomPreview = ({videoId, expanded, onToggle}) => (
  <div
    className={`embed-preview loom-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {expanded ? (
      <iframe
        title={`Loom ${videoId}`}
        src={`https://www.loom.com/embed/${videoId}`}
        allowFullScreen
      />
    ) : (
      <div className="preview-placeholder">Loom Video - Click to Watch</div>
    )}
  </div>
);

const YouTubePreview = ({videoId, expanded, onToggle}) => (
  <div
    className={`embed-preview youtube-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {expanded ? (
      <iframe
        title={`YouTube ${videoId}`}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    ) : (
      <div className="preview-placeholder">YouTube Video - Click to Watch</div>
    )}
  </div>
);

const GenericLinkPreview = ({url, preview, expanded, onToggle}) => (
  <div
    className={`link-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {preview.image && (
      <div className="link-preview-image">
        <img src={preview.image} alt={preview.title} />
      </div>
    )}
    <div className="link-preview-content">
      <h4>{preview.title}</h4>
      {expanded && <p>{preview.description}</p>}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-preview-url"
        onClick={e => e.stopPropagation()}>
        {new URL(url).hostname}
      </a>
    </div>
  </div>
);

export default React.memo(MagicMarkdown);
