import React from 'react';
import {convertToDate, formatWWW_DD_MMM_TTT} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import './styles.css';

export const MessageItem = ({item}) => {
  const {parseTextForLinks, formatMessageWURLS} = useStringFormatter();
  const {
    state: {id},
  } = useAuth();

  if (!item) {
    return null;
  }

  const {content, sender_id, timestamp, hyperlinks} = item;
  const owner = sender_id === id;

  const date = convertToDate(timestamp * 1000);
  const prettyDateTime = formatWWW_DD_MMM_TTT(date);

  return (
    <div className={`message-wrapper ${owner ? 'message-to' : 'message-from'}`}>
      <div className={`message-bubble ${owner ? 'bubble-to' : 'bubble-from'}`}>
        <p className={`${owner ? 'text-inverted text-right' : 'text-left'}`}>
          {formatMessageWURLS(content)}
        </p>
      </div>
      <div
        className={`message-timestamp ${
          owner ? 'timestamp-to' : 'timestamp-from'
        }`}>
        <p className="text-10">{prettyDateTime}</p>
      </div>
    </div>
  );
};
