/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const copyString = string => {
    if (navigator.clipboard) {
      try {
        navigator.clipboard.writeText(string);
        console.log('URL copied to clipboard successfully!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = string;
      // Avoid scrolling to bottom
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        console.log('Fallback: URL copied to clipboard successfully!');
      } catch (err) {
        console.error('Fallback: Failed to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return {copyString};
};
