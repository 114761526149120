import {v4} from 'uuid';
import {dateToTimestamp} from '../api/dates';
import {
  fetchOpp,
  fetchOpps,
  fetchPost,
  listSortedOpps,
  listSortedPosts,
  setOpp,
  setPost,
  updateOpp,
  updatePosts,
} from '../api/graphql';
import {createOpportunities} from '../api/recommender';
import {getFileURL, uploadFile} from '../api/s3';
import createDataContext from './create-data-context';

const workspaceReducer = (state, action) => {
  const {
    id,
    ids,
    updates,
    personal_updates,
    update_token,
    post,
    posts,
    post_token,
    search_token,
    filter,
    opp,
    opps,
    opps_token,
    loaded,
    active_opps,
    closed_opps,
    direct_opps,
    completed_opps,
    token,
    post_feed,
    time,
  } = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'paginate':
      return {
        ...state,
        updates: [...state.updates, ...updates],
        personal_updates: [...state.personal_updates, ...personal_updates],
        update_token,
      };
    case 'setPost':
      return {
        ...state,
        posts: {...state.posts, [id]: post},
        post_ids: [id, ...state.post_ids],
      };
    case 'createPost':
      return {
        ...state,
        posts: {...state.posts, [id]: post},
        post_ids: [id, ...state.post_ids],
        post_search: [id, ...state.post_search],
      };
    case 'updatePost':
      const {status: post_status} = post;

      let search_ids = [...state.post_search];

      if (post_status === 'archived') {
        search_ids = search_ids.filter(cur => cur !== id);
      }
      return {
        ...state,
        post_search: search_ids,
        posts: {
          ...state.posts,
          [id]: {...(state.posts?.[id] ?? {}), ...post},
        },
      };
    case 'setPosts':
      return {
        ...state,
        post_token,
        posts: {...state.posts, ...posts},
        post_ids: ids,
        posts_loaded: true,
      };
    case 'paginatePosts':
      return {
        ...state,
        post_token,
        posts: {...state.posts, ...posts},
        post_ids: [...state.post_ids, ...ids],
      };
    case 'setOrgPosts':
      return {
        ...state,
        posts: {...state.posts, ...posts},
        org_posts: {...state.org_posts, [id]: ids},
      };
    case 'setUserPosts':
      return {
        ...state,
        posts: {...state.posts, ...posts},
        user_posts: {...state.user_posts, [id]: ids},
      };
    case 'searchPosts':
      return {
        ...state,
        posts: {...state.posts, ...posts},
        post_search: ids,
        post_filter: filter,
        search_token,
        posts_loaded: true,
      };
    case 'paginateSearch':
      return {
        ...state,
        posts: {...state.posts, ...posts},
        post_search: [...state.post_search, ...ids],
        post_filter: filter,
        search_token,
      };
    case 'newOpps':
      return {
        ...state,
        new_opps: [...state.new_opps, ...ids],
        opportunities: {...state.opportunities, ...opps},
        opps_token,
        new_opps_loaded: state.new_opps_loaded ? state.new_opps_loaded : loaded,
      };
    case 'interactedOpps':
      return {
        ...state,
        interacted_opps_loaded: true,
        opportunities: {...state.opportunities, ...opps},
        active_opps,
        closed_opps,
        direct_opps,
        completed_opps,
      };
    case 'contentOpps':
      return {
        ...state,
        content_opps: {...state.content_opps, [id]: ids},
        opportunities: {...state.opportunities, ...opps},
        content_opps_loading: false,
        time,
      };
    case 'getOpp':
      return {...state, opportunities: {...state.opportunities, [id]: opp}};
    case 'createOpp':
      return {
        ...state,
        opportunities: {...state.opportunities, [id]: opp},
        direct_opps: [...state.direct_opps, id],
      };
    case 'updateOpp':
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          [id]: {...state.opportunities[id], ...opp},
        },
      };
    case 'handleOpp':
      const {status} = opp;
      const filtered_new = state.new_opps.filter(opp_id => opp_id !== id);
      const filtered_active = state.active_opps.filter(opp_id => opp_id !== id);
      const filtered_closed = state.closed_opps.filter(opp_id => opp_id !== id);
      const filtered_complete = state.completed_opps.filter(
        opp_id => opp_id !== id,
      );

      switch (status) {
        case 'active':
        case 'direst':
          filtered_active.unshift(id);
          break;
        case 'closed':
          filtered_closed.unshift(id);
          break;
        case 'completed':
          filtered_complete.unshift(id);
          break;
        default:
          break;
      }

      return {
        ...state,
        new_opps: filtered_new,
        active_opps: filtered_active,
        closed_opps: filtered_closed,
        completed_opps: filtered_complete,
        opportunities: {
          ...state.opportunities,
          [id]: {...state.opportunities[id], ...opp},
        },
      };
    case 'getFeed':
      return {
        ...state,
        feed: [...state.feed, ...ids],
        opportunities: {...state.opportunities, ...opps},
        feed_token: token,
        feed_loaded: true,
      };
    case 'getPostFeed':
      return {...state, post_feed, posts: {...state.posts, ...posts}};
    default:
      return {...state};
  }
};

const defaultUpdate = dispatch => update => {
  try {
    dispatch({type: 'default', payload: update});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createPost = dispatch => async post => {
  try {
    const {media, user_id} = post;
    const media_urls = [];
    // UPLOAD MEDIA
    const uploaded_media = await Promise.all(
      media.map(async file => {
        const {name, type, size} = file;
        const key = `${user_id}/${name}`;
        const response = await uploadFile(key, file, {
          accessLevel: 'public',
          contentType: type,
          onProgress: ({transferredBytes, totalBytes}) => {},
        });
        media_urls.push({key, type, size, url: URL.createObjectURL(file)});
        return {key, type, size};
      }),
    );
    const id = v4();
    const timestamp = dateToTimestamp();
    const upload_post = {...post, id, timestamp, media: uploaded_media};
    await setPost(upload_post);

    upload_post.media = media_urls;

    const payload = {post: upload_post, id};
    dispatch({
      type: 'createPost',
      payload,
    });
    return {success: true, error: null, data: upload_post};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updatePost = dispatch => async post => {
  try {
    const {id} = post;

    await updatePosts(post);

    const payload = {post, id};
    dispatch({
      type: 'updatePost',
      payload,
    });
    return {success: true, error: null, data: post};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getPosts = dispatch => async query => {
  try {
    const {nextToken} = query;
    const {items, nextToken: post_token} = await listSortedPosts(query);
    const posts = {};
    const ids = await Promise.all(
      items.map(async post => {
        const {id, media} = post;
        posts[id] = post;
        const urls = await Promise.all(
          media.map(async file => {
            const {key} = file;
            const {expiresAt, url} = await getFileURL(key);
            return {...file, url};
          }),
        );
        post.media = urls;
        return id;
      }),
    );
    const payload = {ids, posts, post_token};
    if (nextToken) {
      dispatch({type: 'paginatePosts', payload});
    } else {
      dispatch({type: 'setPosts', payload});
    }
    return {success: true, error: null, data: {ids, posts}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getPost = dispatch => async id => {
  try {
    const post = await fetchPost(id);
    const {media} = post;
    const urls = await Promise.all(
      media.map(async file => {
        const {key} = file;
        const {expiresAt, url} = await getFileURL(key);
        return {...file, url};
      }),
    );
    post.media = urls;
    const payload = {id, post};
    dispatch({type: 'setPost', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const searchPosts = dispatch => async (filter_obj, options) => {
  try {
    const {limit, nextToken} = options || {};
    if (!nextToken) {
      dispatch({
        type: 'default',
        payload: {post_search: [], filter: filter_obj},
      });
    } else {
      dispatch({type: 'default', payload: {filter: filter_obj}});
    }

    const filter = postToFilter(filter_obj);

    const {items, nextToken: first_token} = await listSortedPosts({
      ...options,
      filter,
    });

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    while (all_items.length < limit && token && i < 100) {
      const {items, nextToken} = await listSortedPosts({
        ...options,
        nextToken: token,
        filter,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const posts = {};
    const ids = await Promise.all(
      all_items.map(async post => {
        const {id, media} = post;
        posts[id] = post;
        const urls = await Promise.all(
          media.map(async file => {
            const {key} = file;
            const {expiresAt, url} = await getFileURL(key);
            return {...file, url};
          }),
        );
        post.media = urls;
        return id;
      }),
    );
    const payload = {ids, posts, filter: filter_obj, search_token: token};

    if (nextToken) {
      dispatch({type: 'paginateSearch', payload});
    } else {
      dispatch({type: 'searchPosts', payload});
    }
    return {success: true, error: null, data: {ids, posts}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getOrgPosts = dispatch => async (query, id) => {
  try {
    if (!id) {
      return;
    }
    const {items, nextToken} = await listSortedPosts(query);
    const posts = {};
    const ids = await Promise.all(
      items.map(async post => {
        const {id, media} = post;
        posts[id] = post;
        const urls = await Promise.all(
          media.map(async file => {
            const {key} = file;
            const {expiresAt, url} = await getFileURL(key);
            return {...file, url};
          }),
        );
        post.media = urls;
        return id;
      }),
    );
    const payload = {id, ids, posts};

    dispatch({type: 'setOrgPosts', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getUserPosts = dispatch => async (query, id) => {
  try {
    if (!id) {
      return;
    }
    const {items, nextToken} = await listSortedPosts(query);
    const posts = {};
    const ids = await Promise.all(
      items.map(async post => {
        const {id, media} = post;
        posts[id] = post;
        const urls = await Promise.all(
          media.map(async file => {
            const {key} = file;
            const {expiresAt, url} = await getFileURL(key);
            return {...file, url};
          }),
        );
        post.media = urls;
        return id;
      }),
    );
    const payload = {id, ids, posts};

    dispatch({type: 'setUserPosts', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const submitNewOpps = (dispatch, items, token) => {
  try {
    const opps = {};
    const ids = items.map(opp => {
      const {id} = opp;
      opps[id] = opp;
      return id;
    });
    const loaded = !!items?.length;
    const payload = {
      ids,
      opps,
      opp_token: token,
      loaded,
    };
    dispatch({
      type: 'newOpps',
      payload,
    });
  } catch (err) {
    throw err;
  }
};

const getNewOpportunities = dispatch => async query => {
  try {
    const threshold = 20;
    const {limit} = query;
    const {items, nextToken: first_token} = await fetchOpps(query);

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    submitNewOpps(dispatch, items, first_token);

    while (all_items.length < threshold && token && i < 100) {
      const {items, nextToken} = await fetchOpps({
        ...query,
        nextToken: token,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
      submitNewOpps(dispatch, items, nextToken);
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getTypedOpportunities = dispatch => async query => {
  try {
    const {items, nextToken: first_token} = await fetchOpps(query);

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    while (token && i < 50) {
      const {items, nextToken} = await fetchOpps({
        ...query,
        nextToken: token,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }
    const active_opps = [];
    const closed_opps = [];
    const direct_opps = [];
    const completed_opps = [];
    const opps = {};

    all_items.forEach(opp => {
      const {id, status} = opp;
      opps[id] = opp;
      if (status === 'active') {
        active_opps.push(id);
      }
      if (status === 'closed') {
        closed_opps.push(id);
      }
      if (status === 'direct') {
        direct_opps.push(id);
      }
      if (status === 'completed') {
        completed_opps.push(id);
      }
    });

    const payload = {
      active_opps,
      closed_opps,
      direct_opps,
      completed_opps,
      opps,
    };
    dispatch({
      type: 'interactedOpps',
      payload,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getOpportunitiesByContent = dispatch => async (query, id) => {
  try {
    dispatch({type: 'default', payload: {content_opps_loading: true}});
    const start = dateToTimestamp();
    const {items, nextToken} = await fetchOpps(query);

    let all_items = [...items];
    let token = nextToken;
    let i = 0;

    while (token && i < 50) {
      const {items, nextToken} = await fetchOpps({
        ...query,
        nextToken: token,
      });

      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const opps = {};
    const ids = all_items
      .sort((a, b) => {
        return b?.tags?.length - a?.tags?.length;
      })
      .map(opp => {
        const {id} = opp;
        opps[id] = opp;
        return id;
      });
    const end = dateToTimestamp();
    const time = end - start;
    const payload = {ids, opps, id, time};
    dispatch({type: 'contentOpps', payload});
  } catch (err) {
    dispatch({type: 'default', payload: {content_opps_loading: false}});
    return handleErrors(err, dispatch);
  }
};

const manageOpportunities = dispatch => async (body, id) => {
  try {
    const start = dateToTimestamp();
    dispatch({type: 'default', payload: {content_opps_loading: true}});
    const {success, error, active, removed} = await createOpportunities(body);
    const end = dateToTimestamp();
    const time = end - start;
    if (success) {
      const payload = {ids: active, opps: {}, id, time};
      dispatch({type: 'contentOpps', payload});
    } else {
      const payload = {ids: [], opps: {}, id, time};
      dispatch({type: 'contentOpps', payload});
    }
  } catch (err) {
    dispatch({type: 'default', payload: {content_opps_loading: false}});
    return handleErrors(err, dispatch);
  }
};

const getOpportunity = dispatch => async id => {
  try {
    const opp = await fetchOpp(id);
    const payload = {opp, id};
    dispatch({type: 'getOpp', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const handleOpportunity = dispatch => async opp => {
  try {
    const {id} = opp;
    await updateOpp(opp);
    const payload = {opp, id};
    dispatch({type: 'handleOpp', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

// TO UPDATE DATA NOT STATUS
const updateOpportunity = dispatch => async opp => {
  try {
    const {id} = opp;
    await updateOpp(opp);
    const payload = {opp, id};
    dispatch({type: 'updateOpp', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createOpp = dispatch => async opp => {
  try {
    const {id} = opp;
    await setOpp(opp);
    const payload = {opp, id};
    dispatch({type: 'createOpp', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const submitNewFeed = (dispatch, items, token) => {
  try {
    if (!items?.length) {
      dispatch({
        type: 'default',
        payload: {feed_token: token},
      });
      return;
    }

    const opps = {};
    const ids = items.map(opp => {
      const {id} = opp;
      opps[id] = opp;
      return id;
    });

    const payload = {
      ids,
      opps,
      token,
    };

    dispatch({
      type: 'getFeed',
      payload: payload,
    });
  } catch (err) {
    throw err;
  }
};

const getOpportunityFeed = dispatch => async query => {
  try {
    const {items, nextToken: first_token} = await listSortedOpps(query);

    const {items: posts_items} = await listSortedPosts({
      status: 'active',
      sortDirection: 'DESC',
      limit: 15,
    });

    const posts = {};
    const post_feed = posts_items.map(item => {
      const {id} = item;
      posts[id] = item;
      return id;
    });

    dispatch({
      type: 'getPostFeed',
      payload: {posts, post_feed},
    });

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    submitNewFeed(dispatch, items, first_token);

    while (all_items.length < 50 && token && i < 100) {
      const {items, nextToken} = await listSortedOpps({
        ...query,
        nextToken: token,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
      submitNewFeed(dispatch, items, nextToken);
    }
    dispatch({
      type: 'default',
      payload: {feed_loaded: true},
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const resetWorkspaces = dispatch => () => {
  try {
    dispatch({
      type: 'default',
      payload: defaultValues,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const default_post_filter = {
  description: '',
  type: '',
  inner_tags: [],
  outer_tags: [],
  group_ids: [],
};

const defaultValues = {
  loaded: false,
  // POSTS
  post_ids: [],
  posts: {},
  org_posts: {},
  user_posts: {},
  // OPPS
  active_opps: [],
  new_opps: [],
  closed_opps: [],
  completed_opps: [],
  direct_opps: [],
  opportunities: {},
  content_opps: {},
  // SEARCH
  post_search: [],
  post_token: null,
  posts_loaded: false,
  post_filter: default_post_filter,
  new_opps_loaded: false,
  interacted_opps_loaded: false,
  opp_token: null,
  // FEED
  feed: [],
  post_feed: [],
  feed_token: null,
  feed_loaded: false,
  time: 0,
  error: null,
};

const funcs = {
  createOpp,
  createPost,
  defaultUpdate,
  getNewOpportunities,
  getOpportunitiesByContent,
  getOpportunity,
  getOpportunityFeed,
  getOrgPosts,
  getPost,
  getPosts,
  getTypedOpportunities,
  getUserPosts,
  handleOpportunity,
  manageOpportunities,
  resetWorkspaces,
  searchPosts,
  updateOpportunity,
  updatePost,
};

export const {Provider, Context} = createDataContext(
  workspaceReducer,
  funcs,
  defaultValues,
);

const handleErrors = (err, dispatch) => {
  const {data, errors, code} = err;
  console.log('WORKSPACE ERROR', err);
  let error = 'Something went wrong.';

  if (code) {
    switch (code) {
      case 'NotAuthorizedException':
      case 'UsernameExistsException':
      case 'UserNotFoundException':
        error = err.message;
        break;
      default:
        break;
    }
  }

  const top_error = errors && errors[0];
  if (top_error) {
    switch (top_error.errorType) {
      case 'DynamoDB:ConditionalCheckFailedException':
        error = 'Item already exists.';
        break;
      default:
        break;
    }
  }

  dispatch({
    type: 'error',
    payload: {error},
  });
  return {success: false, error};
};

const postToFilter = obj => {
  const and = [];
  const or = [];
  const fields = Object.keys(obj);
  fields.forEach(field => {
    const values = obj[field];

    // IGNORE NULL
    if (!values) {
      return;
    }

    switch (field) {
      case 'description':
      case 'type':
        and.push({[field]: {contains: values}});
        break;
      case 'collaborators':
      case 'opportunities':
      case 'skills':
      case 'degrees':
      case 'majors':
        values.forEach(val => {
          and.push({tags: {contains: val}});
        });
        break;
      case 'inner_tags':
      case 'outer_tags':
        values.forEach(val => {
          and.push({[field]: {contains: val}});
        });
        break;
      // case 'group_id':
      //   and.push({[field]: {contains: values}});
      //   break;
      case 'group_ids':
        values.forEach(val => {
          or.push({[field]: {contains: val}});
        });
        break;
      default:
        return;
    }
  });

  const filter = {};
  if (and.length) {
    filter.and = and;
  }
  if (or.length) {
    filter.or = or;
  }
  return filter;
};
