import constants from '../../components/constants';

const generateSharePostFromOpp = props => {
  const {pretty_name, post} = props || {};
  const post_id = post?.id;
  const post_link = `${constants.post_url}/${post_id}`;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <div style="text-align: center;">
          <img src="${
            constants.publicmind_logo
          }" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 8px 20%;">
          <p style="text-align: left; font-size: 28px; color: black; font-weight: bold;">You matched with ${
            pretty_name ? pretty_name + `'s` : 'their'
          } <a href="${post_link}">${post.type}</a></p>  
          <p style="white-space: pre-line; text-align: left; color: #75757a;">
            ${
              pretty_name || 'A colleague'
            } thought you might be interested in this ${
    post.type
  } and believes your expertise could be valuable. <a href="${post_link}">View</a> on PublicMind to explore this project and collaborate.
          </p>    
          <div style="text-align: left; border: 1px solid #ccc; padding: 16px; border-radius: 16px; margin: 8px 0px; background-color: #f4f4f4;">
            <p style="font-size: 18px;"><strong>${
              post
                ? post?.type?.[0].toUpperCase() + post?.type?.slice(1)
                : 'Post'
            } Details</strong></p>
            <p style="color: grey;"><strong>Title:</strong></p>
            <p style="font-size: 18px;">${post.title}</p>
            <p style="color: grey;"><strong>Description:</strong></p>
            <p style="font-size: 14px;">${post?.description?.slice(
              0,
              200,
            )}...</p>
            ${
              (post?.inner_tags?.length ?? 0) > 0
                ? '<p style="font-size: 18px;"><strong>Resource Tags</strong></p>'
                : ''
            }
            ${tagItems(post?.inner_tags)}
            ${
              (post?.outer_tags?.length ?? 0) > 0
                ? '<p style="font-size: 18px;"><strong>Request Tags</strong></p>'
                : ''
            }
            ${tagItems(post?.outer_tags)}
          </div>
          <br/>
          <div style="text-align: center;" >
            <a href="${post_link}" style="background-color: #6e8efb; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;" >
              View ${
                post
                  ? post?.type[0].toUpperCase() + post?.type.slice(1)
                  : 'Post'
              }
            </a>
          </div>
          <br/>
          <p style="text-align: center;">Sent from PublicMind</p>
        </div>
      </body>
    </html>
  `;
};

const tagItems = similar => {
  return similar?.length
    ? `<div style="display: flex; flex-wrap: wrap; word-break: keep-all;">
         ${similar
           .slice(0, 2)
           .map(
             tag => `
               <p style="display: inline-block; font-weight: light; background-color: #4743c5; border: 1px solid #75757a; color: white; padding: 6px 10px; margin: 4px; border-radius: 24px; box-sizing: border-box; word-break: keep-all;">
                 ${tag}
               </p>
             `,
           )
           .join('')}
       </div>`
    : '';
};

export default generateSharePostFromOpp;
