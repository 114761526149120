import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useThreads} from '../../hooks/use-threads';
import ThreadDetail from './thread-detail';

const PublicThread = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {
    state: {thread_ids, threads, threads_loaded},
    getThread,
  } = useThreads();

  useEffect(() => {
    if (id && threads?.[id] === undefined) {
      getThread(id);
    }
  }, [id]);

  return (
    <div className="">
      <div className="grid-container">
        <div className="grid-8">
          <ThreadDetail id={id} />
        </div>
      </div>
    </div>
  );
};

export default PublicThread;
