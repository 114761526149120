import {getFileURL} from '../api/s3';
import contactsJSON from '../assets/lists/contacts.json';
import constants from '../components/constants';
import {useAuth} from './use-auth';
import {useNetworking} from './use-networking';
import {useWorkspace} from './use-workspace';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const {
    state: {id, organizations, profiles, posts, groups},
    getProf,
    getOrg,
    getGroup,
  } = useAuth();
  const {
    state: {opportunities},
    getOpportunity,
    getPost,
  } = useWorkspace();
  const {
    state: {rolodex_loaded},
    fetchRolodex,
  } = useNetworking();

  const loadOrgs = async (org_ids, options) => {
    if (!org_ids || !org_ids.length) {
      return [];
    }

    await Promise.all(
      org_ids.map(async id => {
        if (!organizations || organizations[id] === undefined) {
          return getOrg(id, options);
        }
        return null;
      }),
    );
  };

  const loadProfiles = async (profile_ids, options) => {
    if (!profile_ids || !profile_ids.length) {
      return [];
    }

    const profs = await Promise.all(
      profile_ids.map(async id => {
        if (!id) {
          return null;
        }
        if ((id && !profiles) || profiles[id] === undefined) {
          const {success, error, prof} = await getProf(id, options);
          if (success) {
            return prof;
          }
          if (error) {
            return null;
          }
        }
        return null;
      }),
    );

    const mapped = {};
    profs.forEach(prof => {
      if (prof) {
        mapped[prof.id] = prof;
      }
    });

    return mapped;
  };

  const loadPosts = async post_ids => {
    if (!post_ids || !post_ids.length) {
      return [];
    }
    await Promise.all(
      post_ids.map(async id => {
        if ((id && !posts) || posts[id] === undefined) {
          return getPost(id);
        }
        return null;
      }),
    );
  };

  const loadWorkspaces = async workspace_ids => {
    if (!workspace_ids || !workspace_ids.length) {
      return;
    }
  };

  const loadContacts = source => {
    try {
      if (!source || !source.length) {
        return [];
      }

      const filtered = contactsJSON.filter(contact => {
        const {college} = contact;
        const included = source.find(src => src.pretty === college);
        return included;
      });
      return filtered;
    } catch (err) {
      return [];
    }
  };

  const loadURLS = async media => {
    try {
      if (!media || !media.length) {
        return [];
      }

      const urls = await Promise.all(
        media.map(async file => {
          const {key} = file;
          const {expiresAt, url} = await getFileURL(key);
          return {...file, url};
        }),
      );
      return urls;
    } catch (err) {
      return [];
    }
  };

  const loadOpportunities = async opp_ids => {
    if (!opp_ids || !opp_ids.length) {
      return [];
    }
    await Promise.all(
      opp_ids.map(async id => {
        if (!opportunities || opportunities[id] === undefined) {
          return getOpportunity(id);
        }
        return null;
      }),
    );
  };

  const loadRolodex = async () => {
    if (!rolodex_loaded) {
      const query = {
        source_user: id,
        filter: {},
        limit: constants.all_items,
        nextToken: null,
      };
      await fetchRolodex(query);
    }
  };

  const loadGroups = async (group_ids, options) => {
    if (!group_ids || !group_ids.length) {
      return [];
    }

    await Promise.all(
      group_ids.map(async id => {
        if (!groups || groups[id] === undefined) {
          if (id) {
            return getGroup(id, options);
          }
        }
        return null;
      }),
    );
  };

  return {
    loadOrgs,
    loadProfiles,
    loadWorkspaces,
    loadContacts,
    loadURLS,
    loadOpportunities,
    loadPosts,
    loadRolodex,
    loadGroups,
  };
};
