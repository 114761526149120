import chrono from 'chrono-node';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {UpdateThread} from '../../pages/threads/create-thread';
import {mergeArrays} from '../../utils/utils';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import constants from '../constants';
import {FormikArraySearchableDropDown} from '../inputs/drop-down';
import {MultilineFormikTextfield, Textfield} from '../inputs/textfields';
import {MultipleFileUploader} from '../inputs/uploader';
import {Modal} from './modal';

export const EditThreadModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditThreadContentModal
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditThreadContentModal = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      group_id: thread.group_id,
      status: thread.status,
      security_level: thread?.security_level,
      admins: thread.admins,
      subscribers: thread.subscribers,
      media: thread.media,
      title: thread.title,
      content: thread.content,
      parent: thread.parent,
      expiration: thread.expiration,
      logo: thread.logo,
      tags: thread.tags,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      // content: Yup.string().required('Content is required'),
      // group_id: Yup.string().required('Group is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        const {success, error} = await updateThread(update);

        if (success) {
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <UpdateThread formik={formik} />
    </Modal>
  );
};
export const EditThread = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      group_id: thread.group_id,
      status: thread.status,
      admins: thread.admins,
      subscribers: thread.subscribers,
      media: thread.media,
      title: thread.title,
      content: thread.content,
      parent: thread.parent,
      expiration: thread.expiration,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
      group_id: Yup.string().required('Group is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        const {success, error} = await updateThread(update);

        if (success) {
          helpers.resetForm();

          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return <UpdateThread formik={formik} isSmall={true} />;
};

export const EditContentModal = ({active, setActive, content}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditContentContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          content={content}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditContentContent = ({active, setActive, setPopup, content}) => {
  const {updateContent} = useThreads();

  const [files, setFiles] = useState(content.media ?? []);

  const formik = useFormik({
    initialValues: {
      content: content.content ?? '',
      media: content.media ?? [],
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = content;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        if (files?.length) {
          update.media = files;
        }

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Update Content</h4>
      <MultilineFormikTextfield
        formik={formik}
        name={'content'}
        header="Content"
      />

      <MultipleFileUploader
        files={files}
        setFiles={setFiles}
        types={[
          ...constants.image_mime_types,
          ...constants.video_mime_types,
          ...constants.document_mime_types,
          ...constants.compressed_mime_types,
          ...constants.audio_mime_types,
        ]}
        limit={constants.file_20mb}
      />

      <div className="flex justify-center">
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const EditThreadPermissionsModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditPermissionsContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditPermissionsContent = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();
  const {prettyName} = useStringFormatter();
  const {
    state: {groups, current_group, profiles},
  } = useAuth();

  const {group_id} = thread;
  const {members} = groups?.[group_id] ?? {};

  const formik = useFormik({
    initialValues: {
      admins: [],
      subscribers: [],
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {id, admins, subscribers} = thread;
        const {merged: merged_admins, unique: unique_admins} = mergeArrays(
          admins,
          values.admins,
        );
        const {merged: merged_subscribers, unique: unique_subscribers} =
          mergeArrays(subscribers, values.subscribers);

        const {merged: combined} = mergeArrays(
          merged_admins,
          merged_subscribers,
        );

        const update = {
          id,
          updated: dateToTimestamp(),
          admins: merged_admins,
          subscribers: combined,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const mapped = members?.length
    ? members.map(member_id => {
        const profile = profiles[member_id];
        const name = prettyName(profile);
        return {key: name, value: member_id};
      })
    : [];

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Add New People</h4>
      <span className="pretty-form-group">
        <div className="create-thread__field">
          <FormikArraySearchableDropDown
            formik={formik}
            header={'Administrators'}
            name={'admins'}
            items={mapped}
          />
        </div>
        <div className="create-thread__field">
          <FormikArraySearchableDropDown
            formik={formik}
            header={'Collaborators'}
            name={'subscribers'}
            items={mapped}
          />
        </div>
      </span>
      <br />
      <div className="flex justify-center">
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Add
        </button>
      </div>
    </Modal>
  );
};

export const ScheduleContentModal = ({active, setActive, formik}) => {
  return (
    <>
      {active ? (
        <ScheduleContentContent
          active={active}
          setActive={setActive}
          formik={formik}
        />
      ) : null}
    </>
  );
};

const ScheduleContentContent = ({active, setActive, formik}) => {
  const [dateString, setDateString] = useState('');
  const [chosenDate, setDate] = useState('');

  useEffect(() => {
    const scheduledTime = chrono.parseDate(dateString);
    setDate(scheduledTime);
  }, [dateString]);

  const now = new Date();
  const past = chosenDate && chosenDate < now;
  const formattedDate = chosenDate?.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const formattedTime = chosenDate?.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Schedule your content</h4>
      <br />
      <Textfield
        value={dateString}
        onChange={e => setDateString(e.target.value)}
        placeholder="Tomorrow at..."
      />
      {past && <p className="error">Choose a time in the future.</p>}
      {chosenDate && (
        <p>
          {formattedDate} at {formattedTime}
        </p>
      )}
      <div className="flex justify-center">
        <button
          disabled={!chosenDate || past || formik.isSubmitting}
          onClick={async () => {
            formik.setFieldValue('status', 'scheduled');
            formik.setFieldValue('scheduled', dateToTimestamp(chosenDate));
            await formik.handleSubmit();
            setActive(false);
          }}>
          Schedule
        </button>
      </div>
    </Modal>
  );
};
