import constants from '../../components/constants';

const generateShareOtherPost = props => {
  const {profile, post, link, note} = props || {};
  const {email, first_name, last_name} = profile || {};
  const post_id = post?.id;
  const post_link = `${constants.post_url}/${post_id}`;

  const pretty_name =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <div style="text-align: center;">
          <img src="${
            constants.publicmind_logo
          }" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 8px 20%;">
          <p style="text-align: left; font-size: 28px; color: black; font-weight: bold;">
            <a href="${link}">Join</a> ${pretty_name || 'Us'} on PublicMind
          </p>
          <p style="white-space: pre-line; text-align: left; color: #75757a;">
            ${
              pretty_name || 'A colleague'
            } thought you might be interested in this ${
    post?.type
  } and believes your expertise could be valuable. 
            <a href="${link}">Join</a> PublicMind to explore this project and collaborate with other professionals.
          </p>
          ${
            note
              ? `<p style="white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 16px; text-align: center;">${note}</p>`
              : ''
          }
          <div style="text-align: left; border: 1px solid #ccc; padding: 16px; border-radius: 16px; margin: 8px 0px; background-color: #f4f4f4;">
            <p style="font-size: 18px;"><strong>${
              post?.type[0].toUpperCase() + post?.type.slice(1)
            } Details</strong></p>
            <p style="color: grey;"><strong>Title:</strong></p>
            <p style="font-size: 18px;">${post?.title}</p>
            <p style="color: grey;"><strong>Description:</strong></p>
            <p style="font-size: 14px;">${post?.description.slice(
              0,
              200,
            )}...</p>
            ${
              (post?.inner_tags?.length ?? 0) > 0
                ? '<p style="font-size: 18px;"><strong>Resource Tags</strong></p>'
                : ''
            }
            ${tagItems(post?.inner_tags)}
            ${
              (post?.outer_tags?.length ?? 0) > 0
                ? '<p style="font-size: 18px;"><strong>Request Tags</strong></p>'
                : ''
            }
            ${tagItems(post?.outer_tags)}
          </div>
          <br/>
          <div style="text-align: center;">
            <a href="${post_link}" style="background-color: #6e8efb; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
              View ${post?.type[0].toUpperCase() + post?.type.slice(1)}
            </a>
          </div>
          <br/>
          <p style="text-align: center;">Sent from PublicMind</p>
        </div>
      </body>
    </html>
  `;
};

const tagItems = tags => {
  return tags?.length
    ? `<div style="display: flex; flex-wrap: wrap; word-break: keep-all;">
         ${tags
           .slice(0, 2)
           .map(
             tag => `
               <p style="display: inline-block; font-weight: light; background-color: #4743c5; border: 1px solid #75757a; color: white; padding: 6px 10px; margin: 4px; border-radius: 24px; box-sizing: border-box; word-break: keep-all;">
                 ${tag}
               </p>
             `,
           )
           .join('')}
       </div>`
    : '';
};

export default generateShareOtherPost;
