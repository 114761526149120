import React, {useEffect, useRef, useState} from 'react';
import {GoImage, GoPerson} from 'react-icons/go';
import {MdOpenInNew} from 'react-icons/md';
import {RxCross2, RxFile, RxOpenInNewWindow, RxUpload} from 'react-icons/rx';
import {useAuth} from '../../hooks/use-auth';
import {LinkButton} from '../buttons/buttons';
import {SpinningIndicator} from '../loading/loading-indicator';
import Tooltip from '../tooltip/tooltip';
import './styles.css';

import {RxChevronDown, RxChevronUp} from 'react-icons/rx';

export const Uploader = props => {
  const {header, file, setFile, types, limit, ...rest} = props;

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  let accept = null;

  if (types) {
    accept = types.join(', ');
  }

  return (
    <div>
      {header && <label>{header}</label>}
      <input
        {...rest}
        type="file"
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
};

export const DragAndDropUploader = ({file, setFile, types, limit}) => {
  const [hovered, setHovered] = useState(false);
  const dragCounter = useRef(0);
  const [pretty_types, setTypes] = useState('');
  const fileInputRef = useRef(null);

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDragEnter = e => {
    e.preventDefault();
    dragCounter.current++;
    if (dragCounter.current === 1) {
      setHovered(true);
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setHovered(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    handleFile(e.dataTransfer.files[0]);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInput = e => {
    handleFile(e.target.files[0]);
  };

  const handleFile = file => {
    dragCounter.current = 0;
    setHovered(false);

    if (!file) return;

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      return;
    }

    if (types && !types.includes(file.type)) {
      alert('Invalid file type');
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  useEffect(() => {
    if (types?.length) {
      const pretty = types.map(type => type.split('/')[1]).join(', ');
      setTypes(pretty);
    }
  }, [types]);

  return (
    <div>
      <div
        className={`uploader ${hovered ? 'hover' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        style={{cursor: 'pointer'}}>
        {file ? (
          <FileViewer type={file.type} url={URL.createObjectURL(file)} />
        ) : (
          <p>Drag and drop file here or click to select</p>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
        style={{display: 'none'}}
        accept={types ? types.join(',') : undefined}
      />
      {pretty_types && (
        <p className="text-14 text-secondary">
          Allowed file type: {pretty_types}
        </p>
      )}
      {limit && (
        <p className="text-14 text-secondary">
          Size limit: {(limit / (1024 * 1024)).toFixed(0)}MB
        </p>
      )}
    </div>
  );
};

export const MultipleFileUploader = ({files, setFiles, types, limit}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [prettyTypes, setPrettyTypes] = useState('');

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleChange = e => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const handleFiles = newFiles => {
    const validFiles = Array.from(newFiles).filter(file => {
      if (limit && file.size > limit) {
        alert(
          `File size exceeds the maximum limit of ${(
            limit /
            (1024 * 1024)
          ).toFixed(2)}MB`,
        );
        return false;
      }
      if (types && !types.includes(file.type)) {
        alert('Invalid file type');
        return false;
      }
      return true;
    });

    setFiles(prevFiles => [
      ...(Array.isArray(prevFiles) ? prevFiles : []),
      ...validFiles,
    ]);
  };

  const removeFile = index => {
    setFiles(prevFiles => {
      if (Array.isArray(prevFiles)) {
        return prevFiles.filter((_, i) => i !== index);
      }
      return [];
    });
  };

  useEffect(() => {
    if (types?.length) {
      const pretty = types.map(type => type.split('/')[1]).join(', ');
      setPrettyTypes(pretty);
    }
  }, [types]);

  return (
    <div className="file-uploader-container">
      <div
        className={`file-uploader-dropzone ${dragActive ? 'drag-active' : ''}`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => inputRef.current.click()}>
        <input
          ref={inputRef}
          type="file"
          multiple
          onChange={handleChange}
          accept={types ? types.join(',') : undefined}
          style={{display: 'none'}}
        />
        <RxUpload size={24} />
        <p>Drag and drop files here or click to select</p>
      </div>

      {/* {(prettyTypes || limit) && (
        <div className="file-uploader-info">
          {prettyTypes && <p>Allowed: {prettyTypes}</p>}
          {limit && (
            <p>Max total size: {(limit / (1024 * 1024)).toFixed(0)}MB</p>
          )}
        </div>
      )} */}

      {Array.isArray(files) && files.length > 0 && (
        <div className="file-uploader-preview">
          {files.map((file, index) => (
            <div key={index} className="file-item">
              {file.type.startsWith('image/') ? (
                <img
                  src={file.url ? file.url : URL.createObjectURL(file)}
                  alt={file.name}
                />
              ) : (
                <RxFile size={24} />
              )}
              <p>{file.name}</p>
              <button
                onClick={() => removeFile(index)}
                className="file-remove-btn">
                X
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ProfileImageUpload = props => {
  const {types, limit, type} = props;

  const {
    state: {id, profile, current_organization, organizations},
    updateProfile,
    updateOrganization,
  } = useAuth();

  const organization = organizations?.[current_organization] ?? {};
  const url =
    type === 'organization'
      ? organization.profile_image
      : profile.profile_image;

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  const handleEditClick = () => {
    // Trigger the file input click event
    const inputElement = document.getElementById('image_input');
    if (inputElement) {
      inputElement.click();
    }
  };

  let accept = null;

  if (types) {
    accept = types.join(', ');
  }

  return (
    <div className="flex-column align-center">
      <input
        id="image_input"
        type="file"
        accept={accept || 'image/*'}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <ProfileImage
        data={file ? {url: URL.createObjectURL(file)} : url}
        onClick={handleEditClick}
        type={type}
      />
      {loading ? (
        <SpinningIndicator />
      ) : file ? (
        <button
          className="margin-0"
          onClick={async () => {
            setLoading(true);
            if (type === 'organization') {
              await updateOrganization({
                id: current_organization,
                profile_image: file,
              });
            } else {
              await updateProfile({id, profile_image: file});
            }
            setFile(null);
            setLoading(false);
          }}>
          Save
        </button>
      ) : (
        <LinkButton onClick={handleEditClick} className="margin-0 padding-0">
          {url ? 'Edit' : type === 'organization' ? 'Add Logo' : 'Add Image'}
        </LinkButton>
      )}
    </div>
  );
};

export const ProfileImage = ({data, onClick, style, type}) => {
  const src = data?.url ?? null;

  const sizing =
    type === 'organization'
      ? {
          width: '150px',
          maxHeight: '100px',
          borderRadius: '8px',
          coverFit: 'cover',
        }
      : {
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: '1px solid black',
        };

  return (
    <div onClick={onClick} className="profile-container-special">
      {src ? (
        <ImageComponent
          data={data}
          styles={{backgroundColor: 'grey', ...sizing, ...style}}
        />
      ) : type === 'organization' ? (
        <GoImage
          style={{
            height: '150px',
            width: '150px',
            ...style,
          }}
        />
      ) : (
        <GoPerson
          style={{
            ...sizing,
            ...style,
          }}
        />
      )}
    </div>
  );
};

export const ProfileImageSimple = ({data, onClick, style, type}) => {
  const src = data?.url ?? null;

  const sizing =
    type === 'organization'
      ? {
          width: '150px',
          maxHeight: '100px',
          borderRadius: '8px',
          coverFit: 'cover',
        }
      : {
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: '1px solid black',
        };

  return (
    <div onClick={onClick} className="profile-container-simple">
      {src ? (
        <ImageComponent
          data={data}
          styles={{backgroundColor: 'grey', ...sizing, ...style}}
        />
      ) : type === 'organization' ? (
        <GoImage
          style={{
            height: '150px',
            width: '150px',
            ...style,
          }}
        />
      ) : (
        <GoPerson
          style={{
            ...sizing,
            ...style,
          }}
        />
      )}
    </div>
  );
};

const ImageComponent = ({data, styles}) => {
  const src = data?.url ?? null;
  const [imageStyle, setImageStyle] = useState(styles);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        // Determine style based on image dimensions
        const style =
          img.width > img.height
            ? {
                // More banner-like
                width: '200px', // or '150px' for fixed size
                height: 'auto',
                borderRadius: '8px',
                objectFit: 'cover',
                ...styles,
              }
            : {
                // More circular-like or square
                width: img.width > img.height ? '100px' : '150px',
                height: img.width > img.height ? '100px' : '150px',
                borderRadius: '50%',
                objectFit: 'cover',
                ...styles,
              };
        setImageStyle(style);
      };
      img.src = src;
    }
  }, [src]);

  return src ? <img src={src} alt="" style={imageStyle} /> : null;
};

export const ResizingFileViewer = ({type, url}) => {
  const imgRef = useRef();
  const [imageDimensions, setImageDimensions] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {
    if (type.startsWith('image/')) {
      loadImage();
    }
  }, [type, url]);

  const loadImage = () => {
    const img = new Image();
    img.onload = () => {
      calculateDimensions();
    };
    img.src = url;
    imgRef.current.src = url;
  };

  const calculateDimensions = () => {
    const parentWidth = imgRef.current.parentElement.offsetWidth;
    const parentStyles = window.getComputedStyle(imgRef.current.parentElement);
    const parentPaddingLeft = parseFloat(parentStyles.paddingLeft);
    const parentPaddingRight = parseFloat(parentStyles.paddingRight);
    const parentMarginLeft = parseFloat(parentStyles.marginLeft);
    const parentMarginRight = parseFloat(parentStyles.marginRight);
    const totalParentWidth =
      parentWidth -
      parentPaddingLeft -
      parentPaddingRight -
      parentMarginLeft -
      parentMarginRight;

    const maxWidth = Math.min(totalParentWidth, 800); // Maximum width of 800 pixels
    const {naturalWidth, naturalHeight} = imgRef.current;
    const aspectRatio = naturalWidth / naturalHeight;

    let width = maxWidth;
    let height = maxWidth / aspectRatio;

    setImageDimensions({width, height});
  };

  switch (type) {
    case 'url':
      return (
        <div className="flex">
          <Tooltip text={`Open the content in a new tab`}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <button>
                <MdOpenInNew />
              </button>
            </a>
          </Tooltip>
        </div>
      );
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/svg+xml':
      return (
        <img
          ref={imgRef}
          alt="Content"
          src={url}
          width={imageDimensions.width}
          height={imageDimensions.height}
        />
      );
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/mpeg':
    case 'video/quicktime':
      return (
        <video controls style={{width: '100%'}}>
          <source src={url} type={type} />
          Your browser does not support the video tag.
        </video>
      );
    case 'application/pdf':
      return (
        <iframe src={url} width="100%" height="500px" title="Content PDF" />
      );
    default:
      return null;
  }
};

export const FileViewer = ({type, url}) => {
  const renderFilePreview = () => {
    switch (true) {
      case type.startsWith('image/'):
        return (
          <img
            src={url}
            alt="File preview"
            style={{width: '100%', height: '100%', objectFit: 'cover'}}
          />
        );
      case type.startsWith('video/'):
        return (
          <video controls style={{width: '100%', height: '100%'}}>
            <source src={url} type={type} />
            Your browser does not support the video tag.
          </video>
        );
      case type === 'application/pdf':
        return (
          <embed src={url} type="application/pdf" width="100%" height="100%" />
        );
      default:
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: '#f0f0f0',
            }}>
            <span>{type.split('/')[1].toUpperCase()}</span>
          </div>
        );
    }
  };

  return (
    <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
      {renderFilePreview()}
    </div>
  );
};

// FileViewerSimple

const determineImageSize = (width, height) => {
  const aspectRatio = width / height;
  if (aspectRatio > 2) return 'banner';
  if (aspectRatio < 0.75) return 'portrait';
  if (Math.abs(aspectRatio - 1) < 0.1) return 'square';
  return 'landscape';
};

const ImagePreview = ({file, size, isSingle, onClick}) => {
  return (
    <div
      className={`file-viewer__preview file-viewer__preview--image ${
        isSingle ? 'file-viewer__preview--single' : ''
      } file-viewer__preview--${size}`}
      onClick={onClick}>
      <img src={file.url} alt={file.name} className="file-viewer__img" />
    </div>
  );
};

const FilePreview = ({file, isSingle, onImageClick}) => {
  const [size, setSize] = useState('default');

  useEffect(() => {
    if (file.type.startsWith('image/')) {
      const img = new Image();
      img.onload = () => {
        setSize(determineImageSize(img.width, img.height));
      };
      img.src = file.url;
    } else if (file.type === 'application/pdf') {
      setSize('portrait');
    }
  }, [file]);

  const handleClick = e => {
    e.preventDefault();
    if (file.type.startsWith('image/')) {
      onImageClick(file);
    } else {
      window.open(file.url, '_blank');
    }
  };

  switch (true) {
    case file.type.startsWith('image/'):
      return (
        <ImagePreview
          file={file}
          size={size}
          isSingle={isSingle}
          onClick={handleClick}
        />
      );
    case file.type.startsWith('video/'):
      return (
        <video
          controls
          className={`file-viewer__preview file-viewer__preview--video ${
            isSingle ? 'file-viewer__preview--single' : ''
          }`}>
          <source src={file.url} type={file.type} />
          Your browser does not support the video tag.
        </video>
      );
    case file.type === 'application/pdf':
      return (
        <div
          className={`file-viewer__preview file-viewer__preview--pdf file-viewer__preview--${size} ${
            isSingle ? 'file-viewer__preview--single' : ''
          }`}>
          <FileViewer type={'application/pdf'} url={file.url} />
        </div>
      );
    default:
      return (
        <div
          className={`file-viewer__preview file-viewer__preview--default ${
            isSingle ? 'file-viewer__preview--single' : ''
          }`}
          onClick={handleClick}>
          <RxFile size={24} />
          <span>{file.type.split('/')[1].toUpperCase()}</span>
        </div>
      );
  }
};

const FullScreenImage = ({file, onClose}) => {
  return (
    <div className="full-screen-image">
      <div className="full-screen-image__header">
        <h3>{file.key}</h3>
        <button onClick={onClose}>
          <RxCross2 size={24} />
        </button>
      </div>
      <div className="full-screen-image__content">
        <img src={file.url} alt={file.name} />
      </div>
    </div>
  );
};

export const FileViewerSimple = ({files}) => {
  const [expanded, setExpanded] = useState(false);
  const [fullScreenFile, setFullScreenFile] = useState(null);

  if (!files || files.length === 0) {
    return null;
  }

  const toggleExpanded = () => setExpanded(!expanded);

  const handleImageClick = file => {
    setFullScreenFile(file);
  };

  const closeFullScreen = () => {
    setFullScreenFile(null);
  };

  if (files.length === 1) {
    return (
      <div className="file-viewer file-viewer--single">
        <FilePreview
          file={files[0]}
          isSingle={true}
          onImageClick={handleImageClick}
        />
        <div className="file-viewer__info">
          {/* <div className="file-viewer__name">{files[0].key}</div> */}
          <button
            className="file-viewer__download"
            onClick={() => window.open(files[0].url, '_blank')}>
            <RxOpenInNewWindow size={18} />
          </button>
        </div>
        {fullScreenFile && (
          <FullScreenImage file={fullScreenFile} onClose={closeFullScreen} />
        )}
      </div>
    );
  }

  return (
    <div className="file-viewer">
      <div className="file-viewer__header" onClick={toggleExpanded}>
        <span className="file-viewer__title">
          <RxFile />
          {files.length} files attached
        </span>
        {expanded ? <RxChevronUp size={18} /> : <RxChevronDown size={18} />}
      </div>
      {expanded && (
        <div className="file-viewer__grid">
          {files.map((file, index) => (
            <div key={index} className="file-viewer__item">
              <FilePreview
                file={file}
                isSingle={false}
                onImageClick={handleImageClick}
              />

              <button
                className="file-viewer__download"
                onClick={() => window.open(files[index].url, '_blank')}>
                <RxOpenInNewWindow size={18} />
              </button>
            </div>
          ))}
        </div>
      )}
      {fullScreenFile && (
        <FullScreenImage file={fullScreenFile} onClose={closeFullScreen} />
      )}
    </div>
  );
};
