import React from 'react';
import {RxEnter} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import './styles.css';

export const ThreadItem = ({item, index, clickable}) => {
  const navigate = useNavigate();
  const {prettyTag} = useStringFormatter();
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_ids},
    defaultUpdate,
    updateThread,
  } = useThreads();

  const {id, title, content, admins, subscribers, tags, security_level, logo} =
    item;

  const joinable = security_level === 'open' && !subscribers?.includes(user_id);

  const isMember = subscribers?.includes(user_id) || admins?.includes(user_id);
  const getSecurityTitle = () => {
    switch (security_level) {
      case 'secure':
        return <span className="security-icon secure">Secure</span>;
      case 'private':
        return <span className="security-icon private">Private</span>;
      case 'open':
      default:
        return <span className="security-icon open">Public</span>;
    }
  };
  return (
    <div className="group-item">
      {isMember && (
        <button
          className="group-item__details"
          onClick={() => navigate(`/threads/${id}`)}>
          <RxEnter size={'15px'} />
        </button>
      )}
      {!isMember && security_level === 'private' && (
        <button
          className="group-item__details"
          onClick={() => navigate(`/threads/request/${id}`)}>
          Request to Join
        </button>
      )}
      {joinable && (
        <button
          className="group-item__details"
          onClick={async () => {
            await updateThread({id, subscribers: [...subscribers, user_id]});
            const new_threads = [...thread_ids];
            if (!new_threads?.includes(id)) {
              new_threads.push(id);
            }
            defaultUpdate({thread_ids: new_threads});
            navigate(`/threads/${id}`);
          }}>
          Join
        </button>
      )}
      <div className="group-item__content">
        <h3 className="group-item__name">{getSecurityTitle()}</h3>
        <h3 className="group-item__name padding-top8">
          {logo} {title}
        </h3>

        <p className="group-item__bio">
          {content?.length > 250 ? content.slice(0, 250) + '...' : content}
        </p>

        <div className="group-item__tags">
          {tags?.length > 0 && <p className="text-12">Topics:</p>}
          {tags?.slice(0, 12).map(tag => (
            <span key={tag} className="group-item__tag">
              {prettyTag(tag)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
