import DOMPurify from 'dompurify';
import {marked} from 'marked';
import constants from '../../../components/constants';

const threadContentNotification = props => {
  const {content, profile} = props || {};

  const {first_name, last_name} = profile || {};
  const pretty = `${first_name || ''} ${last_name || ''}`;

  const convertedContent = DOMPurify.sanitize(marked.parse(content.content));

  return `
      <html>
            <head>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
          <div style="text-align: center;">
            <img src=${
              constants.publicmind_logo
            } alt="PublicMind Logo" height="64" width="64" />
            <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
          </div>
          <br />
          <table width="100%" cellpadding="20" cellspacing="0" bgcolor="#f4f4f4">
            <tr>
              <td>                
               <h3 style="text-align: center;">New Tag From ${pretty}</h3>
                <br />           
                <p style="text-align: center; line-height: 2;">
                  ${convertedContent}
                </p>
                <br />
                <p style="text-align: center; margin: 0 64px; line-height: 2;">
                  View the thread for more details.
                </p>
                        <br />                

                                 <div style="text-align: center;">
    <a href=${
      constants.root_url + '/threads'
    } style="background-color: #000000; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
        Open Thread
    </a>
</div>
                <br />                
              </td>
            </tr>
          </table>
          <br />
          <p style="text-align: center;">Sent from PublicMind</p>
          <br />
          <div style="text-align: center;">
            <a href=${
              constants.help_center
            } style="margin: 0 8px;">Help Center</a>
            <a href=${
              constants.privacy_policy
            } style="margin: 0 8px;">Privacy Policy</a>
            <a href=${
              constants.terms_of_service
            } style="margin: 0 8px;">Terms of Service</a>
          </div>
        </body>
      </html>
      `;
};

export default threadContentNotification;
