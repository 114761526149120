/* eslint-disable import/no-anonymous-default-export */
import CryptoJS from 'crypto-js';

export default () => {
  const crypto_key = process.env.REACT_APP_SALT;

  const encryptString = string => {
    try {
      if (!string || !crypto_key) {
        return null;
      }
      const coded = CryptoJS.AES.encrypt(string, crypto_key).toString();
      return coded;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const decryptString = string => {
    try {
      if (!string || !crypto_key) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(string, crypto_key);
      const decoded = bytes.toString(CryptoJS.enc.Utf8);
      return decoded;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return {encryptString, decryptString};
};
