import constants from '../../components/constants';

const negativeFeedbackTemplate = props => {
  const {status, notes, profile} = props || {};
  const {first_name, last_name} = profile || {};

  const reason = status === 'ignore' ? 'ignored.' : 'saved for later.';

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src="${
            constants.publicmind_logo
          }" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 0 auto; max-width: 600px; background-color: #f4f4f4; padding: 20px; border-radius: 8px;">
          <h3 style="text-align: center; color: black;">Request ${reason}</h3>
          <br />
          <p style="text-align: left; line-height: 1.6; color: black;">
            Hello,
          </p>
          <br />
          <p style="text-align: left; line-height: 1.6; color: black;">
            Your connection request with ${first_name || ''} ${
    last_name || ''
  } was ${reason} The member gave the following reason...
          </p>
          <br/>
          <p style="text-align: center; background-color: #ffffff; padding: 16px; border-radius: 8px; border: 1px solid #ddd;">${
            notes || 'No reason given'
          }</p>
          <br />
          <p style="text-align: left; line-height: 1.6;">Best regards,</p>
          <p style="text-align: left; line-height: 1.6;">The PublicMind Team</p>
        </div>
        <br />
        <p style="text-align: center;">Sent from PublicMind</p>
        <br />
        <div style="text-align: center;">
          <a href="${
            constants.help_center
          }" style="margin: 0 8px; color: #75757a; text-decoration: none;">Help Center</a>
          <a href="${
            constants.privacy_policy
          }" style="margin: 0 8px; color: #75757a; text-decoration: none;">Privacy Policy</a>
          <a href="${
            constants.terms_of_service
          }" style="margin: 0 8px; color: #75757a; text-decoration: none;">Terms of Service</a>
        </div>
      </body>
    </html>
  `;
};

export default negativeFeedbackTemplate;
