import React, {useState} from 'react';
import {RiPushpinFill, RiPushpinLine} from 'react-icons/ri';
import {RxCopy, RxImage, RxTrash} from 'react-icons/rx';
import {ScheduleContentModal} from '../modal/thread-modals';
import './ChatActionButtons.css';
import { MdOutlineAttachment } from 'react-icons/md';

const ChatActionButtons = React.memo(
  ({
    formik,
    handleSendMessage,
    inputMessage,
    setInputMessage,
    copyString,
    setShowFileUploader,
    isEdit = false,
  }) => {
    const prio = formik?.values?.priority || false;

    const [schedule, setSchedule] = useState(false);

    return (
      <div className="button-group">
        <div className="left-buttons">
          <button
            type="submit"
            onClick={handleSendMessage}
            disabled={!inputMessage.trim() || formik.isSubmitting}
            className={formik.isSubmitting ? 'loading' : ''}>
            {formik.isSubmitting ? 'Sending...' : isEdit ? 'Save' : 'Comment'}
          </button>
        </div>
        {!isEdit && (
          <div className="left-buttons">
            <button
              onClick={() => {
                setSchedule(!schedule);
              }}
              disabled={!inputMessage.trim() || formik.isSubmitting}
              className={formik.isSubmitting ? 'loading' : ''}>
              {formik.isSubmitting ? 'Scheduling...' : 'Schedule'}
            </button>
          </div>
        )}
        <div className="right-buttons">
          <button onClick={() => setShowFileUploader(prev => !prev)}>
            <MdOutlineAttachment />
          </button>
          <button
            onClick={() => {
              copyString(inputMessage);
            }}>
            <RxCopy />
          </button>
          <button
            onClick={() => {
              setInputMessage('');
              formik.setFieldValue('media', []);
            }}>
            <RxTrash />
          </button>

          <button
            onClick={() => {
              formik.setFieldValue('priority', !prio);
            }}>
            {prio ? <RiPushpinFill /> : <RiPushpinLine />}
          </button>
        </div>
        <ScheduleContentModal
          active={schedule}
          setActive={setSchedule}
          formik={formik}
        />
      </div>
    );
  },
);

export default ChatActionButtons;
