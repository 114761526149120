import React from 'react';
import {BsInfo} from 'react-icons/bs';
import './styles.css';

export const Badge = ({count}) => {
  if (!count || count === 0) {
    return null;
  }

  return <div className="badge">{count || <BsInfo />}</div>;
};

export const BadgeDot = ({active, style}) => {
  if (!active) {
    return null;
  }
  return <div className="badge-dot" style={style} />;
};
export const BadgeDotRelative = ({active, style}) => {
  if (!active) {
    return null;
  }
  return <div className="badge-dot-relative" style={style} />;
};
