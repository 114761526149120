import {useFormik} from 'formik';
import React, {useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {identify, track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import tagsJSON from '../../assets/lists/tags.json';
import constants from '../../components/constants';
import {FormikArraySearchableDropDown} from '../../components/inputs/drop-down';
import {
  FormikSubtleTextfield,
  MultilineFormikSubtleTextfield,
} from '../../components/inputs/textfields';
import {ProfileImage} from '../../components/inputs/uploader';
import {AuthHeader} from '../../components/layout/layout';
import {TooltipNoShadow} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import {useWorkspace} from '../../hooks/use-workspace';

const WelcomeAccount = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();

  const {
    state: {id, profile, current_group, current_organization},
    updateProfile,
  } = useAuth();
  const {createPost, manageOpportunities} = useWorkspace();

  const [file, setFile] = useState(null);

  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name ?? '',
      last_name: profile?.last_name ?? '',
      position: profile?.position ?? '',
      bio: profile?.bio ?? '',
      title: '',
      description: '',
      inner_tags: [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required('You must add a first name.'),
      last_name: Yup.string().trim().required('You must add a last name.'),
      title: Yup.string().trim().required('You must add a title.'),
      description: Yup.string().trim().required('You must add a description.'),
      position: Yup.string().trim(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {
          first_name,
          last_name,
          position,
          bio,
          phone,
          properties,
          title,
          description,
          inner_tags,
        } = values;

        if (!inner_tags.includes('opp_networking')) {
          inner_tags.push('opp_networking');
        }

        const status =
          profile?.status === 'complete' ? 'complete' : 'in_progress';
        const update = {
          id,
          first_name,
          last_name,
          position,
          bio,
          phone,
          status,
          properties,
          inner_tags,
          post_created: dateToTimestamp(),
        };
        if (file) {
          update.profile_image = file;
        }
        const {success, error} = await updateProfile(update);

        if (success) {
          // CREATE POST

          const values = {
            status: 'active',
            org_id: current_organization,
            user_id: id,
            properties: profile?.properties ?? [],
            type: 'resource',
            title,
            description,
            group_ids: [current_group],
            time_zone: null,
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            location: null,
            media: [],
            inner_tags,
            outer_tags: [],
            asks: [],
            links: [],
          };
          const {success, error, data} = await createPost(values);

          // IF CREATED POST
          if (success) {
            const {id: post_id} = data;

            formik.resetForm();
            navigate(`/posts/share/${post_id}`);
            track('post_created', data);
            const body = {
              id,
              post_id,
              type: 'post',
              inner_added: inner_tags,
              inner_removed: [],
              outer_added: [],
              outer_removed: [],
              group_ids: [current_group],
            };

            manageOpportunities(body, post_id);
          }

          if (error) {
            navigate('/home');
          }

          // HANDLE TRACKING
          track('create_profile', update);
          const {organization_ids, createdAt = ''} = profile;
          identify(id, {
            $email: id,
            $first_name: first_name,
            $last_name: last_name,
            $created: createdAt,
            aws_sub: id,
            organization_ids,
            phone,
            position,
            bio,
            status,
          });

          // INTERCOM
          update({
            email: id,
            userId: id,
            name: `${first_name} ${last_name}`,
            createdAt,
            organization_ids,
          });
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="Let's start with a post introducing yourself!"
          subheader={
            'Click each section below to add your details. This will go live for other members to see.'
          }
          action={true}
        />
        <div className="flex justify-center">
          <form className={mobile ? 'mobile-form' : 'width-640'}>
            <div className="flex-row align-center">
              <TooltipNoShadow text={'Click to add a profile picture'}>
                <ImageUploader file={file} setFile={setFile} />
              </TooltipNoShadow>
              <div className="padding-left8">
                <FormikSubtleTextfield
                  formik={formik}
                  placeholder="First Name"
                  name="first_name"
                  type="text"
                />
                <FormikSubtleTextfield
                  formik={formik}
                  placeholder="Last Name"
                  name="last_name"
                  type="text"
                />
              </div>
            </div>
            <span className="padding-top8">
              <FormikSubtleTextfield
                formik={formik}
                placeholder="Job Title"
                name="position"
                type="text"
              />
            </span>
            <br />
            <FormikSubtleTextfield
              formik={formik}
              placeholder="I work on ..."
              name="title"
              type="text"
            />
            <br />
            <MultilineFormikSubtleTextfield
              formik={formik}
              rows={6}
              placeholder="A few other things you should know..."
              name="description"
              type="text"
            />
            <FormikArraySearchableDropDown
              formik={formik}
              header="A few tags I'm interested in?"
              name="inner_tags"
              items={tagsJSON}
              mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
            />
          </form>
        </div>
      </div>
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="button padding-top16">
        <button
          type="button"
          className="button-container"
          onClick={formik.handleSubmit}
          disabled={
            formik.isSubmitting ||
            !formik.values.first_name ||
            !formik.values.last_name ||
            !formik.values.position ||
            !formik.values.title ||
            !formik.values.description
          }>
          POST
        </button>
      </div>
    </>
  );
};

const ImageUploader = props => {
  const {file, setFile} = props;

  const types = constants.image_mime_types;
  const limit = constants.file_20mb;

  const {
    state: {profile},
  } = useAuth();

  const url = profile?.profile_image ?? null;

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  const handleEditClick = () => {
    // Trigger the file input click event
    const inputElement = document.getElementById('image_input');
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div>
      <input
        id="image_input"
        type="file"
        accept={'image/*'}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <ProfileImage
        data={file ? {url: URL.createObjectURL(file)} : url}
        onClick={handleEditClick}
        type={'user'}
        style={{height: '48px', width: '48px'}}
      />
    </div>
  );
};

export default WelcomeAccount;
