import DOMPurify from 'dompurify';
import constants from '../../../components/constants';

const generateThreadInvitation = props => {
  const {thread} = props || {};
  const {title, content} = thread;

  // Sanitize and parse the title, ensuring it's treated as plain text
  const sanitizedTitle = DOMPurify.sanitize(title, {ALLOWED_TAGS: []});

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <style>
      body {
        font-family: 'Montserrat', sans-serif;
        color: #000000;
        letter-spacing: 0.1em;
        margin: 0;
        padding: 0;
        background-color: #ffffff;
      }
      .container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
      }
      .logo {
        text-align: center;
        margin-bottom: 20px;
      }
      .content {
        background-color: #f4f4f4;
        padding: 20px;
        border-radius: 5px;
      }
      .button {
        display: inline-block;
        background-color: #000000;
        color: white;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        border: 1px solid #333333;
      }
      .footer {
        text-align: center;
        margin-top: 20px;
      }
      .footer a {
        margin: 0 8px;
        color: #000000;
        text-decoration: none;
      }
      @media (max-width: 600px) {
        .container {
          width: 100%;
          padding: 10px;
        }
        .content {
          padding: 15px;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="logo">
        <h2 style="font-weight: 300;">PUBLICMIND</h2>
      </div>
      <div class="content">
        <h3 style="text-align: center;">New Thread Invitation</h3>
        <p style="line-height: 1.6;">Hello,</p>
        <p style="line-height: 1.6;">You have been invited to join the <strong>"${sanitizedTitle}"</strong> thread. View the thread for more details.</p>
        <div style="text-align: center; margin: 20px 0;">
          <a href="${constants.root_url}/threads" class="button">Open Thread</a>
        </div>
      </div>
      <div class="footer">
        <p>Sent from PublicMind</p>
        <div>
          <a href="${constants.help_center}">Help Center</a>
          <a href="${constants.privacy_policy}">Privacy Policy</a>
          <a href="${constants.terms_of_service}">Terms of Service</a>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;
};

export default generateThreadInvitation;
