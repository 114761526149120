import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {IoMdArrowBack} from 'react-icons/io';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
// import '../../App.css';
import {track} from '../../api/analytics';
import tagsJSON from '../../assets/lists/tags.json';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {ErrorButton} from '../../components/buttons/buttons';
import constants from '../../components/constants';
import {FormikDateInput} from '../../components/inputs/date-input';
import {FormikArraySearchableDropDown} from '../../components/inputs/drop-down';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {Modal} from '../../components/modal/modal';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import useTracker from '../../hooks/use-tracker';
import {useWorkspace} from '../../hooks/use-workspace';
import {
  ContentDetails,
  PeopleDetails,
  PostOpportunities,
} from './post-components';

const PostDetail = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {loadOrgs, loadProfiles} = useLoader();
  const {trackInteraction} = useTracker();

  const {
    state: {id: current_id, auth, profiles, organizations, profile},
  } = useAuth();
  const {
    state: {posts, post_ids, post_token, content_opps},
    getPost,
    getPosts,
  } = useWorkspace();

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const post = posts?.[id] ?? {};
  const {org_id, user_id} = post;

  // LOAD POST
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getPost(id);
      setLoading(false);

      if (!post_ids.length) {
        const query = {
          status: 'active',
          sortDirection: 'DESC',
          nextToken: post_token,
          limit: 3,
        };
        await getPosts(query);
      }
    };
    if (posts[id] === undefined) {
      load();
    }
  }, []);

  // LOAD PROFILE
  useEffect(() => {
    if (user_id && profiles[user_id] === undefined) {
      loadProfiles([user_id], {authMode: 'apiKey'});
    }
  }, [user_id]);

  // LOAD ORG
  useEffect(() => {
    if (org_id && organizations[org_id] === undefined) {
      loadOrgs([org_id], {authMode: 'apiKey'});
    }
  }, [org_id]);

  useEffect(() => {
    if (id && user_id) {
      trackInteraction({
        target_user: user_id,
        content_id: id,
        content_type: 'post',
        action: 'view',
      });
    }
  }, [id, user_id]);

  if (!id || !post?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="post-detail-container">
      {auth && (
        <div className="flex-row justify-between align-center">
          <div className="margin-h8">
            <IoMdArrowBack
              className="clickable"
              onClick={() => {
                if (window.history.length <= 1) {
                  navigate('/home');
                } else {
                  navigate(-1);
                }
              }}
            />
          </div>
          {user_id === profile?.id && (
            <button
              className="button-container"
              onClick={() => {
                setEdit(!edit);
              }}>
              {edit ? 'CANCEL' : 'EDIT'}
            </button>
          )}
        </div>
      )}
      {edit ? (
        <div>
          <EditPost id={id} />
        </div>
      ) : (
        <div>
          <ContentDetails item={post} />
          <PeopleDetails item={post} />
          {/* <PostOpportunities post_id={id} /> */}
        </div>
      )}
    </div>
  );
};
export const PostOpps = ({active, setActive, id}) => {
  const {loadOrgs, loadProfiles} = useLoader();

  const {
    state: {id: current_id, auth, profiles, organizations, profile},
  } = useAuth();
  const {
    state: {posts, post_ids, post_token, content_opps},
    getPost,
    getPosts,
    getOpportunitiesByContent,
  } = useWorkspace();

  const [loading, setLoading] = useState(false);
  const [loadOpps, setLoad] = useState(false);

  const post = posts?.[id] ?? {};
  const {org_id, user_id} = post;

  // LOAD POST
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getPost(id);
      setLoading(false);

      if (!post_ids.length) {
        const query = {
          status: 'active',
          sortDirection: 'DESC',
          nextToken: post_token,
          limit: 3,
        };
        await getPosts(query);
      }
    };
    if (posts[id] === undefined) {
      load();
    }
  }, []);

  // LOAD OPPS
  useEffect(() => {
    const load = async () => {
      const query = {
        filter: {
          content_ids: {contains: id},
          members: {contains: current_id},
          status: {ne: 'closed'},
        },
        limit: constants.all_items,
      };
      setLoad(true);
      await getOpportunitiesByContent(query, id);
      setLoad(false);
    };

    if (active && content_opps[id] === undefined) {
      load();
    }
  }, [active]);

  // LOAD PROFILE
  useEffect(() => {
    if (user_id && profiles[user_id] === undefined) {
      loadProfiles([user_id], {authMode: 'apiKey'});
    }
  }, [user_id]);

  // LOAD ORG
  useEffect(() => {
    if (org_id && organizations[org_id] === undefined) {
      loadOrgs([org_id], {authMode: 'apiKey'});
    }
  }, [org_id]);

  if (!id || !post?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <Modal active={active} setActive={setActive}>
      {loadOpps ? <SpinningIndicator /> : <PostOpportunities post_id={id} />}
    </Modal>
  );
};

function separateByType(data) {
  const collaborators = [];
  const others = [];

  data.forEach(item => {
    if (item.type === 'collaborator') {
      collaborators.push(item);
    } else {
      others.push(item);
    }
  });

  return {collaborators, others};
}

const EditPost = ({id}) => {
  const navigate = useNavigate();
  const {tagDifferences} = useCollaborator();
  const {
    state: {profile, current_organization, groups},
  } = useAuth();
  const {
    state: {posts},
    updatePost,
    manageOpportunities,
  } = useWorkspace();

  const mobile = isMobile || isTablet;
  const {group_ids: user_groups} = profile || {};
  const mapped = user_groups?.length
    ? user_groups.map(id => {
        const {name} = groups?.[id] ?? {};
        return {value: id, key: name};
      })
    : [];

  const [popup, setPopup] = useState({message: '', on: false});

  const post = posts?.[id] ?? {};
  const {
    status,
    type,
    org_id,
    user_id,
    properties,
    title,
    description,
    time_zone,
    start_date,
    start_time,
    end_date,
    end_time,
    location,
    media,
    tags,
    inner_tags,
    outer_tags,
    asks,
    links,
    group_ids,
  } = post;

  const formik = useFormik({
    initialValues: {
      status: status || 'active',
      type: type || 'project',
      org_id: org_id || current_organization,
      user_id: user_id || id,
      properties: properties || (profile?.properties ?? []),
      title: title || '',
      description: description || '',
      time_zone: time_zone || null,
      start_date: start_date || null,
      start_time: start_time || null,
      end_date: end_date || null,
      end_time: end_time || null,
      location: location || null,
      media: media || [],
      tags: tags || [],
      inner_tags: inner_tags || [],
      outer_tags: outer_tags || [],
      asks: asks || [],
      links: links || [],
      group_ids: group_ids || [],
    },
    validationSchema: Yup.object({
      title: Yup.string(),
      description: Yup.string(),
      group_ids: Yup.array().min(1, 'You must include a group.'),
      type: Yup.string().required('Type is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {createdAt, updatedAt, __typename, ...rest} = post;
        const {inner_tags: starting_inner, outer_tags: starting_outer} = post;
        const {inner_tags, outer_tags, group_ids} = values;
        const updated = {...rest, ...values};

        const {added: inner_added, removed: inner_removed} = tagDifferences(
          starting_inner,
          inner_tags,
        );
        const {added: outer_added, removed: outer_removed} = tagDifferences(
          starting_outer,
          outer_tags,
        );

        if (updated.media) {
          updated.media = updated.media.map(({url, ...rest}) => rest);
        }

        const {success, error} = await updatePost(updated);

        if (success) {
          setPopup({on: true, message: 'SUCCESS!'});
          track('post_edited', {post: updated});
          const body = {
            id: profile.id,
            post_id: id,
            type: 'post',
            inner_added,
            inner_removed,
            outer_added,
            outer_removed,
            group_ids,
          };

          manageOpportunities(body, id);
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="card pretty-form-group">
        <FormikTextfield
          formik={formik}
          placeholder="Seeking partners for..."
          name="title"
          type="text"
          header="Title"
        />
        <MultilineFormikTextfield
          formik={formik}
          rows={4}
          header="Description"
          name="description"
          placeholder={
            'What is this Request for? You can add a document or file below...'
          }
        />
        <FormikArraySearchableDropDown
          formik={formik}
          name="group_ids"
          header="Groups"
          placeholder={'Where do you want to post this?'}
          items={mapped}
        />
        {formik.values.type !== 'request' && (
          <FormikArraySearchableDropDown
            formik={formik}
            header="Resource-Related Tags"
            name="inner_tags"
            items={tagsJSON}
            mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
          />
        )}
        {formik.values.type !== 'resource' && (
          <FormikArraySearchableDropDown
            formik={formik}
            header="Requests-Related Tags"
            name="outer_tags"
            items={tagsJSON}
            mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
          />
        )}

        <div className="grid-container">
          <div className="grid-6">
            <div className={mobile ? '' : 'margin-r16'}>
              <FormikDateInput
                formik={formik}
                placeholder=""
                name="start_date"
                header="Request Start Date"
              />
            </div>
          </div>
          <div className="grid-6">
            <div className={mobile ? '' : 'margin-l16'}>
              <FormikDateInput
                formik={formik}
                placeholder=""
                name="end_date"
                header="Request End Date"
              />
            </div>
          </div>
        </div>

        {formik.errors.submit && (
          <p className="error">{formik.errors.submit}</p>
        )}
        <div className="flex-row justify-center padding-top8">
          <ErrorButton
            onClick={async () => {
              const {id} = post;
              const updated = {id, status: 'archived'};

              const {success, error} = await updatePost(updated);

              if (success) {
                setPopup({on: true, message: 'Archive complete'});
                if (window.history.length <= 1) {
                  navigate('/home');
                } else {
                  navigate(-1);
                }
                track('post_archived', {post_id: id});
                const body = {
                  id: profile.id,
                  post_id: id,
                  type: 'post',
                  inner_added: [],
                  inner_removed: [],
                  outer_added: [],
                  outer_removed: [],
                  group_ids,
                  force_delete: true,
                };

                manageOpportunities(body, id);
              } else {
                setPopup({
                  on: true,
                  message: 'Error - reach out to the PublicMind Team',
                });
              }
            }}>
            ARCHIVE
          </ErrorButton>
          <button
            className="button-container"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            SAVE
          </button>
        </div>
      </div>

      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export default PostDetail;
