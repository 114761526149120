import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {RxEnvelopeClosed, RxExit, RxPencil1} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import {v4} from 'uuid';
import {StatusIcon} from '../../components/widgets/widgets';

import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {dateToTimestamp, formatMMDDYYYY} from '../../api/dates';
import constants from '../../components/constants';
import {
  ArrayFormikTextfield,
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {DragAndDropUploader, Uploader} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {InviteGroupModal} from '../../components/modal/invite-group-modal';
import {NudgeInvitation} from '../../components/modal/schedule-modal';
import Tooltip from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';

const GroupDetail = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();

  const {
    state: {groups, profile},
    getGroup,
  } = useAuth();
  const {
    state: {id: user_id, profiles},
    updateGroup,
    updateProfile,
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [invite, setInvite] = useState(false);
  const [active, setActive] = useState(false);
  const [other_profile, setOther] = useState({});

  const group = groups?.[id] ?? {};

  const {members, admins, type} = group;
  const invitable = type === 'public' ? true : admins?.includes(user_id);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getGroup(id);
      setLoading(false);
    };
    if (groups?.[id] === undefined) {
      load();
    }
  }, []);

  useEffect(() => {
    loadProfiles(members);
  }, [members]);

  return (
    <div className="page-container">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <div>
          <GroupInfo id={id} />
          <div className="card">
            <div className="flex-row justify-between align-center">
              <h4>Members</h4>
              <div className="flex-row align-center">
                {invitable && (
                  <Tooltip text={'Invite members'}>
                    <span className="flex-column align-center">
                      <RxEnvelopeClosed
                        className="cursor-pointer"
                        onClick={() => {
                          setInvite(!invite);
                        }}
                        size={'14px'}
                      />
                      <p className="text-secondary text-10">Invite</p>
                    </span>
                  </Tooltip>
                )}
                {!!admins.length && (
                  <Tooltip text={'Leave Group'}>
                    <span className="flex-column align-center padding-left8">
                      <RxExit
                        className="cursor-pointer"
                        onClick={async () => {
                          const {group_ids} = profile || {};

                          const mem = [...(members || [])].filter(
                            id => id !== user_id,
                          );

                          const adm = [...(admins || [])].filter(
                            id => id !== user_id,
                          );

                          const grp = [...(group_ids || [])].filter(
                            gpr_id => gpr_id !== id,
                          );

                          const {success, error} = await updateGroup({
                            id,
                            updated: dateToTimestamp(),
                            members: mem,
                            admins: adm,
                          });
                          if (success) {
                            const {success, error} = await updateProfile({
                              id: user_id,
                              group_ids: grp,
                            });
                            if (success) {
                              navigate(-1);
                            }
                          }
                        }}
                        size={'14px'}
                      />
                      <p className="text-secondary text-10">Leave</p>
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>

            {members.map(mem_id => {
              const profile = profiles?.[mem_id] ?? {};
              const {status, referral_user} = profile;
              const name = prettyName(profile);

              return (
                <div className="group-member-item" key={mem_id}>
                  <div className="flex-row align-center justify-between">
                    <div className="flex-row align-center">
                      <StatusIcon status={status} />

                      <p
                        className="text-bold"
                        onClick={() => {
                          navigate(`/feed/profiles/detail/${mem_id}`);
                        }}>
                        {name}
                      </p>
                      {profile.position && (
                        <p className="text-secondary text-12 margin-h8">
                          {profile.position}
                        </p>
                      )}
                      {referral_user === user_id && status === 'unactivated' ? (
                        <p
                          className="margin-h8 text-12 remind-link clickable-text"
                          onClick={() => {
                            setOther(profile);
                            setActive(!active);
                          }}>
                          [Send Reminder]
                        </p>
                      ) : // <span className="fake-button" />
                      null}
                    </div>
                    {type === 'private' && (
                      <div className="flex-row align-center">
                        <p className="text-secondary text-12">{mem_id}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <NudgeInvitation
        active={active}
        setActive={setActive}
        referral={other_profile}
      />
      <InviteGroupModal active={invite} setActive={setInvite} group_id={id} />
    </div>
  );
};

const GroupInfo = ({id}) => {
  const navigate = useNavigate();

  const {
    state: {id: user_id, groups, profile, current_group},
    updateGroup,
    updateProfile,
    defaultUpdate,
  } = useAuth();
  const {defaultUpdate: update} = useWorkspace();

  const group = groups?.[id] ?? {};
  const {group_ids} = profile || {};
  const initial = group_ids?.[0] === id;

  const {name, bio, admins, members, image, created, tags} = group;
  const editable = admins?.includes(user_id);
  const pretty_date = formatMMDDYYYY(new Date(created * 1000));

  const [file, setFile] = useState(null);
  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: id || v4(),
      name: name || '',
      bio: bio || '',
      admins: admins || [id],
      members: members || [id],
      tags: tags || [],
      created: created || dateToTimestamp(),
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      bio: Yup.string().required('About is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // CREATE GROUP
        const now = dateToTimestamp();

        if (file) {
          values.image = file;
        }

        const grp = {...values, updated: now};
        const {success, error} = await updateGroup(grp);

        if (success) {
          setEdit(false);
          track('group_edited', {group: grp});
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error.message});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (edit) {
    return (
      <div className="card pretty-form-group">
        <IoMdArrowBack
          className="clickable"
          onClick={() => {
            setEdit(!edit);
          }}
        />
        <div className="flex-row justify-between">
          <FormikTextfield
            formik={formik}
            name="name"
            type="text"
            header="Group Name"
          />
        </div>
        <MultilineFormikTextfield
          rows={5}
          formik={formik}
          name="bio"
          type="text"
          header="About"
        />
        {/* <ArrayFormikTextfield
        formik={formik}
        name="admins"
        type="text"
        header="Admins"
      />
      <ArrayFormikTextfield
        formik={formik}
        name="members"
        type="text"
        header="Members"
      /> */}
        <ArrayFormikTextfield
          formik={formik}
          name="tags"
          type="text"
          header="Tags"
        />
        <label className="padding-top8">Attach a Group Logo</label>
        <DragAndDropUploader
          file={file}
          limit={constants.file_20mb}
          setFile={setFile}
          types={[
            ...constants.image_mime_types,
            ...constants.video_mime_types,
            ...constants.pdf_mime_type,
          ]}
        />
        <Uploader
          file={file}
          limit={constants.file_20mb}
          setFile={setFile}
          types={[
            ...constants.image_mime_types,
            ...constants.video_mime_types,
            ...constants.pdf_mime_type,
          ]}
        />
        {formik.errors.submit && (
          <p className="error">{formik.errors.submit}</p>
        )}
        <div className="flex justify-center">
          <button
            className="button-container"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <IoMdArrowBack
        className="clickable"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="card">
        <div className="flex-row justify-between">
          <div className="flex-row align-center">
            {image?.url && (
              <img
                src={image?.url}
                alt=""
                style={{height: '32px', width: '32px', marginRight: '8px'}}
              />
            )}
            <h4 className="">{name}</h4>
          </div>
          {editable && (
            <Tooltip text={'Edit group'}>
              <span className="flex-column align-center">
                <RxPencil1
                  className="cursor-pointer"
                  onClick={() => {
                    setEdit(!edit);
                  }}
                  size={'14px'}
                />
                <p className="text-secondary text-10">{'Edit'}</p>
              </span>
            </Tooltip>
          )}
        </div>
        <p className="text-left">{bio}</p>
        <div className="flex-row-wrap padding-top8">
          {tags.slice(0, 12).map(tag => {
            return (
              <p key={tag} className="bubble" onClick={() => {}}>
                {tag}
              </p>
            );
          })}
        </div>
        {created && (
          <p className="text-left text-secondary text-14">
            Created: {pretty_date}
          </p>
        )}
        {!initial && (
          <p
            className="text-link text-left cursor-pointer"
            onClick={async () => {
              const index = group_ids?.findIndex(item => item === id);

              if (!index) {
                return;
              }

              const grps = [...(group_ids || [])];
              const [removedItem] = grps.splice(index, 1);
              grps.unshift(removedItem);

              await updateProfile({id: user_id, group_ids: grps});
              track('default_group_updated', {group_id: id});

              const active = current_group === id;
              if (!active) {
                defaultUpdate({
                  current_group: id,
                  profiles_loaded: false,
                  profile_token: null,
                  orgs_loaded: false,
                  org_token: null,
                });
                update({
                  posts_loaded: false,
                  post_token: null,
                  feed_loaded: false,
                  feed_token: null,
                  new_opps_loaded: false,
                  opp_token: null,
                  feed: [],
                  post_feed: [],
                  user_posts: {},
                });
                track('current_group_updated', {group_id: id});
              }
            }}>
            Set Group as Default
          </p>
        )}
      </div>
    </>
  );
};

export default GroupDetail;
