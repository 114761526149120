import {useFormik} from 'formik';
import React, {useEffect, useMemo, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {RxLockClosed, RxLockOpen1, RxLockOpen2} from 'react-icons/rx';

import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import {sendThreadInvitations} from '../../api/email';
import tagsJSON from '../../assets/lists/tags.json';
import {
  FormikArraySearchableDropDown,
  FormikSearchableDropDown,
} from '../../components/inputs/drop-down';
import {EmojiInput} from '../../components/inputs/emoji-input';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {Tab} from '../../components/tab/tab';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {mergeArrays} from '../../utils/utils';
import './CreateThread.css';

const CreateThread = ({}) => {
  const [index, setIndex] = useState(0);
  const [toggleType, setToggleType] = useState('Open');
  const tabs = [
    {tab: 'Thread', index: 0},
    {tab: 'Direct Message', index: 1},
  ];

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />
      {index === 0 && (
        <TypeToggle toggleType={toggleType} setToggleType={setToggleType} />
      )}

      {index === 0 && <CreateThreadChain toggleType={toggleType} />}
      {index === 1 && <CreateDM />}
      <br />
      <br />
    </div>
  );
};

const CreateDM = ({}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {
    state: {id, current_group, groups, profiles, profile},
  } = useAuth();
  const {createThread} = useThreads();

  const [file, setFile] = useState(null);

  const {group_ids} = profile || {};

  const mappedGroups = useMemo(() => {
    return group_ids?.length
      ? group_ids.map(id => {
          const {name} = groups?.[id] ?? {};
          return {value: id, key: name};
        })
      : [];
  }, [group_ids, groups]);

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      group_id: current_group,
      status: 'active',
      type: 'direct',
      security_level: 'secure',
      admins: [],
      subscribers: [],
      media: [],
      tags: [],
      title: '',
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      group_id: Yup.string().required('Group is required'),
      admins: Yup.array()
        .max(1, 'You may only pick one person.')
        .min(1, 'You must pick one person.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {admins, subscribers} = values;
        const merged_admins = [id, ...admins];
        const {merged} = mergeArrays(merged_admins, subscribers);
        const now = dateToTimestamp();

        const otherPersonName = prettyName(profiles[admins[0]]);
        const currentUserName = prettyName(profiles[id]);

        const thread = {
          ...values,
          title: `${currentUserName} and ${otherPersonName}`,
          content: `A conversation between ${currentUserName} and ${otherPersonName}`,
          admins: merged_admins,
          subscribers: merged,
          created: now,
          updated: now,
        };

        if (file) {
          thread.media = [file];
        }

        const {success, error} = await createThread(thread);
        if (success) {
          helpers.resetForm();
          navigate(-1);
          await sendThreadInvitations({
            emails: merged,
            thread,
          });
        }
        if (error) {
          // TODO: Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const group = formik.values.group_id;
  const {members} = groups?.[group] ?? {};

  useEffect(() => {
    if (group && members?.length) {
      loadProfiles(members);
    }
    if (!group) {
      formik.setFieldValue('admins', []);
      formik.setFieldValue('subscribers', []);
    }
  }, [group]);

  const mapped = members?.map(member_id => {
    const profile = profiles[member_id];
    const name = prettyName(profile);
    return {key: name, value: member_id, subtext: member_id.split('@')[0]};
  });

  return (
    <div className="">
      <div className="create-thread">
        <IoMdArrowBack
          className="clickable padding-top8"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="create-thread__header">
          <h4>Create a Direct Message</h4>
        </div>
        <div className="create-thread__form pretty-form-group">
          <div className="create-thread__field">
            <FormikSearchableDropDown
              formik={formik}
              header={'Group'}
              name="group_id"
              placeholder={'Where do you want this thread?'}
              items={mappedGroups}
            />
          </div>
          {formik.values.group_id && (
            <div>
              <div className="create-thread__field">
                <FormikArraySearchableDropDown
                  formik={formik}
                  header={'Member'}
                  name={'admins'}
                  items={mapped}
                />
              </div>
            </div>
          )}
          <FormikArraySearchableDropDown
            formik={formik}
            header="Tags"
            name="tags"
            items={tagsJSON}
            mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
          />
          <div className="create-thread__submit-container">
            <button
              className="create-thread__submit-button"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}>
              Create Direct Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateThreadChain = ({toggleType}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {
    state: {id, current_group, groups, profiles, profile},
  } = useAuth();
  const {createThread} = useThreads();

  const [file, setFile] = useState(null);
  const [emojiPicker, setEmoji] = useState(false);

  const {group_ids} = profile || {};

  const mappedGroups = useMemo(() => {
    return group_ids?.length
      ? group_ids.map(id => {
          const {name} = groups?.[id] ?? {};
          return {value: id, key: name};
        })
      : [];
  }, [group_ids, groups]);

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      group_id: current_group,
      status: 'active',
      type: 'thread',
      security_level: toggleType.toLowerCase(),
      admins: [],
      subscribers: [],
      media: [],
      tags: [],
      title: '',
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      // content: Yup.string().required('Description is required'),
      // group_id: Yup.string().required('Group is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {admins, subscribers} = values;
        values.security_level = toggleType.toLowerCase();
        const merged_admins = [id, ...admins];
        const {merged} = mergeArrays(merged_admins, subscribers);
        const now = dateToTimestamp();
        const thread = {
          ...values,
          admins: merged_admins,
          subscribers: merged,
          created: now,
          updated: now,
        };
        if (file) {
          thread.media = [file];
        }
        const {success, error} = await createThread(thread);
        if (success) {
          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const {success, error} = await sendThreadInvitations({
            emails: merged,
            thread,
          });
        }
        if (error) {
          // TODO
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const group = formik.values.group_id;
  const {members} = groups?.[group] ?? {};

  useEffect(() => {
    if (group && members?.length) {
      loadProfiles(members);
    }
    if (!group) {
      formik.setFieldValue('admins', []);
      formik.setFieldValue('subscribers', []);
    }
  }, [group]);

  const mapped = members?.map(member_id => {
    const profile = profiles[member_id];
    const name = prettyName(profile);
    return {key: name, value: member_id};
  });

  return (
    <div className="">
      <div className="create-thread">
        <IoMdArrowBack
          className="clickable padding-top8"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="create-thread__header">
          <h4>
            Create a{' '}
            <strong>{toggleType === 'Open' ? 'Public' : toggleType}</strong>{' '}
            Thread
          </h4>
          <p className="text-secondary">
            You will be able to invite people after you create the thread
          </p>
        </div>
        <div className="create-thread__form pretty-form-group">
          <label className="">Thread Logo</label>
          <EmojiInput
            emoji={formik?.values?.logo}
            setEmoji={emoji => {
              formik.setFieldValue('logo', emoji);
            }}
            setShowPicker={setEmoji}
            showPicker={emojiPicker}
          />
          <div className="create-thread__field ">
            <FormikTextfield
              formik={formik}
              name={'title'}
              header="Thread Title"
            />
          </div>
          <div className="create-thread__field">
            <MultilineFormikTextfield
              formik={formik}
              name={'content'}
              header="Thread Description"
              rows={10}
            />
          </div>
          {/* <div className="create-thread__field">
            <FormikSearchableDropDown
              formik={formik}
              header={'Group'}
              name="group_id"
              placeholder={'Where do you want this thread?'}
              items={mappedGroups}
            />
          </div> */}
          {formik.values.group_id && (
            <div>
              {/* <div className="create-thread__field">
                <FormikArraySearchableDropDown
                  formik={formik}
                  header={'Admins'}
                  name={'admins'}
                  items={mapped}
                />
              </div> */}
              {/* <div className="create-thread__field">
                <FormikArraySearchableDropDown
                  formik={formik}
                  header={'Subscribers'}
                  name={'subscribers'}
                  items={mapped}
                />
              </div> */}
            </div>
          )}
          {/* <FormikArraySearchableDropDown
            formik={formik}
            header="Tags"
            name="tags"
            items={tagsJSON}
            mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
          /> */}

          <div className="create-thread__submit-container">
            <button
              className="create-thread__submit-button"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}>
              Create{' '}
              <strong>{toggleType === 'Open' ? 'Public' : toggleType}</strong>{' '}
              Thread
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpdateThread = ({formik, isSmall}) => {
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {
    state: {id, current_group, groups, profiles, profile},
  } = useAuth();

  const group = formik.values.group_id;
  const {members} = groups?.[group] ?? {};

  const {group_ids} = profile || {};
  const [emojiPicker, setEmoji] = useState(false);
  useEffect(() => {
    if (group && members?.length) {
      loadProfiles(members);
    }
  }, [group]);
  const mappedGroups = useMemo(() => {
    return group_ids?.length
      ? group_ids.map(id => {
          const {name} = groups?.[id] ?? {};
          return {value: id, key: name};
        })
      : [];
  }, [group_ids, groups]);

  const mapped = members?.map(member_id => {
    const profile = profiles[member_id];
    const name = prettyName(profile);
    return {key: name, value: member_id};
  });
  return (
    <div className="create-thread">
      <div className="create-thread__header">
        {!isSmall && <h4>Update Your Thread</h4>}
      </div>
      <div className="create-thread__form pretty-form-group">
        <div className="create-thread__field">
          <label className="">Thread Logo</label>
          <EmojiInput
            emoji={formik?.values?.logo}
            setEmoji={emoji => {
              formik.setFieldValue('logo', emoji);
            }}
            setShowPicker={setEmoji}
            showPicker={emojiPicker}
          />
          <FormikTextfield
            formik={formik}
            name={'title'}
            header="Thread Title"
          />
        </div>
        <div className="create-thread__field">
          <MultilineFormikTextfield
            formik={formik}
            name={'content'}
            header="Thread Description"
          />
        </div>
        <div className="create-thread__field">
          <FormikSearchableDropDown
            formik={formik}
            header={'Choose Group to select people'}
            name="group_id"
            placeholder={'Where do you want this thread?'}
            items={mappedGroups}
          />
        </div>
        <div className="create-thread__field">
          <FormikArraySearchableDropDown
            formik={formik}
            header={'Thread Administrators'}
            name={'admins'}
            items={mapped}
          />
        </div>
        <div className="create-thread__field">
          <FormikArraySearchableDropDown
            formik={formik}
            header={'Thread Collaborators'}
            name={'subscribers'}
            items={mapped}
          />
        </div>
        <FormikArraySearchableDropDown
          formik={formik}
          header="Tags"
          name="tags"
          items={tagsJSON}
          mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
        />
        <div className="create-thread__submit-container">
          <button
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateThread;

const TypeToggle = ({toggleType, setToggleType}) => {
  const getDescriptionText = () => {
    switch (toggleType) {
      case 'Secure':
        return 'Encrypted thread for sensitive information. Not discoverable or searchable.';
      case 'Private':
        return 'Invite-only thread. Visible in group-level searches only.';
      case 'Open':
        return 'Public thread. Visible in group searches and open for others to join.';
      default:
        return '';
    }
  };

  const getLabelText = () => {
    switch (toggleType) {
      case 'Secure':
        return 'Secure Thread';
      case 'Private':
        return 'Private Thread';
      case 'Open':
        return 'Public Thread';
      default:
        return '';
    }
  };
  const getToggleClass = () => {
    if (toggleType === 'Secure') return 'secure-active';
    if (toggleType === 'Private') return 'private-active';
    return 'discoverable-active';
  };

  return (
    <div className="ai-toggle">
      <button
        className={`type-toggle-button ${getToggleClass()}`}
        aria-label="Toggle Thread Type">
        <span
          className="toggle-icon secure-icon"
          onClick={() => setToggleType('Secure')}>
          <RxLockClosed />
        </span>
        <span
          className="toggle-icon private-icon"
          onClick={() => setToggleType('Private')}>
          <RxLockOpen1 />
        </span>
        <span
          className="toggle-icon discoverable-icon"
          onClick={() => setToggleType('Open')}>
          <RxLockOpen2 />
        </span>
        <span className="toggle-slider"></span>
      </button>

      <p className="text-bold">{getLabelText()}</p>
      <p className="text-secondary">{getDescriptionText()}</p>
    </div>
  );
};
