import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {useNavigate, useParams} from 'react-router-dom';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import useLoader from '../../hooks/use-loader';
import {useWorkspace} from '../../hooks/use-workspace';
import {OpportunityCard} from './opportunities-components';

const OpportunityDetail = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {loadOpportunities} = useLoader();

  const {
    state: {opportunities},
  } = useWorkspace();

  const [loading, setLoading] = useState(false);

  const opp = opportunities?.[id] ?? {};

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadOpportunities([id]);
      setLoading(false);
    };
    if (id) {
      load();
    }
  }, [id]);

  if (!id || !opp?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <>
            <IoMdArrowBack
              className="clickable"
              onClick={() => {
                if (window.history.length <= 1) {
                  navigate('/home');
                } else {
                  navigate(-1);
                }
              }}
            />
            <p>What you're looking for doesn't exist.</p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="page-container">
      <IoMdArrowBack
        className="clickable"
        onClick={() => {
          if (window.history.length <= 1) {
            navigate('/home');
          } else {
            navigate(-1);
          }
        }}
      />
      <div className="flex justify-center">
        <OpportunityCard
          item={opp}
          onClick={() => {
            // navigate(-1);
          }}
        />
      </div>
    </div>
  );
};

export default OpportunityDetail;
