import {useFormik} from 'formik';
import React, {useState} from 'react';
import {FaBolt} from 'react-icons/fa6';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {
  sendConnectionAccepted,
  sendEmail,
  sendInvitationNudgeEmail,
} from '../../api/email';
import generateMeetingRequest from '../../assets/html/meeting-request';
import meetingConfirmationTemplate from '../../assets/html/meeting-request-confirmation';
import subscriptionConfirmationTemplate from '../../assets/html/subscriptionConfirmation';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useMessage} from '../../hooks/use-message';
import useSearchParameters from '../../hooks/use-search-parameters';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import {useWorkspace} from '../../hooks/use-workspace';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {ErrorButton} from '../buttons/buttons';
import constants from '../constants';
import EmailPreviewModal from '../email/email-preview';
import {PublicMindLogoLightNoText} from '../images/logos';
import {
  FormikTextfield,
  MultilineFormikTextfield,
  MultilineTextfield,
} from '../inputs/textfields';
import {Modal} from './modal';

export const ScheduleModal = ({item, active, setActive, onClick}) => {
  const navigate = useNavigate();
  const {sortOpportunity, membersToId} = useCollaborator();
  const {trackInteraction} = useTracker();

  const {
    state: {
      id,
      profile,
      profiles,
      organizations,
      current_organization,
      current_group,
    },
  } = useAuth();
  const {
    state: {new_opps, opp_token, posts},
    handleOpportunity,
    getNewOpportunities,
    getPost,
  } = useWorkspace();
  const {createChain, sendMessage} = useMessage();

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const org = organizations?.[current_organization] ?? {};
  const {id: opp_id, members, content, data, tags} = item || {};
  const {user_status, other_status, user_content, other_content} =
    sortOpportunity(item);
  const other_person = profiles?.[other_content?.user_id] ?? {};
  const {first_name} = other_person;

  const formik = useFormik({
    initialValues: {
      notes: '',
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {notes} = values;

        // MAP THE NEW DATA
        const mapped_data = data.map(d => {
          const {
            user_id,
            status,
            notes: cur_note,
            updated,
            email_timestamp,
          } = d;
          if (user_id === id) {
            return {
              user_id,
              notes,
              status: 'connect',
              updated: dateToTimestamp(),
              email_timestamp,
            };
          }
          return {user_id, notes: cur_note, status, updated, email_timestamp};
        });
        // UPDATE OPP
        const update = {
          id: opp_id,
          status: 'active',
          data: mapped_data,
        };
        const {success, error} = await handleOpportunity(update);
        if (success) {
          item.status = 'active';
          setPopup({on: true, message: 'Match request sent!'});
          // ON SUCCESS, HANDLE NAV AND FORM AND EXTRAS
          setActive(false);
          helpers.resetForm();
          if (onClick) {
            onClick();
          }

          // IF CONNECTING, SEND EMAIL AND CREATE AN EMAIL CHAIN
          if (other_status?.status === 'connect') {
            // SEND ACCEPTED EMAIL
            const email = other_status.user_id;
            sendConnectionAccepted({email, profile, org, item, notes});
            const id = membersToId(members);
            const chain = {
              id,
              type: 'direct',
              members,
              name: 'Direct',
              last_timestamp: dateToTimestamp(),
              last_message: null,
            };
            const {success, error, data: chain_data} = await createChain(chain);

            // SEND MESSAGES FROM OPP
            if (success || chain_data || error === 'Item already exists.') {
              await Promise.all(
                mapped_data
                  .sort((a, b) => {
                    return b.updated - a.updated;
                  })
                  .map(async status => {
                    const {notes, user_id, updated} = status;
                    const prof = profiles?.[user_id] ?? {};
                    const {organization_ids} = prof;
                    const sender_org = organization_ids?.[0] ?? null;
                    // IF A MESSAGE SEND IT
                    if (notes && notes.length) {
                      const message = {
                        id: v4(),
                        message_chain: id,
                        content: notes,
                        timestamp: updated || dateToTimestamp(),
                        sender_id: user_id,
                        sender_org,
                        read: null,
                        hyperlinks: [],
                      };
                      const {success, error} = await sendMessage(message);
                    }
                  }),
              );
              navigate('/messages', {state: {id}});
            } else {
              navigate('/messages');
            }
          } else {
            // IF INITIATING A CONNECTION
            const email = other_status.user_id;

            // UPDATE OPP DATA FOR EMAIL
            item.data = mapped_data;

            const recipient_html = generateMeetingRequest({
              profile,
              organization: org,
              opportunity: item,
            });
            const recipient_body = {
              to: email,
              subject: 'Connection Request',
              text: 'Connection Request',
              html: recipient_html,
            };
            await sendEmail(recipient_body);
          }

          // IF FEW TAGS LEFT PULL IN MORE
          if (new_opps.length <= 2) {
            const query = {
              filter: {
                status: {eq: 'inactive'},
                members: {contains: id},
                groups: {contains: current_group},
              },
              limit: constants.opportunity_limit,
              nextToken: opp_token,
            };
            await getNewOpportunities(query);
          }
          track('opportunity_interaction', {
            updated_status: 'connect',
            user_id: id,
            opportunity_id: opp_id,
            opportunity: item,
          });
          trackInteraction({
            target_user: other_content.user_id,
            content_id: opp_id,
            content_type: 'opportunity',
            action: 'connect',
          });
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!item) {
    return null;
  }
  return (
    <>
      <Modal active={active} setActive={setActive} disabled_dismiss={false}>
        <div className="flex-column align-center justify-center padding-32">
          <h4>Add a note to {first_name || 'them'}?</h4>
          <p className="text-left text-secondary">
            Add an optional note explaining why you're matching with{' '}
            {first_name || 'them'}
          </p>
          <div style={{width: '100%'}}>
            <MultilineFormikTextfield
              formik={formik}
              header={`Note`}
              name="notes"
            />
          </div>
          {formik.errors.submit && (
            <p className="error">{formik.errors.submit}</p>
          )}
          <div className="flex-row justify-center">
            <button
              className="button-container"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}>
              Match
            </button>
          </div>
          {other_status?.status === 'connect' ? (
            <p className="text-secondary text-12">
              Matching will start a direct message chain with{' '}
              {first_name || 'them'}
            </p>
          ) : (
            <div>
              <p className="text-secondary text-12">
                Matching will send an email to {first_name || 'them'}
              </p>
              <div className="flex align-center justify-center ">
                <EmailPreviewModal
                  emailType={'sendMatch'}
                  emailProps={{
                    profile,
                    organization: org,
                    opportunity: item,
                    previewNotes: formik.values.notes,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export const SubscribeModal = ({profile, active, setActive}) => {
  const {
    state: {id},
  } = useAuth();
  const {trackInteraction} = useTracker();

  const {first_name} = profile || {};
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      email: id || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required.'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email} = values;
        const lowered = email.toLowerCase();

        track('user_subscription', {
          subscriber: lowered,
          target: profile.email,
          source: 'profile',
        });
        trackInteraction({
          target_user: profile.id,
          content_id: profile.id,
          content_type: 'user',
          action: 'subscribe',
        });

        try {
          const sender_html = subscriptionConfirmationTemplate(profile);
          const sender_body = {
            to: email,
            subject: 'Subscription Confirmation',
            text: 'Subscription Confirmation',
            html: sender_html,
          };
          await sendEmail(sender_body);
        } catch (err) {
          console.log(err);
        }

        setPopup({on: true, message: 'Subscription confirmed!'});
        setActive(false);
        helpers.resetForm();
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!profile) {
    return null;
  }

  return (
    <>
      <Modal active={active} setActive={setActive} disabled_dismiss={true}>
        <div className="padding-32">
          <h4>Subscribe to {first_name || 'them'}?</h4>
          <p className="text-left text-secondary">
            You will receive updates on their activity in your email.
          </p>
          <br />
          {!id && (
            <FormikTextfield
              formik={formik}
              header="Add your email"
              name="email"
            />
          )}
          {formik.errors.submit && (
            <p className="error">{formik.errors.submit}</p>
          )}
          <div className="flex-row justify-center">
            <ErrorButton
              onClick={() => {
                setActive(false);
              }}>
              Cancel
            </ErrorButton>
            <button
              className="button-container"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}>
              Subscribe
            </button>
          </div>
        </div>
      </Modal>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

// USED TO MAKE A DIRECT OPP WITH A POST
export const DirectPostOppModal = ({post, active, setActive, setAnalytics}) => {
  const {trackOpportunity, trackInteraction} = useTracker();
  const {capitalize} = useStringFormatter();

  const {
    state: {
      id,
      profile: cur_profile,
      organizations,
      current_organization,
      profiles,
      current_group,
    },
    addWaitlist,
  } = useAuth();
  const {createOpp} = useWorkspace();

  const org = organizations?.[current_organization] ?? {};

  const {id: post_id, user_id, type} = post || {};
  const post_type = capitalize(type ?? 'Project');

  const profile = profiles?.[user_id] ?? null;
  const {first_name} = profile || {};

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      email: id || '',
      notes: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required.'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {notes, email} = values;
        const lowered = email.toLowerCase();

        // IF NO USER ADD THEM TO THE WAITLIST
        if (!id) {
          const waitlist = {
            id: lowered,
            email,
            timestamp: dateToTimestamp(),
            referred_by: null,
          };

          const {success, error} = await addWaitlist(waitlist);

          if (success) {
            track('waitlist_sign_up', {email: lowered, source: 'profile'});
          }
        }

        // CREATE A DIRECT OPP
        const members = [email, user_id];
        const opp = {
          id: v4(),
          members,
          content_ids: [id, post_id],
          status: 'direct',
          timestamp: dateToTimestamp(),
          content: members.map(id => {
            if (id === user_id) {
              return {
                user_id: id,
                content_id: post_id,
                type: 'post',
                inner_tags: [],
                outer_tags: [],
              };
            } else {
              return {
                user_id: id,
                content_id: id,
                type: 'user',
                inner_tags: [],
                outer_tags: [],
              };
            }
          }),
          data: [
            {
              user_id: email,
              notes,
              status: 'connect',
              updated: dateToTimestamp(),
              email_timestamp: null,
            },
            {
              user_id,
              notes: null,
              status: null,
              updated: null,
              email_timestamp: null,
            },
          ],
          tags: [],
          groups: [current_group],
        };

        const {success, error} = await createOpp(opp);
        // IF SUCCESSFUL TRACK
        if (success) {
          track('opportunity_interaction', {
            updated_status: 'connect',
            user_id: id,
            opportunity_id: opp.id,
            opportunity: opp,
            type: 'direct',
          });
          const analytics = await trackOpportunity(post_id);
          setAnalytics(analytics);
          trackInteraction({
            target_user: user_id,
            content_id: opp.id,
            content_type: 'opportunity',
            action: 'connect',
          });
          setPopup({on: true, message: 'Match created!'});
          setActive(false);
          helpers.resetForm();
        }

        if (!success || error) {
          setPopup({on: true, message: 'Something went wrong.'});
          return;
        }

        // SEND EMAILS TO REQUESTER AND RECIPIENT
        try {
          // IF NO AUTH - CONFIRM THROUGH EMAIL
          if (!id) {
            const sender_html = meetingConfirmationTemplate(profile);
            const sender_body = {
              to: email,
              subject: 'Match Request Confirmation',
              text: 'Match Request Confirmation',
              html: sender_html,
            };
            await sendEmail(sender_body);
          }

          const {first_name, last_name} = cur_profile;
          const pretty =
            first_name || last_name
              ? `${first_name || ''} ${last_name || ''}`
              : 'Member';
          // SEND OPP
          const recipient_html = generateMeetingRequest({
            profile: cur_profile || {email},
            organization: org,
            opportunity: opp,
          });
          const recipient_body = {
            to: user_id,
            subject: `Match Request from ${pretty}`,
            text: 'Match Request',
            html: recipient_html,
          };
          await sendEmail(recipient_body);
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!post) {
    return null;
  }

  return (
    <>
      <Modal active={active} setActive={setActive} disabled_dismiss={false}>
        <div className="flex-column align-center padding-bottom16 margin-h16">
          <span className="create-opportunity-item">
            <FaBolt />
          </span>
          <h2 className="invite-modal-title">Create a Match</h2>
          <p className="invite-modal-description">
            Interested in this {post_type}? Create an Match. This will allow the
            other member to accept the match to connect with you.
          </p>
        </div>
        <div className="flex flex-column padding-32">
          {!id && (
            <FormikTextfield
              formik={formik}
              header="Add your email"
              name="email"
            />
          )}
          <MultilineFormikTextfield
            formik={formik}
            header={`Add a note to ${first_name || 'them'} `}
            name="notes"
          />
        </div>
        {formik.errors.submit && (
          <p className="error">{formik.errors.submit}</p>
        )}
        <div className="flex-row justify-center">
          <button
            className="button-container"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Send
          </button>
        </div>
        <EmailPreviewModal
          emailType={'sendMatch'}
          emailProps={{
            profile: cur_profile || {email: formik.values.email},
            organization: org,
            opportunity: null,
            previewNotes: formik.values.notes,
          }}
        />
      </Modal>

      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export const NudgeInvitation = ({active, setActive, referral}) => {
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  return (
    <>
      {active ? (
        <NudgeInvitationContent
          active={active}
          setActive={setActive}
          referral={referral}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const NudgeInvitationContent = ({active, setActive, referral, setPopup}) => {
  const {createReferralLink} = useSearchParameters();
  const {
    state: {id, profile, current_group},
  } = useAuth();

  const {first_name, last_name, email, group_ids} = referral || {};

  const group_id = group_ids?.[0] ?? current_group;

  const pretty =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  const [note, setNote] = useState('');

  const link = createReferralLink(email, group_id);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex justify-center padding-bottom8">
        <PublicMindLogoLightNoText />
      </div>
      <p className="invite-modal-description">
        Add a personal note to {pretty}
      </p>
      <MultilineTextfield
        value={note}
        type="text"
        onChange={e => setNote(e.target.value)}
        header="Personal Note"
      />
      <div className="flex justify-center">
        <button
          disabled={!note}
          onClick={async () => {
            const msg = {email, profile, note, link};
            const {success, error} = await sendInvitationNudgeEmail(msg);
            if (success) {
              setActive(false);
              setNote('');
              setPopup({on: true, message: 'Reminder sent!'});
            }

            if (error) {
              setPopup({on: true, message: 'Something went wrong'});
            }
          }}>
          Send
        </button>
      </div>
      <EmailPreviewModal
        emailType={'followUpNudge'}
        emailProps={{email, profile, note, link}}
      />
    </Modal>
  );
};
