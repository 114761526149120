import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {GoCrossReference} from 'react-icons/go';
import {LuCalendarDays} from 'react-icons/lu';
import {RxEnter, RxLightningBolt, RxPencil1, RxShare2} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {convertToDate, formatEventDate, formatMMDDYYYY} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useTracker from '../../hooks/use-tracker';
import {AskDetails} from '../../pages/posts/post-components';
import {PostOpps} from '../../pages/posts/post-detail';
import {FileViewer, ProfileImage} from '../inputs/uploader';
import {
  SendNoteModal,
  SharePostModal,
} from '../modal/external-engagement-modals';
import {DirectPostOppModal} from '../modal/schedule-modal';
import {SimpleTagDisplay} from '../text/display-fields';
import Tooltip, {TooltipNoShadow} from '../tooltip/tooltip';
import {GroupsIcon} from '../widgets/widgets';
import {Label} from './labels';

export const PostItem = ({item, inProfile = false, isPublic = false}) => {
  const navigate = useNavigate();
  const {loadOrgs, loadProfiles, loadGroups} = useLoader();
  const {getPostAnalytics, trackPage} = useTracker();

  const {
    state: {organizations, profiles, profile, groups},
  } = useAuth();

  const {
    id,
    user_id,
    org_id,
    timestamp,
    title,
    description,
    media,
    type,
    inner_tags,
    outer_tags,
    group_ids,
  } = item || {};

  const created = convertToDate(timestamp * 1000);
  const pretty_created = formatMMDDYYYY(created);
  const pretty_date = formatEventDate(item || null);
  const mobile = isMobile;

  const org = organizations?.[org_id] ?? {};
  const user = profiles?.[user_id] ?? {};

  const profile_image = user?.profile_image;
  const {name} = org;
  const {first_name, last_name, position} = user;
  const editable = user_id === profile?.id;
  const [showFullDescription, setShowFullDescription] = useState(
    description?.length <= 500,
  );
  const [add, setAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [showOpps, setShowOpp] = useState(false);

  const [recSomeone, setRec] = useState(false);
  const [analytics, setAnalytics] = useState({
    views: 0,
    share_count: 0,
    refer_count: 0,
    opportunity_count: 0,
  });

  const handleShowFullDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (id) {
      trackPage(`post_${id}`, 'post');
    }
  }, [id]);

  useEffect(() => {
    if (org_id) {
      loadOrgs([org_id], {authMode: 'apiKey'});
    }
  }, [org_id]);

  useEffect(() => {
    if (user_id) {
      loadProfiles([user_id], {authMode: 'apiKey'});
    }
  }, [user_id]);

  useEffect(() => {
    loadGroups(group_ids);
  }, [group_ids]);

  // useEffect(() => {
  //   const getAnalytics = async () => {
  //     const {views, share_count, refer_count, opportunity_count} =
  //       await getPostAnalytics(id);
  //     setAnalytics({views, share_count, refer_count, opportunity_count});
  //   };
  //   if (id) {
  //     getAnalytics();
  //   }
  // }, [id]);

  return (
    <div className="update-container">
      <div className="flex-column fullwidth">
        <div className="flex-row justify-between">
          <div className="flex-column">
            <div className="flex-row">
              <Label
                label={
                  type === 'request'
                    ? 'Request'
                    : type === 'resource'
                    ? 'Resource'
                    : 'Project'
                }
              />
              <span className="margin-h4" />
              <GroupsIcon
                group_ids={group_ids}
                isPublic={isPublic}
                inProfile={inProfile}
              />
            </div>
            {inProfile && (
              <p className="text-secondary text-14">{pretty_created}</p>
            )}
          </div>
          {!mobile && !isPublic && (
            <QuickButtons
              editable={editable}
              // analytics={analytics}
              onRecommend={() => {
                setRec(!recSomeone);
              }}
              onConnect={() => {
                setShow(!show);
              }}
              onAdd={() => {
                setAdd(!add);
              }}
              onEdit={() => {
                navigate(`/feed/posts/detail/${id}`);
              }}
              onViewOpps={() => {
                setShowOpp(!showOpps);
              }}
              onViewPost={() => {
                navigate(`/feed/posts/detail/${id}`);
              }}
            />
          )}
        </div>
        {!inProfile && (
          <div className="flex-row justify-between padding-top8">
            <div
              className={`flex-row align-center padding-bottom16 margin-top-${
                mobile ? '64' : '32'
              }`}>
              <TooltipNoShadow text={'See Profile'}>
                <ProfileImage
                  data={profile_image}
                  style={{height: '60px', width: '60px'}}
                  onClick={() => {
                    navigate(`/feed/profiles/detail/${user_id}`);
                  }}
                />
              </TooltipNoShadow>
              <span className="margin-8" />
              <div className="flex-column">
                <p className="text-left text-18">
                  {first_name} {last_name}
                </p>
                <p
                  className="text-left text-14 text-secondary"
                  onClick={e => {
                    e.stopPropagation();
                    navigate(`/feed/organizations/detail/${org_id}`);
                  }}>
                  {position}
                  {name ? `, ${name}` : null}
                </p>
                <p className="text-left text-secondary text-14">
                  {pretty_created}
                </p>
              </div>
            </div>
          </div>
        )}
        <h4>{title}</h4>
        <p
          className={`text-left text-formatting-preserved padding-top8 ${
            description?.length > 500 ? ' clickable-text' : ''
          }`}
          onClick={handleShowFullDescription}>
          {showFullDescription || description?.lenght <= 500
            ? description
            : `${description?.substring(0, 500)}...`}
        </p>
        {pretty_date && (
          <div className="flex-row align-center">
            <LuCalendarDays size={'16px'} />
            <p className="margin-h8 text-14 text-left">{pretty_date}</p>
          </div>
        )}
        {!!media?.length && (
          <div className="card">
            {media.map(file => {
              const {type, key, url} = file;
              return <FileViewer key={key} type={type} url={url} />;
            })}
          </div>
        )}
        {item && <AskDetails item={item} />}
        <div className="padding-top8" />
        <SimpleTagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
        {mobile && (
          <QuickButtons
            editable={editable}
            // analytics={analytics}
            onRecommend={() => {
              setRec(!recSomeone);
            }}
            onConnect={() => {
              setShow(!show);
            }}
            onAdd={() => {
              setAdd(!add);
            }}
            onEdit={() => {
              navigate(`/feed/posts/detail/${id}`);
            }}
            onViewOpps={() => {
              setShowOpp(!showOpps);
            }}
            onViewPost={() => {
              navigate(`/feed/posts/detail/${id}`);
            }}
          />
        )}
      </div>
      {!editable && (
        <SendNoteModal active={recSomeone} setActive={setRec} post={item} />
      )}
      <SharePostModal active={add} setActive={setAdd} post={item} />
      <DirectPostOppModal
        post={item}
        active={show}
        setActive={setShow}
        setAnalytics={setAnalytics}
      />
      {showOpps && (
        <PostOpps active={showOpps} setActive={setShowOpp} id={id} />
      )}
    </div>
  );
};

const QuickButtons = ({
  editable,
  analytics,
  onRecommend,
  onConnect,
  onAdd,
  onEdit,
  onViewOpps,
  onViewPost,
}) => {
  const mobile = isMobile || isTablet;

  return (
    <div className="flex-row justify-end align-start">
      {!editable && (
        <span className="padding-right4">
          <Tooltip text={'Send Note'}>
            <span className="flex-column align-center">
              <GoCrossReference
                size={'18px'}
                onClick={onRecommend}
                className="clickable"
              />
              <p className="text-secondary text-8">{'Note'}</p>
            </span>
          </Tooltip>
        </span>
      )}
      {!editable && (
        <span className="padding-right4">
          <Tooltip text={'Match'}>
            <span className="flex-column align-center">
              <RxLightningBolt
                size={'18px'}
                onClick={onConnect}
                className="clickable"
              />
              <p className="text-secondary text-8">{'Match'}</p>
            </span>
          </Tooltip>
        </span>
      )}
      {editable && !mobile && (
        <span className="padding-right4">
          <Tooltip text={'See Matches'}>
            <span className="flex-column align-center">
              <RxLightningBolt
                size={'18px'}
                onClick={onViewOpps}
                className="clickable"
              />
              <p className="text-secondary text-8">{'Match'}</p>
            </span>
          </Tooltip>
        </span>
      )}
      <span className="padding-right4">
        <Tooltip text={'Share Post'}>
          <span className="flex-column align-center">
            <RxShare2 size={'18px'} onClick={onAdd} className="clickable" />
            <p className="text-secondary text-8">{'Share'}</p>
          </span>
        </Tooltip>
      </span>
      {editable && (
        <span>
          <Tooltip text={'Edit Post'}>
            <span className="flex-column align-center">
              <RxPencil1 size={'18px'} onClick={onEdit} className="clickable" />
              <p className="text-secondary text-8">{'Edit'}</p>
            </span>
          </Tooltip>
        </span>
      )}
      {!editable && (
        <span>
          <Tooltip text={'See Details'}>
            <span className="flex-column align-center">
              <RxEnter
                onClick={onViewPost}
                size={'18px'}
                className="clickable"
              />
              <p className="text-secondary text-8">{'Details'}</p>
            </span>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export const SimplePostItem = ({item, inProfile = false}) => {
  const navigate = useNavigate();
  const {loadOrgs, loadProfiles} = useLoader();
  const {getPostAnalytics, trackPage} = useTracker();

  const {
    state: {organizations, profiles, profile},
  } = useAuth();

  const {
    id,
    user_id,
    org_id,
    timestamp,
    title,
    description,
    media,
    type,
    inner_tags,
    outer_tags,
  } = item || {};

  const created = convertToDate(timestamp * 1000);
  const pretty_created = formatMMDDYYYY(created);

  const [showFullDescription, setShowFullDescription] = useState(
    description?.length <= 500,
  );
  const [add, setAdd] = useState(false);

  useEffect(() => {
    trackPage(`post_${id}`, 'post');
  }, []);

  useEffect(() => {
    if (org_id) {
      loadOrgs([org_id], {authMode: 'apiKey'});
    }
  }, [org_id]);

  useEffect(() => {
    if (user_id) {
      loadProfiles([user_id], {authMode: 'apiKey'});
    }
  }, [user_id]);

  return (
    <div
      className="bare-border clickable"
      onClick={() => {
        navigate(`/feed/posts/detail/${id}`);
      }}>
      <div className="flex-column fullwidth">
        <p className="text-left text-secondary text-12">
          {type === 'request'
            ? 'Request'
            : type === 'resource'
            ? 'Resource'
            : 'Project'}
        </p>

        <p className="text-left text-secondary text-14">{pretty_created}</p>

        <h4>{title}</h4>
        <p className="text-secondary text-left text-12">
          {showFullDescription ? description : description?.slice(0, 500)}
        </p>
      </div>
      <SharePostModal active={add} setActive={setAdd} post={item} />
    </div>
  );
};
