import React, {useEffect, useRef, useState} from 'react';
import {CiCircleMore} from 'react-icons/ci';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useMessage} from '../../hooks/use-message';
import constants from '../constants';
import {MessageItem} from '../item-details/message-item';
import {SpinningIndicator} from '../loading/loading-indicator';
import './styles.css';

export const Messenger = ({chain_id, profile_id, limit, header}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {message_ids, chains, subscriptions},
    getChain,
    getMessages,
    attachMessageListener,
  } = useMessage();

  const [loading, setLoading] = useState(false);
  const chain = chains && chains[chain_id] ? chains[chain_id] : null;

  // FETCH CHAIN IF EXISTS
  useEffect(() => {
    if (!chain_id) {
      return;
    }
    // PULL FROM DB
    const fetchChain = async () => {
      const {success, error, data} = await getChain(chain_id);
    };
    if (!chain) {
      fetchChain();
    }
  }, [chain_id]);

  // FETCH MESSAGES - ON CHAIN CHANGE
  useEffect(() => {
    const fetchMessages = async () => {
      const query = {
        message_chain: chain_id,
        sortDirection: 'DESC',
        limit: limit || constants.message_limit,
      };
      setLoading(true);
      const {success, error} = await getMessages(query);
      setLoading(false);
    };
    // FETCH INITIAL MESSAGES
    if (chain_id && chain && message_ids[chain_id] === undefined) {
      fetchMessages();
    }
    // ATTACH SUBSCRIBER TO CHAIN
    if (chain_id && chain && subscriptions[chain_id] === undefined) {
      attachMessageListener({filter: {message_chain: {eq: chain_id}}}, id);
    }
  }, [chain_id, chain]);

  // IF NOTHING RETURN BARE COMPONENT
  if (!chain && !profile_id) {
    return <Messages />;
  }

  // NO CHAIN EXISTS
  if (!chain && profile_id) {
    return null;
    // return (
    // <div className="flex-row justify-between align-center">
    //   <h4>Send a Message</h4>
    //   <IconButton
    //     active={true}
    //     onClick={async () => {
    //       const members = [id, profile_id];
    //       const id = membersToId(members);
    //       const chain = {
    //         id,
    //         type: 'direct',
    //         members,
    //         name: 'Direct',
    //         last_timestamp: dateToTimestamp(),
    //         last_message: null,
    //       };
    //       const {success, error, data} = await createChain(chain);
    //     }}>
    //     <AiOutlinePlus size={'24px'} />
    //   </IconButton>
    // </div>
    // );
  }

  return (
    <Messages
      chain_id={chain_id}
      limit={limit}
      initial_load={loading}
      header={header}
    />
  );
};

const Messages = ({chain_id, limit, initial_load, header}) => {
  const navigate = useNavigate();
  const {
    state: {id, profile, current_organization, organizations},
  } = useAuth();
  const {
    state: {message_ids, messages, chains, paginations},
    getMessages,
    sendMessage,
    updateMessage,
  } = useMessage();
  const {membersToOther} = useCollaborator();

  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const chain = chains && chains[chain_id] ? chains[chain_id] : null;
  const paginate_token = paginations[chain_id];
  const {other} = membersToOther(chain);

  const [content, setContent] = useState('');
  const [hyperlinks, setHyper] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const message_chain = message_ids[chain_id];

  // SCROLL TO BOTTOM ON NEW MESSAGES OR NEW CHAIN
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [message_ids, chain_id]);

  // HANDLES PAGINATION
  const handleScroll = async () => {
    const {scrollTop, scrollHeight, clientHeight} =
      messagesContainerRef.current;
    const difference = scrollHeight + scrollTop;
    if (difference >= clientHeight - 40 && paginate_token && !loading) {
      setLoading(true);
      const query = {
        message_chain: chain_id,
        sortDirection: 'DESC',
        limit: limit || constants.message_limit,
        nextToken: paginate_token,
      };
      const {success, error} = await getMessages(query);

      setLoading(false);
    }
  };

  // ATTACH LISTENER
  useEffect(() => {
    if (!messagesContainerRef.current) {
      return;
    }
    const element = messagesContainerRef.current;
    element.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [messagesContainerRef.current, loading, paginate_token]); // Re-attach event listener if isLoading changes

  useEffect(() => {
    const markSeen = async () => {
      message_chain.forEach(m_id => {
        const message = messages[m_id];
        const {read, sender_id} = message;
        if (!read && sender_id !== id) {
          updateMessage({id: m_id, read: dateToTimestamp()});
        }
      });
    };
    if (message_chain) {
      markSeen();
    }
  }, [message_chain]);

  // SEND MESSAGE
  const send = async () => {
    if (!content && !hyperlinks.length) {
      return;
    }
    setSending(true);
    const message = {
      id: v4(),
      message_chain: chain_id,
      content,
      timestamp: dateToTimestamp(),
      sender_id: id,
      sender_org: current_organization,
      read: null,
      hyperlinks,
    };
    let last_time_message = chains[chain_id]?.last_timestamp;

    const {success, error} = await sendMessage(
      message,
      profile,
      other,
      last_time_message,
    );
    if (success) {
      setContent('');
      setHyper([]);
      track('message_sent', {message});
    }
    setSending(false);
  };

  return (
    <>
      <div
        className="flex flex-column message-scrollview"
        ref={messagesContainerRef}>
        <div ref={messagesEndRef} />
        {initial_load && <SpinningIndicator />}
        {message_chain && message_chain.length ? (
          message_chain.map(id => {
            return <MessageItem key={id} item={messages[id]} />;
          })
        ) : (
          <p className="text-secondary text-12">No messages</p>
        )}
        {loading && <SpinningIndicator />}
        <br />
      </div>
      <div className="message-input">
        {/* <SendButton chain_id={chain_id} /> */}
        <DynamicTextfieldMessages
          disabled={sending}
          placeholder="Write a message..."
          value={content}
          onChange={e => setContent(e.target.value)}
          onEnter={() => {
            if (!sending) send();
          }}
        />
      </div>
    </>
  );
};

const SendButton = ({chain_id}) => {
  const {membersToOther} = useCollaborator();

  const {
    state: {id, profile, current_organization},
  } = useAuth();
  const {
    state: {chains},
    sendMessage,
  } = useMessage();

  const chain = chains && chains[chain_id] ? chains[chain_id] : null;
  const {other} = membersToOther(chain);

  const {social_links, sub} = profile || {};

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowButtons(false);
    }, 2000);
  };

  // SEND MESSAGE
  const send = async url => {
    if (!url || !chain_id) {
      return;
    }

    const message = {
      id: v4(),
      message_chain: chain_id,
      content: url,
      timestamp: dateToTimestamp(),
      sender_id: id,
      sender_org: current_organization,
      read: null,
      hyperlinks: [],
    };
    const {success, error} = await sendMessage(message, profile, other);
    if (success) {
      track('message_sent', {message, link: true});
    }
  };

  return (
    <span>
      {showButtons && (
        <div className="hover-buttons">
          <button
            className="send-button-publiclink"
            onClick={async () => {
              const public_link = constants.public_profile + `/${sub}`;
              await send(public_link);
              setShowButtons(false);
            }}>
            PublicLink
          </button>
          {!!social_links?.length &&
            social_links.map(link => {
              const {text, url, type} = link;
              return (
                <button
                  key={url}
                  className="send-button-publiclink"
                  onClick={async () => {
                    await send(url);
                    setShowButtons(false);
                  }}>
                  {text}
                </button>
              );
            })}
        </div>
      )}
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="send-button">
        <CiCircleMore size={'25px'} />
      </button>
    </span>
  );
};
const DynamicTextfieldMessages = props => {
  const {header, onEnter, type = 'text', ...rest} = props;
  const textAreaRef = useRef(null);
  const maxRows = 5; // maximum number of rows
  const lineHeight = 21; // height of each row in pixels

  const resizeTextArea = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto'; // temporarily shrink textarea to fit new content
    textArea.style.height = `${Math.min(
      textArea.scrollHeight,
      maxRows * lineHeight,
    )}px`; // resize the textarea
  };

  useEffect(() => {
    resizeTextArea(); // initial resize
  }, []);

  return (
    <div className="dynamic-textfield">
      {header && <label>{header}</label>}
      <textarea
        {...rest}
        ref={textAreaRef}
        type={type}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (onEnter) {
              onEnter();
            }
          }
        }}
        onInput={resizeTextArea} // resize whenever the text changes
      />
    </div>
  );
};
