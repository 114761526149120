/* eslint-disable import/no-anonymous-default-export */
import {createSearchParams, useSearchParams} from 'react-router-dom';
import constants from '../components/constants';
import {useAuth} from './use-auth';
import useCrypto from './use-crypto';

export default () => {
  const {
    state: {id, current_organization, current_group, groups},
  } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {encryptString, decryptString} = useCrypto();

  const paramsToData = () => {
    try {
      const parsed = {};

      searchParams.forEach((value, key) => {
        const decrypted = decryptString(value);
        parsed[key] = decrypted;
      });
      return parsed;
    } catch (err) {
      return null;
    }
  };

  const dataToParams = data => {
    if (!data) {
      return '';
    }
    const params = {};
    Object.keys(data).forEach(key => {
      const value = data[key];
      const encrypted = encryptString(value);
      params[key] = encrypted;
    });

    return createSearchParams(params).toString();
  };

  const createReferralLink = (email, group_id) => {
    const group = groups?.[group_id || current_group] || {};
    const {type = 'private', admins = []} = group;
    const invitable = type === 'public' ? true : admins.includes(id);

    // IF INVITABLE SEND TO SIGNUP
    if (invitable) {
      const data = {
        status: 'active',
        referral_user: id,
      };
      if (email) {
        data.id = email;
      }
      if (current_group) {
        data.referral_group = current_group;
      }
      if (current_organization) {
        data.referral_org = current_organization;
      }

      const hash = dataToParams(data);
      const link = `${constants.signin_url}?${hash}`;
      return link;
    } else {
      // IF NOT INVITABLE SEND TO WAITLIST
      const data = {
        referral_user: id,
      };
      const hash = dataToParams(data);
      const link = `${constants.signin_url}?${hash}`;
      return link;
    }
  };

  return {
    paramsToData,
    dataToParams,
    createReferralLink,
  };
};
