import {useFormik} from 'formik';
import React from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {SecondaryButton} from '../../components/buttons/buttons';
import {TagInput} from '../../components/inputs/tag-picker';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useWorkspace} from '../../hooks/use-workspace';

const ProfileTagResources = ({}) => {
  const navigate = useNavigate();
  const {compareUpdatedTags} = useCollaborator();
  const {manageOpportunities} = useWorkspace();

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();
  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      opportunity:
        profile?.inner_tags?.filter(tag => tag.includes('opp_')) ?? [],
      collaborator:
        profile?.inner_tags?.filter(tag => tag.includes('collab_')) ?? [],
      skill: profile?.inner_tags?.filter(tag => tag.includes('skill_')) ?? [],
      degree: profile?.inner_tags?.filter(tag => tag.includes('deg_')) ?? [],
      major: profile?.inner_tags?.filter(tag => tag.includes('maj_')) ?? [],
      service: profile?.inner_tags?.filter(tag => tag.includes('serv_')) ?? [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        const {opportunity, collaborator, skill, degree, major, service} =
          values;

        const inner_tags = [
          ...opportunity,
          ...collaborator,
          ...skill,
          ...degree,
          ...major,
          ...service,
        ];

        const status = 'complete';

        const {outer_tags, group_ids} = profile;

        const update = {id, status, inner_tags};
        const {success, error} = await updateProfile(update);

        if (success) {
          navigate('/profile-tag-requests');

          // const {inner_added, inner_removed, outer_added, outer_removed} =
          //   compareUpdatedTags(inner_tags, outer_tags);
          // const body = {
          //   id: id,
          //   type: 'user',
          //   inner_added,
          //   inner_removed,
          //   outer_added,
          //   outer_removed,
          //   group_ids: group_ids || [],
          // };
          // manageOpportunities(body, id);
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="What are you good at?"
          subheader={
            mobile
              ? ''
              : 'Resource tags help us match you with relevant requests based on the resources you have. Please add the appropriate tags to your profile here.'
          }
          isMobile={mobile}
        />
        <div className="flex justify-center">
          <div className={mobile ? 'card' : 'card half-width'}>
            <p className="text-secondary">
              Instructions: Choose the categories below and add tags that best
              describe the resources you offer. Select any Services or
              Opportunities you have and fill out skills or academic majors you
              possess.
            </p>
            <TagInput formik={formik} />
            <p className="text-secondary">
              You can add or remove tags later in your profile settings.
            </p>
            <div className="flex-row justify-between">
              <SecondaryButton
                type="button"
                onClick={() => {
                  navigate(-1);
                }}>
                BACK
              </SecondaryButton>
              {formik.isSubmitting && <SpinningIndicator />}
              <button
                type="button"
                className="button-container"
                onClick={() => {
                  formik.handleSubmit();
                }}>
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTagResources;
