import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {RxCheckCircled} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {TooltipRight} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import './ThreadJoinRequest.css';

const ThreadJoinRequest = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {
    state: {threads},
    getThread,
  } = useThreads();

  const {
    state: {id: user_id},
  } = useAuth();
  const thread = threads?.[id];
  const {title, content, security_level, logo} = thread || {};
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getThread(id);
      setLoading(false);
    };
    if (id && !thread) {
      load();
    }
  }, [id, thread, getThread]);

  const formik = useFormik({
    initialValues: {
      email: user_id,
      reason: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      reason: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .required('Reason is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // Placeholder for join request logic
        console.log('Join request submitted:', values);

        // Simulating a successful request
        setPopup({on: true, message: 'Join request submitted successfully!'});
        setSubmitted(true);
        helpers.resetForm();

        // TODO: Implement actual join request logic here
      } catch (err) {
        helpers.setErrors({submit: err.message});
      }
    },
  });

  if (loading) {
    return (
      <div className="thread-join-container loading-indicator">
        <SpinningIndicator />
      </div>
    );
  }

  if (!id || !thread) {
    return (
      <div className="thread-join-container">
        <p className="error-message">
          The thread you're looking for doesn't exist.
        </p>
      </div>
    );
  }

  const getSecurityTitle = () => {
    switch (security_level) {
      case 'secure':
        return (
          <TooltipRight text={'Maximum Security Thread'}>
            <span className="security-icon secure">Secure</span>
          </TooltipRight>
        );
      case 'private':
        return (
          <TooltipRight text={'Invite-only Security Thread'}>
            <span className="security-icon private">Private</span>{' '}
          </TooltipRight>
        );
      case 'open':
      default:
        return (
          <TooltipRight text={'Public Thread'}>
            <span className="security-icon open">Public</span>{' '}
          </TooltipRight>
        );
    }
  };

  return (
    <div className="thread-join-container">
      <div className="thread-join-header">
        {getSecurityTitle()}
        <h1 className="thread-join-title">Request to Join Thread</h1>
        <p className="thread-join-subtitle">
          Submit a request to join this thread and participate in the
          conversation
        </p>
      </div>
      <div className="thread-details">
        <h2 className="thread-details-title">
          {logo} {title}
        </h2>
        <p className="thread-details-content">{content}</p>
      </div>
      <form onSubmit={formik.handleSubmit} className="join-form">
        {submitted ? (
          <div className="flex-column align-center">
            <RxCheckCircled size={'25px'} color="green" />
            <p className="text-center">
              Submitted. You will recieve an email if the thread owner admits
              you.
            </p>
          </div>
        ) : (
          <>
            <div className="join-form-group ">
              <label htmlFor="reason" className="join-form-label">
                Reason for Request
              </label>
              <textarea
                id="reason"
                name="reason"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reason}
                className="join-form-textarea"
                placeholder="Briefly explain why you'd like to join this thread"
              />
              {formik.touched.reason && formik.errors.reason && (
                <div className="join-form-error">{formik.errors.reason}</div>
              )}
            </div>
            {formik.errors.submit && (
              <div className="join-form-error">{formik.errors.submit}</div>
            )}
            <button
              type="submit"
              className="join-submit-button"
              disabled={formik.isSubmitting}>
              {formik.isSubmitting
                ? 'Submitting Request...'
                : 'Submit Join Request'}
            </button>
          </>
        )}
      </form>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

export default ThreadJoinRequest;
