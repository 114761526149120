import React from 'react';

export const DateInput = props => {
  const {header, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type={'date'} />
    </div>
  );
};

export const TimeInput = props => {
  const {header, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type={'time'} />
    </div>
  );
};

export const FormikDateInput = props => {
  const {formik, name, header, autoComplete, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type="date" {...formik.getFieldProps(name)} />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const FormikTimeInput = props => {
  const {formik, name, header, autoComplete, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type="time" {...formik.getFieldProps(name)} />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
