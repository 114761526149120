import constants from '../../components/constants';

const generateSendNoteEmail = props => {
  const {profile, post, note, previewNotes} = props || {};
  const {email, first_name, last_name, id} = profile || {};
  const post_id = post?.id;
  const post_link = `${constants.post_url}/${post_id}`;
  const profile_link = `${constants.profile_url}/${id}`;

  const pretty_name =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <div style="text-align: center;">
          <img src="${
            constants.publicmind_logo
          }" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 8px 20%;">
         

                    <h1 style="text-align: left; font-size: 38px; color: black;"> Note from <a href="${profile_link}">${
    pretty_name || `someone`
  }</a> on your ${post?.type[0].toUpperCase() + post?.type.slice(1)}</h1>

  <p style="white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 16px; text-align: center;">${
    previewNotes ? previewNotes : note || '<i>No note</i>'
  }</p>

          <br />
          <div style="text-align: left; border: 1px solid #ccc; padding: 16px; border-radius: 16px; margin: 8px 0px; background-color: #f4f4f4;">
            <p style="font-size: 18px;"><strong>${
              post?.type[0].toUpperCase() + post?.type.slice(1)
            } Details</strong></p>
            <p style="color: grey;"><strong>Title:</strong></p>
            <p style="font-size: 18px;">${post?.title}</p>
            <p style="color: grey;"><strong>Description:</strong></p>
            <p style="font-size: 14px;">${post?.description.slice(
              0,
              200,
            )}...</p>
          </div>
          <br/>
          <div style="text-align: center;" >
            <a href="${profile_link}" style="background-color: #6e8efb; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;" >
              View Profile
            </a>
          </div>
          <br/>
          <p style="text-align: center;">Sent from PublicMind</p>
        </div>
      </body>
    </html>
  `;
};

export default generateSendNoteEmail;
