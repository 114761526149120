import constants from '../components/constants';

const mergeArrays = (array1, array2) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Merged array without duplicates
  const merged = [...new Set([...array1, ...array2])];

  // Array of duplicates
  const duplicates = array1.filter(item => set2.has(item));

  // Array of unique items
  const unique = [
    ...array1.filter(item => !set2.has(item)),
    ...array2.filter(item => !set1.has(item)),
  ];

  return {merged, duplicates, unique};
};
// Utility function to check if the browser supports notifications
const checkNotificationSupport = () => {
  return 'Notification' in window; // && 'serviceWorker' in navigator;
};
// Function to request notification permission
const requestNotificationPermission = async () => {
  if (!checkNotificationSupport()) return false;

  const permission = await Notification.requestPermission();
  return permission === 'granted';
};

// Function to register service worker
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/service-worker.js',
      );
      console.log(
        'ServiceWorker registration successful with scope: ',
        registration.scope,
      );
      return registration;
    } catch (err) {
      console.log('ServiceWorker registration failed: ', err);
    }
  }
};

// Main notification function
const sendNotification = async ({title, content, thread_id, id}) => {
  if (!checkNotificationSupport()) {
    console.log('Notifications not supported in this browser');
    return;
  }

  const permission = await checkPermissionStatus();

  if (!permission) {
    console.log('Notification permission not granted');
    return;
  }

  // const registration = await registerServiceWorker();
  // if (!registration) {
  //   console.log('Service worker registration failed');
  //   return;
  // }

  const options = {
    body: content,
    icon: '/favicon.ico',
    badge: '/favicon.ico',
    sound: '/Glass.wav',
    data: {
      url: `${constants.root_url}/threads/${thread_id}/${id}`,
    },
  };

  try {
    // await registration.showNotification(title, options);
    console.log('Notification sent successfully');
  } catch (error) {
    console.error('Error sending notification:', error);
  }
};
const checkPermissionStatus = () => {
  if ('Notification' in window) {
    // Check current permission status without requesting
    const currentPermission = Notification.permission;
    return currentPermission;
  }
  return null;
};

const sanitizeMarkdown = markdownText => {
  if (typeof markdownText !== 'string') {
    return '';
  }

  return (
    markdownText
      // Remove headers
      .replace(/#{1,6}\s?/g, '')
      // Remove emphasis (bold, italic)
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove links
      .replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$1')
      // Remove code blocks and inline code
      .replace(/`{3}[\s\S]*?`{3}/g, '')
      .replace(/`(.+?)`/g, '$1')
      // Remove blockquotes
      .replace(/^\s*>\s*/gm, '')
      // Remove horizontal rules
      .replace(/^(-{3,}|\*{3,})$/gm, '')
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, '$1')
      // Remove images
      .replace(/!\[([^\]]+)\]\(([^\)]+)\)/g, '$1')
      // Remove HTML tags (basic)
      .replace(/<[^>]*>/g, '')
      // Remove @s
      .replace(/@\[([^\]]+)\]\([^)]+\)/g, '$1')
      // Trim whitespace
      .trim()
  );
};

export {
  checkPermissionStatus,
  mergeArrays,
  requestNotificationPermission,
  sanitizeMarkdown,
  sendNotification,
};
