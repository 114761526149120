import {post} from 'aws-amplify/api';
import {track} from './analytics';

const apiName = 'externalRestAPI';

const createOpportunities = async body => {
  try {
    const path = '/genOpportunities';
    const options = {
      headers: {}, // Optional
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body,
    };

    const postOpp = post({apiName, path, options});
    const response = await postOpp.response;

    const {body: data, headers, statusCode} = response;

    const json = await data.json();

    return json;
  } catch (err) {
    track('api_error', {type: 'lambda', function: 'createOpportunities', err});
    throw err;
  }
};

export {createOpportunities};
