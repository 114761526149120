import {useFormik} from 'formik';
import React, {useRef, useState} from 'react';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import {sendPriorityThreadContent} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';
import ChatActionButtons from './chat-action-buttons';
import ChatInput from './chat-input';
import './CreateComment.css';
const CreateComment = ({id}) => {
  const inputRef = useRef(null);
  const {copyString} = useUtilities();

  const {
    state: {id: user_id, profile},
  } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);

  const {
    state: {threads, thread_subs},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      media: [],
      mentions: [],
      parent: null,
      scheduled: null,
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {status, scheduled, mentions, media} = values;
        const now = dateToTimestamp();
        const content = {
          id: v4(),
          thread_id: id,
          ...values,
          created: now,
          updated: now,
        };

        if (files?.length) {
          content.media = files;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          //   console.log(response);
          // }
          helpers.resetForm();
          setFiles([]);
          setIsExpanded(false);
          setShowFileUploader(false);
          if (mentions?.length) {
            await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              threadTitle: threads?.[id].title,
            });
          }
          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          // Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="thread-reply-container">
      <ChatInput
        thread_id={id}
        formik={formik}
        inputRef={inputRef}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showFileUploader={showFileUploader}
        files={files}
        setFiles={setFiles}
      />

      <ChatActionButtons
        formik={formik}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
      />
    </div>
  );
};

export default CreateComment;
