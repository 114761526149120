import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import {dateToTimestamp} from '../../api/dates';
import constants from '../../components/constants';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {InviteGroupModal} from '../../components/modal/invite-group-modal';
import {PaymentMeetingModal} from '../../components/modal/payment-meeting-modal';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';

const ConfirmSubscription = ({}) => {
  const navigate = useNavigate();
  const {capitalize} = useStringFormatter();

  const {
    state: {id, referrals, current_group},
    getReferrals,
    updateProfile,
  } = useAuth();

  const [add, setAdd] = useState(false);
  const [meet, setMeet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(2);

  const mobile = isMobile || isTablet;

  const dev = process.env.REACT_APP_ENV !== 'prod';

  const sorted = [...referrals].sort((a, b) => {
    return b?.timestamp - a?.timestamp;
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (!referrals.length) {
        await getReferrals({
          filter: {referral_user: {eq: id}},
          limit: constants.all_items,
        });
      }
      setLoading(false);
    };
    load();
  }, []);

  const pathPayChosen = () => {
    setPath(1);
  };
  const pathVouchChosen = () => {
    setPath(2);
  };

  return (
    <>
      <div className={'container'}>
        <br />

        <AuthHeader
          header={
            path === 1
              ? 'Schedule a Meeting to Finalize Your Membership'
              : path !== 2
              ? 'Choose Your Membership Level'
              : 'Invite 3 New Members to PublicMind'
          }
          subheader={
            mobile
              ? ''
              : 'As PublicMind grows, we offer you two options to enhance your experience. Decide whether you want to support the network financially or contribute by inviting new members.'
          }
        />
        <div className="flex justify-center">
          <div className={mobile ? 'mobile-form' : 'grid-8'}>
            {dev && (
              <div className="card">
                <h3>👋 Hi Beta Members</h3>
                <p>
                  Continue to the product for free or choose Option 2{' '}
                  <span style={{color: '#ff416c'}}>Growth</span> below
                </p>
                <button
                  className="button-container"
                  onClick={async () => {
                    await updateProfile({
                      id,
                      subscription: '3 month',
                      expiration: dateToTimestamp() + constants.days_30,
                    });
                    navigate('/home');
                  }}>
                  CONTINUE
                </button>
              </div>
            )}

            {path === 2 ? (
              <div className="card">
                <h4 className="text-bold">
                  <span style={{color: '#ff416c'}}>{referrals?.length}</span> of
                  3 Invites Sent
                </h4>
                <span className="border-bottom padding-top8" />

                <p className="text-left text-secondary padding-bottom16">
                  {referrals?.length >= 3
                    ? 'You are welcome to vouch for more people or go ahead and press Continue below'
                    : `Vouch for ${
                        3 - referrals?.length
                      } more people to get a month of no-cost access`}
                </p>

                {loading && <SpinningIndicator />}

                {sorted.map(referral => {
                  const {id, timestamp, status} = referral;

                  return (
                    <div
                      className="flex-row justify-between align-center"
                      key={id}>
                      <p className="text-bold">{id}</p>
                      <p>{capitalize(status.replace('_', ' '))}</p>
                    </div>
                  );
                })}
                <br />

                <div className="flex-row justify-between">
                  <button
                    type="button"
                    className="invite-external-button"
                    onClick={() => {
                      setAdd(!add);
                    }}>
                    INVITE
                  </button>

                  <div className="flex-row">
                    <button
                      disabled={referrals.length < 3}
                      type="button"
                      className="button-container"
                      onClick={async () => {
                        await updateProfile({
                          id,
                          subscription: 'monthly_vouches',
                          expiration: dateToTimestamp() + constants.days_30,
                        });
                        navigate('/home');
                      }}>
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {path === 2 ? (
              <div className="card">
                <p className="text-secondary text-left">
                  {path === 2 ? 'Current Selection' : 'Option 2'}
                </p>
                <h4>
                  <span style={{color: '#ff416c'}}>
                    Growth (Free Membership)
                  </span>
                </h4>

                <p className="text-secondary text-left">
                  Help expand the community by vouching for new members.
                  Contribute to PublicMind’s development by inviting vetted,
                  trusted members.
                </p>
                <span className="border-bottom padding-top8" />
                <p className="text-left">
                  Our Growth plan allows you to access PublicMind at no-cost for
                  1 month. In return, we ask that you vouch for at least 3 new
                  members to join. Examples include a prior outstanding boss, a
                  colleague who really moves the needle, or the most technical
                  person you know.
                </p>
              </div>
            ) : null}
            <div className="card">
              <p className="text-secondary text-left">
                {path === 1 ? 'Current Selection' : 'Option 1'}
              </p>
              <h4>
                <span style={{color: '#a777e3'}}>Paid Membership</span>
              </h4>

              <p className="text-secondary text-left">
                Support the growth and maintenance of PublicMind with a paid
                membership. Enjoy early access to PublicMind Single Player, priority support, and
                multiple membership plans tailored to your needs.
              </p>

              {path === 0 || path === 2 ? (
                <button className="button-container" onClick={pathPayChosen}>
                  Explore
                </button>
              ) : null}
              {path === 1 ? (
                <>
                  <span className="border-bottom padding-top8" />

                  <p className="text-left padding-top8">
                    We offer two membership plans: Linear and Exponential.
                    Schedule a meeting with the PublicMind Team to discuss your
                    options and discover the benefits, including increased
                    visibility, concierge support, premium features, and early
                    access to new tools.
                  </p>
                  <p className="text-left padding-top8">
                    <strong>Note:</strong> This option requires a meeting.
                    Access to PublicMind will be granted after the meeting is
                    completed.
                  </p>
                  <br />
                  <a
                    href={constants.jacks_calendly}
                    onClick={async () => {
                      await updateProfile({
                        id,
                        subscription: 'yearly_payment',
                        expiration: null,
                      });
                      navigate('/payment-pending');
                    }}
                    className="button-container"
                    style={{textDecoration: 'none', color: 'white'}}
                    target="_blank"
                    rel="noopener noreferrer">
                    SCHEDULE YOUR MEETING
                  </a>
                  <p className="text-secondary padding-top8">
                    This will navigate you to Calendly to choose a time
                  </p>
                </>
              ) : null}
            </div>
            {path !== 2 ? (
              <div className="card">
                <p className="text-secondary text-left">
                  {path === 2 ? 'Current Selection' : 'Option 2'}
                </p>
                <h4>
                  <span style={{color: '#ff416c'}}>Growth</span>
                </h4>

                <p className="text-secondary text-left">
                  Help expand our community by vouching for new members.
                  Contribute to the network’s development through active
                  engagement and collaboration. Be part of building a stronger,
                  vetted network.
                </p>

                <button
                  className="invite-external-button"
                  onClick={pathVouchChosen}>
                  Explore
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <InviteGroupModal
        active={add}
        setActive={setAdd}
        group_id={current_group}
      />
      <PaymentMeetingModal active={meet} setActive={setMeet} />
    </>
  );
};

export default ConfirmSubscription;
