import {sendEmail} from '../api/email';
import {
  chainListener,
  fetchChain,
  listSortedChains,
  listSortedMessages,
  messageListener,
  setChain,
  setMessage,
  updateChain,
  updateMsg,
} from '../api/graphql';
import sendDirectMessageHTML from '../assets/html/send-direct-message';
import constants from '../components/constants';
import createDataContext from './create-data-context';

const uniqueIds = arr => {
  const uniqueObj = {};
  const uniqueArr = [];

  for (const id of arr) {
    if (!uniqueObj[id]) {
      uniqueObj[id] = true;
      uniqueArr.push(id);
    }
  }

  return uniqueArr;
};

const uniqueCheck = (array, id, inverted) => {
  if (!array.includes(id)) {
    if (inverted) {
      array.unshift(id);
    } else {
      array.push(id);
    }
  }
  return array;
};

const messageReducer = (state, action) => {
  const {
    id,
    ids,
    chain,
    chains,
    message,
    messages,
    message_chain,
    sub,
    nextToken,
    user_id,
  } = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'setChain':
      const unique = uniqueCheck([...state.chain_ids], id);
      return {
        ...state,
        chain_ids: unique,
        chains: {...state.chains, [id]: chain},
      };
    case 'setChains':
      const combined = [...state.chain_ids, ...ids];
      const unique2 = uniqueIds(combined);
      return {
        ...state,
        chain_ids: unique2,
        chains: {...state.chains, ...chains},
        chains_loaded: true,
        paginations: {...state.paginations, chains: nextToken},
      };
    case 'setMessages':
      return {
        ...state,
        message_ids: {
          ...state.message_ids,
          [message_chain]: [
            ...(state.message_ids[message_chain] || []),
            ...ids,
          ],
        },
        messages: {
          ...state.messages,
          ...messages,
        },
        paginations: {...state.paginations, [message_chain]: nextToken},
      };
    case 'sendMessage':
      return {
        ...state,
        messages: {...state.messages, [id]: message},
      };
    case 'updateMessage':
      return {
        ...state,
        messages: {
          ...state.messages,
          [id]: {...state.messages[id], ...message},
        },
      };
    case 'addChain':
      const unique3 = uniqueCheck([...state.chain_ids], id);
      return {
        ...state,
        chain_ids: unique3,
        chains: {...state.chains, [id]: chain},
      };
    case 'messageListener':
      let new_messages = false;
      if (user_id !== message.sender_id) {
        new_messages = true;
      }
      const unique4 = uniqueCheck(
        [...(state.message_ids[message_chain] || [])],
        id,
        true,
      );
      return {
        ...state,
        new_messages,
        messages: {...state.messages, [id]: message},
        message_ids: {
          ...state.message_ids,
          [message_chain]: unique4,
        },
        chains: {
          ...state.chains,
          [message_chain]: {...state.chains[message_chain], ...chain},
        },
      };
    case 'addSub':
      return {
        ...state,
        subscriptions: {...state.subscriptions, [id]: sub},
      };
    default:
      return state;
  }
};

const defaultUpdate = dispatch => update => {
  try {
    dispatch({type: 'default', payload: update});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createChain = dispatch => async chain => {
  try {
    const {id} = chain;
    await setChain(chain);
    const payload = {id, chain};
    dispatch({type: 'addChain', payload});
    return {success: true, error: null, data: {id}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getChain = dispatch => async id => {
  try {
    const chain = await fetchChain(id);
    if (chain) {
      const payload = {id, chain};
      dispatch({type: 'setChain', payload});
      return {success: true, error: null, data: {chain}};
    }
    return {success: false, error: "ITEM DOESN'T EXIST"};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getChains = dispatch => async query => {
  try {
    const {items, nextToken} = await listSortedChains(query);
    const chains = {};
    const ids = items.map(chain => {
      const {id} = chain;
      chains[id] = chain;
      return id;
    });
    const payload = {ids, chains, nextToken};
    dispatch({type: 'setChains', payload});
    return {success: true, error: null, data: {ids, chains}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const sendMessage = dispatch => async (message, profile, other) => {
  try {
    const {message_chain, id, content, timestamp, sender_id} = message;
    const {first_name, last_name, email} = profile || {};

    const pretty_name =
      first_name || last_name
        ? `${first_name || ''} ${last_name || ''}`.trim()
        : email;

    const chain = {
      id: message_chain,
      last_message: content,
      last_timestamp: timestamp,
      last_sender: sender_id,
    };
    await setMessage(message);
    let response = await updateChain(chain);

    const payload = {message, message_chain, id, chain};
    dispatch({type: 'sendMessage', payload});
    // SEND EMAIL TO RECIPIENT
    try {
      const link = `${constants.root_url}/messages`;
      const html = sendDirectMessageHTML({message, profile, link});
      const msg = {
        to: other,
        subject: `New Direct Message ${'from ' + pretty_name}`,
        text: `New Direct Message ${'from ' + pretty_name}`,
        html: html,
      };
      await sendEmail(msg);
    } catch (err) {
      console.log(err);
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getMessages = dispatch => async query => {
  try {
    const {message_chain} = query;
    const {items, nextToken} = await listSortedMessages(query);
    const messages = {};
    const ids = items.map(message => {
      const {id} = message;
      messages[id] = message;
      return id;
    });
    const payload = {ids, messages, message_chain, nextToken};
    dispatch({type: 'setMessages', payload});
    return {success: true, error: null, data: {ids}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateMessage = dispatch => async message => {
  try {
    const {id} = message;
    await updateMsg(message);
    const payload = {id, message};
    dispatch({type: 'updateMessage', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const attachMessageListener = dispatch => async (vars, user_id) => {
  try {
    const {
      filter: {
        message_chain: {eq},
      },
    } = vars;
    if (!eq) {
      return;
    }

    const onEvent = ({value}) => {
      const {id, message_chain, content, timestamp, sender_id} = value;
      const chain = {
        id: message_chain,
        last_message: content,
        last_timestamp: timestamp,
        last_sender: sender_id,
      };
      const payload = {message: value, message_chain, id, user_id, chain};
      dispatch({type: 'messageListener', payload});
    };
    const sub = messageListener(vars, onEvent);
    const payload = {sub, id: eq};
    dispatch({type: 'addSub', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const attachChainListener = dispatch => async vars => {
  try {
    const onEvent = ({value}) => {
      const {id} = value;
      const payload = {chain: value, id};
      dispatch({type: 'addChain', payload});
    };
    const sub = chainListener(vars, onEvent);
    const payload = {sub, id: 'chains'};
    dispatch({type: 'addSub', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const resetMessages = dispatch => () => {
  try {
    dispatch({
      type: 'default',
      payload: defaultValues,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const defaultValues = {
  chain_ids: [],
  message_ids: {},
  chains: null,
  messages: null,
  subscriptions: {},
  paginations: {},
  new_messages: false,
  chains_loaded: false,
  error: null,
};

export const {Provider, Context} = createDataContext(
  messageReducer,
  {
    defaultUpdate,
    createChain,
    getChain,
    getChains,
    sendMessage,
    getMessages,
    updateMessage,
    attachMessageListener,
    attachChainListener,
    resetMessages,
  },
  defaultValues,
);

const handleErrors = (err, dispatch) => {
  const {data, errors, code} = err;
  console.log('MESSAGE ERROR', err);
  let error = 'Something went wrong.';
  let datas = null;

  if (code) {
    switch (code) {
      default:
        break;
    }
  }

  const top_error = errors && errors[0];
  if (top_error) {
    switch (top_error.errorType) {
      // SET THE ERROR AS EXISTS AND DATA TO TRUE SO KNOW ALREADY CREATED
      case 'DynamoDB:ConditionalCheckFailedException':
        error = 'Item already exists.';
        datas = true;
        break;
      default:
        break;
    }
  }

  dispatch({
    type: 'error',
    payload: error,
  });
  return {success: false, error, data: datas};
};
