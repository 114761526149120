import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaHouse} from 'react-icons/fa6';
import {PiPlus} from 'react-icons/pi';

import {MdLogout} from 'react-icons/md';
import {
  RxCardStack,
  RxEnvelopeClosed,
  RxFileText,
  RxLightningBolt,
  RxMagnifyingGlass,
  RxMix,
  RxMoon,
  RxPaperPlane,
  RxSun,
} from 'react-icons/rx';
import QRCode from 'react-qr-code';
import {Outlet, useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import {v4} from 'uuid';
import '../../App.css';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLogout from '../../hooks/use-logout';
import {useMessage} from '../../hooks/use-message';
import useSearchParameters from '../../hooks/use-search-parameters';
import {useWorkspace} from '../../hooks/use-workspace';
import CreatePost from '../../pages/posts/create-post';
import MobileCreatePost from '../../pages/posts/create-post-mobile';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {Badge, BadgeDot} from '../badge/badge';
import {LinkButton} from '../buttons/buttons';
import constants from '../constants';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../images/logos';
import {DropDown} from '../inputs/drop-down';
import {ProfileImage} from '../inputs/uploader';
import {NetworkBackground} from '../loading/network-background';
import {InviteGroupModal} from '../modal/invite-group-modal';
import {Modal} from '../modal/modal';
import {TooltipRight} from '../tooltip/tooltip';
import './styles.css';

export const PublicLayout = props => {
  const {children} = props;

  return (
    <div className="base-container align-center">
      <div className="public-layout">{children || <Outlet />}</div>
      <NetworkBackground />
    </div>
  );
};

export const AuthLayout = props => {
  const {children} = props;

  return (
    <div className="base-container auth-layout">
      <Sidebar disabled={true} />
      {children || <Outlet />}
      <NetworkBackground />
      <Footer />
    </div>
  );
};

export const FeedLayout = props => {
  const {children} = props;

  return (
    <>
      <div className="base-container">
        <Sidebar />
        <FeedHeader />
        <div className={'feed-layout'}>
          {children || <Outlet />}
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

const Sidebar = ({disabled}) => {
  const navigate = useNavigate();
  const {showSpace} = useIntercom();
  const logout = useLogout();
  const {loadOpps, categorizeOpps} = useCollaborator();
  const {
    state: {
      id,
      profile,
      current_group,
      groups,
      invitations_loaded,
      invitations,
    },
    defaultUpdate,
    getInvitations,
  } = useAuth();
  const {
    state: {new_opps, opp_token, new_opps_loaded},
    getNewOpportunities,
    defaultUpdate: update,
  } = useWorkspace();
  const {
    state: {chains, chain_ids, chains_loaded},
    getChains,
  } = useMessage();

  const [add, setAdd] = useState(false);
  const [messageNotifications, setNotifications] = useState(0);
  const [createPost, setCreate] = useState(false);
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  );
  const [popup, setPopup] = useState({on: false, message: ''});

  const {group_ids} = profile || {};
  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.includes('mac');
  const commandKey = isMac ? 'CMD' : 'CTRL';

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'dark' : 'light',
    );
  }, [darkMode]);

  useEffect(() => {
    const setThemeBasedOnSystemPreference = () => {
      setDarkMode(
        window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches,
      );
    };

    setThemeBasedOnSystemPreference();
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', setThemeBasedOnSystemPreference);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', setThemeBasedOnSystemPreference);
    };
  }, []);

  useEffect(() => {
    const unopened = chain_ids.filter(
      chain_id => chains?.[chain_id]?.last_sender !== id,
    ).length;
    setNotifications(unopened);
  }, [chain_ids, chains, id]);

  const handleAction = action => () => {
    switch (action) {
      case 'help':
        showSpace('help');
        break;
      case 'group':
        // showSpace('help');
        navigate(`/groups/create/${v4()}`);
        break;
      case 'messages':
        navigate('/messages');
        break;
      case 'feed':
        navigate('/feed');
        break;
      case 'invite':
        setAdd(!add);
        break;
      case 'post':
        setCreate(!createPost);
        break;
      case 'darkMode':
        setDarkMode(!darkMode);
        break;
      case 'home':
        navigate('/home');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      const {metaKey, ctrlKey, altKey, shiftKey, key, target} = event;
      const isInputElement = ['INPUT', 'TEXTAREA', 'SELECT'].includes(
        target.tagName,
      );

      if ((metaKey || ctrlKey) && !altKey && !shiftKey && !isInputElement) {
        const actionMap = {
          '/': 'help',
          // 'm': 'messages',
          's': 'feed',
          'i': 'invite',
          // 'p': 'post',
          'd': 'darkMode',
          'h': 'home',
        };

        if (actionMap[key]) {
          event.preventDefault();
          handleAction(actionMap[key])();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [darkMode, add, createPost]);

  const handleGroupChange = groupId => {
    const active = current_group === groupId;
    if (!active) {
      defaultUpdate({
        current_group: groupId,
        profiles_loaded: false,
        profile_token: null,
        orgs_loaded: false,
        org_token: null,
      });
      update({
        posts_loaded: false,
        post_token: null,
        feed_loaded: false,
        feed_token: null,
        new_opps_loaded: false,
        opp_token: null,
        feed: [],
        post_feed: [],
        user_posts: {},
      });
      track('current_group_updated', {group_id: groupId});
      //TODO Include which group
      navigate('/threads');
    } else {
      navigate('/threads');
    }
  };

  const getGroupInitials = name =>
    name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);

  const buttons = [
    {
      onClick: handleAction('group'),
      icon: (
        <div className={'add-group-circle'}>
          <PiPlus />
        </div>
      ),
      message: 'Create Group',
      commandMessage: `${commandKey} + g`,
      iconTitle: 'Group',
      notifications: 0,
    },
    {
      onClick: handleAction('invite'),
      icon: <RxEnvelopeClosed size="18px" />,
      message: 'Invite to Group',
      commandMessage: `${commandKey} + i`,
      iconTitle: 'Invite',
      notifications: 0,
    },
    {
      onClick: handleAction('feed'),
      icon: <RxMagnifyingGlass size="20px" />,
      message: 'Search People/Groups/Threads',
      commandMessage: `${commandKey} + s`,
      iconTitle: 'Search',
      notifications: 0,
    },
    {
      onClick: handleAction('darkMode'),
      icon: darkMode ? <RxMoon size="20px" /> : <RxSun size="18px" />,
      message: 'Toggle Darkmode',
      commandMessage: `${commandKey} + d`,
      iconTitle: darkMode ? 'Light?' : 'Dark?',
      notifications: 0,
    },
  ];

  if (disabled) return null;

  return (
    <>
      <div className="sidebar-column">
        <div className="group-circles">
          <TooltipRight text={'All Groups'}>
            <div
              className={`group-circle ${
                current_group === 'all' ? 'active' : ''
              }`}
              onClick={() => handleGroupChange('all')}>
              {darkMode ? (
                <PublicMindLogoDarkNoText />
              ) : (
                <PublicMindLogoLightNoText />
              )}
            </div>
          </TooltipRight>
          {group_ids?.map(groupId => {
            const group = groups?.[groupId] ?? {};
            return (
              <TooltipRight key={groupId} text={group.name}>
                <div
                  className={`group-circle ${
                    current_group === groupId ? 'active' : ''
                  }`}
                  onClick={() => handleGroupChange(groupId)}>
                  {group?.image?.url ? (
                    <img
                      src={group.image.url}
                      alt=""
                      style={{height: '42px', width: '42px'}}
                    />
                  ) : (
                    getGroupInitials(group.name)
                  )}
                </div>
              </TooltipRight>
            );
          })}
        </div>

        <div className="layout-action-buttons">
          {buttons.map((button, i) => (
            <div
              key={i}
              className="layout-action-button"
              onClick={button.onClick}>
              <TooltipRight
                text={button.message}
                subtext={button.commandMessage}>
                {button.icon}
                <BadgeDot active={button.notifications} />
              </TooltipRight>
            </div>
          ))}
        </div>
        <div className="flex-column align-center">
          <div className="flex-column">
            <DropDown
              items={[
                {text: 'Profile', onClick: () => navigate('/settings/profile')},
                {text: 'Groups', onClick: () => navigate('/groups')},

                {
                  text: 'Organization',
                  onClick: () => navigate('/settings/organization'),
                },
                {text: 'Log Out', onClick: logout},
                ...(process.env.NODE_ENV === 'development'
                  ? [
                      {
                        text: 'MASTER',
                        onClick: () => navigate('/settings/master'),
                      },
                    ]
                  : []),
              ]}>
              <div className="margin-8">
                <TooltipRight text="View Profile Settings">
                  <ProfileImage
                    data={profile?.profile_image}
                    onClick={() => {}}
                    style={{height: '24px', width: '24px'}}
                  />
                </TooltipRight>
              </div>
            </DropDown>
          </div>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <InviteGroupModal
        active={add}
        setActive={setAdd}
        group_id={current_group}
      />
      <CreatePost active={createPost} setActive={setCreate} />
    </>
  );
};

export default Sidebar;

const FeedHeader = ({}) => {
  const logout = useLogout();
  const {loadOpps, categorizeOpps} = useCollaborator();
  const navigate = useNavigate();
  const {
    state: {
      id,
      profile,
      current_group,
      groups,
      invitations_loaded,
      invitations,
    },
    defaultUpdate,
    getInvitations,
  } = useAuth();

  const {
    state: {new_opps, active_opps, opportunities, opp_token, new_opps_loaded},
    getNewOpportunities,
    defaultUpdate: update,
  } = useWorkspace();
  const {
    state: {new_messages},
    defaultUpdate: updateMessages,
  } = useMessage();
  const {
    state: {chains_loaded},
    getChains,
  } = useMessage();

  const {profile_image, group_ids} = profile || {};
  const {name} = groups?.[current_group] ?? {};
  //REFACTOR LATER
  const defaults = categorizeOpps();
  const incoming =
    defaults.incoming.internal.count + defaults.incoming.external.count;

  const master =
    process.env.NODE_ENV === 'development'
      ? [{text: 'MASTER', onClick: () => navigate('/settings/master')}]
      : [];

  const paths = [
    {
      title: 'Inbox',
      path: '/opportunities',
      icon: <RxCardStack />,
      items: null,
      disabled: false,
      notifications: (new_opps?.length ?? 0) + incoming,
    },
    {
      title: 'Notes',
      path: '/notes',
      icon: <RxFileText />,
      items: null,
      disabled: false,
      notifications: 0,
    },
    {
      title: 'Groups',
      path: '/groups',
      icon: <RxMix />,
      items: null,
      disabled: false,
      notifications: invitations?.length ?? 0,
    },
  ];

  useEffect(() => {
    if (!invitations_loaded) {
      getInvitations({
        filter: {invitee_id: {eq: id}, status: {eq: 'pending'}},
        limit: constants.all_items,
      });
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      const query = {
        filter: {
          status: {eq: 'inactive'},
          members: {contains: id},
          groups: {contains: current_group},
        },
        limit: constants.opportunity_limit,
        nextToken: opp_token,
      };
      if (!new_opps_loaded) {
        getNewOpportunities(query);
        loadOpps();
      }
    };

    load();
  }, [new_opps_loaded]);

  useEffect(() => {
    const fetchChains = async () => {
      const query = {
        type: 'direct',
        sortDirection: 'DESC',
        filter: {members: {contains: id}},
      };
      const {success, error, data} = await getChains(query);
    };

    // FETCH
    if (!chains_loaded) {
      fetchChains();
    }
  }, []);

  return (
    <>
      <div className="feed-header"></div>
      <ActionPopup
        message={'New Direct Message'}
        setOff={() => {
          updateMessages({new_messages: false});
        }}
        on={new_messages}
      />
    </>
  );
};

const FeedButton = ({section}) => {
  const navigate = useNavigate();

  const {title, path, icon, items, disabled, notifications} = section;

  const message =
    path === '/opportunities'
      ? 'Act on Matches'
      : path === '/notes'
      ? 'View Notes'
      : path === '/threads'
      ? 'View Threads'
      : 'View Groups';

  return (
    <TooltipRight
      text={disabled ? 'Finish Getting Started to Unlock' : message}>
      <div
        className="flex-column justify-center align-center margin-8 clickable"
        onClick={() => {
          if (disabled) {
            return;
          }
          navigate(path);
        }}>
        <Badge count={notifications} />
        {icon}
        {/* <p className="text-secondary text-8">{title}</p> */}
      </div>
    </TooltipRight>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const mobile = isMobile || isTablet;

  return (
    <div className={mobile ? '' : 'footer-container'}>
      <p className="footer-text text-secondary">
        © 2024 by PublicMind {process.env.REACT_APP_VERSION} <br />
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/terms-of-service');
        }}>
        Terms of Service
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/privacy-policy');
        }}>
        Privacy Policy
      </p>
    </div>
  );
};

export const AuthHeader = ({header, subheader, action}) => {
  const logout = useLogout();

  const mobile = isMobile;

  return (
    <div className="container">
      {action && (
        <LinkButton onClick={logout} className="top-left">
          Log Out
        </LinkButton>
      )}
      <div className="flex-column align-center">
        <div className={mobile ? 'width-80' : 'width-66'}>
          <br />
          <div className="flex-column align-center">
            <PublicMindLogoLightNoText />
          </div>
          {header && <h3 className="">{header}</h3>}
          {subheader && (
            <p className="text-secondary padding-bottom16">{subheader}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export const MobileLayout = ({children}) => {
  const {createReferralLink} = useSearchParameters();
  const logout = useLogout();
  const navigate = useNavigate();

  const {
    state: {id, current_organization, profile, current_group, groups},
    defaultUpdate,
  } = useAuth();
  const {defaultUpdate: update} = useWorkspace();

  const [active, setActive] = useState(false);
  const [share, setShare] = useState(false);
  const [link, setLink] = useState('');

  const {group_ids} = profile || {};
  const {name} = groups?.[current_group] ?? {};

  useEffect(() => {
    const link = createReferralLink();
    setLink(link);
  }, [id, current_organization, current_group]);

  return (
    <>
      <div className="base-container">
        <div
          className="flex-row justify-between align-center"
          style={{marginBottom: '32px'}}>
          <div className="flex-row align-center">
            <button
              className="button-container-mobile"
              onClick={() => {
                navigate('/home');
              }}>
              <FaHouse />
            </button>
            <button
              className="invite-external-button-mobile"
              onClick={() => {
                setShare(!share);
              }}>
              <RxPaperPlane />
            </button>
            <button
              className="button-container-mobile"
              onClick={() => {
                setActive(!active);
              }}>
              <RxLightningBolt />
            </button>
            <DropDown
              items={group_ids?.map(grp_id => {
                const {name} = groups?.[grp_id] ?? {};
                return {
                  text: name,
                  onClick: () => {
                    const active = current_group === grp_id;
                    if (!active) {
                      defaultUpdate({
                        current_group: grp_id,
                        profiles_loaded: false,
                        profile_token: null,
                        orgs_loaded: false,
                        org_token: null,
                      });
                      update({
                        posts_loaded: false,
                        post_token: null,
                        feed_loaded: false,
                        feed_token: null,
                        new_opps_loaded: false,
                        opp_token: null,
                        feed: [],
                        post_feed: [],
                      });
                      track('current_group_updated', {group: grp_id});
                    }
                  },
                };
              })}>
              <h5>{name}</h5>
            </DropDown>
          </div>
          <LinkButton onClick={logout}>
            <MdLogout size={'24px'} />
          </LinkButton>
        </div>
        {children || <Outlet />}
        <Footer />
      </div>
      <MobileCreatePost active={active} setActive={setActive} />
      <Modal active={share} setActive={setShare}>
        <p className="text-bold">Invite Members to the platform</p>
        <br />
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            width: '60%',
          }}>
          <QRCode
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </div>
        <br />
      </Modal>
    </>
  );
};
