/* eslint-disable import/no-anonymous-default-export */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default () => {
  const downloadPDF = (ref, filename = 'output.pdf') => {
    // CREATE FILE NAME BASED ON URI
    if (ref?.current && ref.current?.baseURI.includes('organizations')) {
      filename = 'organization-info.pdf';
    } else if (ref?.current && ref.current?.baseURI.includes('updates')) {
      filename = 'community-news.pdf';
    }
    const input = ref.current;

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pdfwidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfwidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfwidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        'PNG',
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
      );
      pdf.save(filename);
    });
  };

  return {downloadPDF};
};
