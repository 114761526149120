import constants from '../../components/constants';

const generateFollowUpNudge = props => {
  const {link = null, profile, note} = props || {};
  const {id: user_id, email, first_name, last_name} = profile || {};
  const pretty_name =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src=${
            constants.publicmind_logo
          } alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <table width="100%" cellpadding="20" cellspacing="0" bgcolor="#f4f4f4">
          <tr>
            <td>
              <h3 style="text-align: center;">${pretty_name} is following up on your invitation to join PublicMind</h3>
                  <p style="white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 16px; text-align: center;">${
                    note || '<i>No note</i>'
                  }</p>
              <br />                     
              <p style="text-align: start; margin: 0 64px; line-height: 2;">
                PublicMind is a private, invite-only professional network designed to empower you to network at the speed of light.
                Membership is highly selective. If you received this email, it's because ${
                  pretty_name || 'someone'
                } believes you would be a valuable addition to our network.
              </p>
              <br />
      
              <p style="text-align: center; margin: 0 64px; line-height: 2;">
                This invitation link expires in 72 hours.
              </p>
              <br />
              <div style="text-align: center;">
                <a href=${link} style="background-color: #000000; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
                  Join PublicMind
                </a>
              </div>
              <br />
              <br />
              <div style="text-align: center;">
                <a href="https://calendly.com/jack-publicmind" style="background-color: #FFF; color: black; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 12px; border: 0.5px solid #333333;">
                  Learn More
                </a>
              </div>
              <br />
              <br />
              <p style="text-align: start; margin: 0 64px; line-height: 2;">With gratitude,</p>
              <p style="text-align: start; margin: 0 64px; line-height: 2;">The PublicMind Team</p>
            </td>
          </tr>
        </table>
        <br />
        <p style="text-align: center;">Sent from PublicMind</p>
        <br />
        <div style="text-align: center;">
          <a href=${
            constants.help_center
          } style="margin: 0 8px;">Help Center</a>
          <a href=${
            constants.privacy_policy
          } style="margin: 0 8px;">Privacy Policy</a>
          <a href=${
            constants.terms_of_service
          } style="margin: 0 8px;">Terms of Service</a>
        </div>
      </body>
    </html>
  `;
};

export default generateFollowUpNudge;
