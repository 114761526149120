import React, {useEffect} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';

const Root = () => {
  const navigate = useNavigate();
  const {
    state: {
      processing,
      auth,
      profile,
      organizations,
      current_organization,
      current_group,
    },
  } = useAuth();

  const {status} = profile || {};
  const org = organizations?.[current_organization] ?? {};

  const mobile = isMobile && !isTablet;

  useEffect(() => {
    // WAIT UNTIL HAVE DATA
    if (processing) {
      return;
    }

    // IF USER IS LOGGED IN HANDLE ROUTING
    if (auth) {
      if (status === 'confirmed') {
        navigate('/introduce-yourself');
        return;
      }

      // if (!current_group) {
      //   navigate('/groups');
      //   return;
      // }

      navigate('/home');

      return;
    } else {
      // IF NO USER HAVE THEM SIGN IN
      navigate('/signin');
      return;
    }
  }, [processing, auth, status, current_group]);

  return (
    <div className="base-container">
      <SpinningIndicator />
    </div>
  );
};

export default Root;
