import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import {MdOutlineAddReaction} from 'react-icons/md';

export const EmojiInput = ({emoji, setEmoji, setShowPicker, showPicker}) => {
  const handleEmojiClick = (event, emojiObject) => {
    if (setEmoji) {
      setEmoji(event.emoji);
    }
    setShowPicker(false);
  };

  return (
    <div style={{position: 'relative'}}>
      <button
        className="action-button"
        onClick={() => setShowPicker(!showPicker)}>
        {emoji ? <p>{emoji}</p> : <MdOutlineAddReaction />}
      </button>
      {showPicker && (
        <div
          style={{
            position: 'absolute',
            top: 'calc(100% + 10px)',
            right: '-10px',
            zIndex: 10,
          }}>
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            searchDisabled={false}
            theme="auto"
            reactionsDefaultOpen={true}
            skinTonesDisabled={true}
          />
        </div>
      )}
    </div>
  );
};
