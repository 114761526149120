import {
  listDeckByUser,
  listSortedInteractions,
  setDeck,
  setInteraction,
  updateDeck,
} from '../api/graphql';
import createDataContext from './create-data-context';

const networkingReducer = (state, action) => {
  const {id, ids, entry, update, content, interactions, interaction, target} =
    action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'setRolodex':
      return {
        ...state,
        rolodex: ids,
        rolodex_content: content,
        rolodex_loaded: true,
      };
    case 'setEntry':
      return {
        ...state,
        rolodex: [id, ...state.rolodex],
        rolodex_content: {...state.rolodex_content, [id]: entry},
      };
    case 'updateEntry':
      return {
        ...state,
        rolodex_content: {
          ...state.rolodex_content,
          [id]: {...state.rolodex_content[id], ...update},
        },
      };
    case 'fetchInteractions':
      return {
        ...state,
        // interaction_ids: [...state.interaction_ids, ...ids],
        interactions: {...state.interactions, ...interactions},
        interactions_by_user: {
          ...state.interactions_by_user,
          [target]: ids,
        },
      };
    case 'fetchOtherInteractions':
      return {
        ...state,
        // interaction_ids: [...state.interaction_ids, ...ids],
        interactions: {...state.interactions, ...interactions},
        interactions_by_other: {
          ...state.interactions_by_other,
          [target]: ids,
        },
      };
    case 'addInteraction':
      const {target_user} = interaction;
      return {
        ...state,
        // interaction_ids: [id, ...state.interaction_ids],
        interactions: {...state.interactions, [id]: interaction},
        interactions_by_user: {
          ...state.interactions_by_user,
          [target_user]: [
            id,
            ...(state.interactions_by_user?.[target_user] ?? []),
          ],
        },
      };
    default:
      return state;
  }
};

const defaultUpdate = dispatch => update => {
  try {
    dispatch({type: 'default', payload: update});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const fetchRolodex = dispatch => async query => {
  try {
    const {items, nextToken} = await listDeckByUser(query);

    // TODO: paginate
    const content = {};
    const ids = items.map(item => {
      const {id} = item;
      content[id] = item;
      return id;
    });

    const payload = {ids, content};
    dispatch({type: 'setRolodex', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateEntry = dispatch => async update => {
  try {
    const {id} = update;
    await updateDeck(update);

    const payload = {id, update};
    dispatch({type: 'updateEntry', payload});
    return {success: true};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createEntry = dispatch => async entry => {
  try {
    const {id} = entry;
    await setDeck(entry);

    const payload = {id, entry};
    dispatch({type: 'setEntry', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const fetchEntry = dispatch => async id => {
  try {
    // TODO
    const payload = {};
    dispatch({type: 'setEntry', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const fetchInteractions = dispatch => async (query, target) => {
  try {
    const {items} = await listSortedInteractions(query);

    const interactions = {};
    const ids = items.map(item => {
      const {id} = item;
      interactions[id] = item;
      return id;
    });

    const payload = {ids, interactions, target};
    dispatch({type: 'fetchInteractions', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const fetchOtherInteractions = dispatch => async (query, target) => {
  try {
    const {items} = await listSortedInteractions(query);

    const interactions = {};
    const ids = items.map(item => {
      const {id} = item;
      interactions[id] = item;
      return id;
    });

    const payload = {ids, interactions, target};
    dispatch({type: 'fetchOtherInteractions', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createInteraction = dispatch => async interaction => {
  try {
    const {id} = interaction;
    await setInteraction(interaction);

    const payload = {id, interaction};
    dispatch({type: 'addInteraction', payload});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const resetNetwork = dispatch => () => {
  try {
    dispatch({
      type: 'default',
      payload: defaultValues,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const defaultValues = {
  rolodex: [],
  rolodex_content: {},
  interaction_ids: [],
  interactions: {},
  interactions_by_user: {},
  interactions_by_other: {},
  rolodex_loaded: false,
  error: null,
};

export const {Provider, Context} = createDataContext(
  networkingReducer,
  {
    defaultUpdate,
    fetchRolodex,
    updateEntry,
    createEntry,
    fetchEntry,
    fetchInteractions,
    fetchOtherInteractions,
    createInteraction,
    resetNetwork,
  },
  defaultValues,
);

const handleErrors = (err, dispatch) => {
  const {data, errors, code} = err;
  console.log('NETWORK ERROR', err);
  let error = 'Something went wrong.';

  if (code) {
    switch (code) {
      default:
        break;
    }
  }

  const top_error = errors && errors[0];
  if (top_error) {
    switch (top_error.errorType) {
      case 'DynamoDB:ConditionalCheckFailedException':
        error = 'Item already exists.';
        break;
      default:
        break;
    }
  }

  dispatch({
    type: 'error',
    payload: error,
  });
  return {success: false, error};
};
