import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {FaArrowUpFromBracket} from 'react-icons/fa6';
import {GoCrossReference} from 'react-icons/go';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {
  sendOtherPostToEmail,
  sendPostToEmail,
  sendPrioritiesToEmail,
  sendRecommendSomeoneEmail,
} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import useSearchParameters from '../../hooks/use-search-parameters';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {PrimaryButton} from '../buttons/buttons';
import EmailPreviewModal from '../email/email-preview';
import {FormikTextfield, MultilineFormikTextfield} from '../inputs/textfields';
import {SpinningIndicator} from '../loading/loading-indicator';
import {Modal} from './modal';

// A COMPONENT NOT A MODAL - USED TO SHARE A POST WITH OTHER USERS
export const SharePostComponent = ({post}) => {
  const isMounted = useMounted();
  const {createReferralLink} = useSearchParameters();

  const {prettyTag} = useStringFormatter();
  const {capitalize} = useStringFormatter();
  const {trackInteraction} = useTracker();

  const {
    state: {id, organizations, current_organization, profile},
  } = useAuth();

  const organization = organizations[current_organization] ?? {};
  const post_type = capitalize(post?.type ?? 'Project');

  const [popup, setPopup] = useState({on: false, message: ''});
  const [emailFields, setEmailFields] = useState(['email_0']);

  const formik = useFormik({
    initialValues: {
      email_0: '',
      note: '',
    },
    validationSchema: Yup.object().shape(
      emailFields.reduce((acc, field) => {
        acc[field] = Yup.string().email('Must be a valid email').max(255);

        return acc;
      }, {}),
    ),
    onSubmit: async (values, helpers) => {
      try {
        const {note} = values;
        const emails = emailFields.map(field => values[field]);
        for (const email of emails) {
          if (email === undefined) continue;
          const {id: org_id} = organization;
          const base_user = {
            id: email,
            email,
          };

          const link = createReferralLink(email);
          post.inner_tags = post.inner_tags?.map(x => {
            return prettyTag(x);
          });
          post.outer_tags = post.outer_tags?.map(x => {
            return prettyTag(x);
          });

          const {success, error} = await sendPostToEmail(
            base_user,
            link,
            post,
            profile,
            post_type,
            note,
          );

          if (success) {
            setPopup({
              on: true,
              message: 'Post shared to: ' + email,
            });
            helpers.resetForm();
            setEmailFields(['email_0']);

            track('shared_post', {
              user: id,
              org_id,
              sent_to: email,
              post_id: post?.id,
            });
            trackInteraction({
              target_user: email,
              content_id: post?.id,
              content_type: 'post',
              action: 'share',
            });
          }

          if (error) {
            setPopup({
              on: true,
              message: 'Error sending post to: ' + email,
            });

            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
            return;
          }
        }
      } catch (err) {
        if (isMounted()) {
          setPopup({
            on: true,
            message: 'Error. Please reach out to the PublicMind team',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    const lastEmailField = emailFields[emailFields.length - 1];
    if (formik.values[lastEmailField] && !formik.errors[lastEmailField]) {
      setEmailFields([...emailFields, `email_${emailFields.length}`]);
    }
  }, [formik.values, formik.errors]);

  return (
    <div className="flex-column align-center justify-center">
      <div className="flex-column align-center justify-center padding-bottom16 margin-h16">
        <span className="update-container-special-add">
          <FaArrowUpFromBracket />
        </span>
        <h2 className="invite-modal-title">Share {post_type}</h2>
        <p className="invite-modal-description">
          Sharing will allow non-members to join this group.
        </p>
      </div>
      <div className="grid-container justify-center">
        {emailFields.map((field, index) => (
          <div key={index} className="grid-7 invite-modal-form-group">
            <FormikTextfield
              formik={formik}
              placeholder=""
              name={field}
              type="text"
              header={`Email ${index + 1} ${index === 0 ? '(Required)' : ''}`}
            />
          </div>
        ))}
        <div className="grid-7 invite-modal-form-group">
          <MultilineFormikTextfield
            formik={formik}
            name="note"
            type="text"
            header="Note"
          />
        </div>
      </div>

      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex-row align-center justify-center invite-modal-actions">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <PrimaryButton
            className="invite-external-button"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            SEND
          </PrimaryButton>
        )}
      </div>
      <EmailPreviewModal
        emailType={'sharePost'}
        emailProps={{profile, post, link: '', note: formik?.values?.note ?? ''}}
      />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

// MODAL USED TO SHARE A POST WITH OTHER USERS
export const SharePostModal = ({active, setActive, post}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <SharePostModalContent
          active={active}
          setActive={setActive}
          post={post}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const SharePostModalContent = ({active, setActive, post, setPopup}) => {
  const isMounted = useMounted();
  const {trackShare, trackInteraction} = useTracker();
  const {createReferralLink} = useSearchParameters();
  const {prettyTag} = useStringFormatter();

  const {
    state: {organizations, current_organization, profile},
  } = useAuth();

  const organization = organizations[current_organization] ?? {};

  const post_type =
    post?.type?.[0].toUpperCase() + post?.type?.slice(1) || 'Project';

  const [emailFields, setEmailFields] = useState(['email_0']);

  const formik = useFormik({
    initialValues: {
      email_0: '',
      note: '',
    },
    validationSchema: Yup.object().shape(
      emailFields.reduce((acc, field) => {
        acc[field] = Yup.string().email('Must be a valid email').max(255);

        return acc;
      }, {}),
    ),
    onSubmit: async (values, helpers) => {
      try {
        const {note} = values;
        const emails = emailFields.map(field => values[field]);
        for (const email of emails) {
          if (email === undefined) continue;
          const {id: org_id} = organization;
          const base_user = {
            id: email,
            email,
          };

          const link = createReferralLink(email);
          post.inner_tags = post.inner_tags?.map(x => {
            return prettyTag(x);
          });
          post.outer_tags = post.outer_tags?.map(x => {
            return prettyTag(x);
          });

          const {success, error} = await sendOtherPostToEmail(
            base_user,
            link,
            post,
            profile,
            post_type,
            note,
          );

          if (success) {
            setPopup({
              on: true,
              message: 'Post shared to: ' + email,
            });
            helpers.resetForm();
            setEmailFields(['email_0']);

            track('shared_post', {user: base_user, org_id});
            trackShare(post?.id);
            setActive(!active);
            trackInteraction({
              target_user: email,
              content_id: post?.id,
              content_type: 'post',
              action: 'share',
            });
          }

          if (error) {
            setPopup({
              on: true,
              message: 'Error sending post to: ' + email,
            });

            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
            return;
          }
        }
      } catch (err) {
        if (isMounted()) {
          setPopup({
            on: true,
            message: 'Error. Please reach out to the PublicMind team',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    const lastEmailField = emailFields[emailFields.length - 1];
    if (formik.values[lastEmailField] && !formik.errors[lastEmailField]) {
      setEmailFields([...emailFields, `email_${emailFields.length}`]);
    }
  }, [formik.values, formik.errors]);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex-column justify-center align-center padding-bottom16 margin-h16">
        <span className="update-container-special-add">
          <FaArrowUpFromBracket />
        </span>
        <h2 className="invite-modal-title">Share this {post_type}</h2>
        <p className="invite-modal-description">
          Sharing will allow non-members to join this group.
        </p>
      </div>
      <div className="flex-column align-center ">
        {emailFields.map((field, index) => (
          <div key={index} className="grid-7 invite-modal-form-group">
            <FormikTextfield
              formik={formik}
              placeholder=""
              name={field}
              type="text"
              header={`Email ${index + 1} ${index === 0 ? '(Required)' : ''}`}
            />
          </div>
        ))}
        <div className="grid-7 invite-modal-form-group">
          <MultilineFormikTextfield
            formik={formik}
            name="note"
            type="text"
            header="Note"
          />
        </div>
      </div>

      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center invite-modal-actions">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <PrimaryButton
            className="invite-external-button"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            SEND
          </PrimaryButton>
        )}
      </div>
      <EmailPreviewModal
        emailType={'sharePost'}
        emailProps={{profile, post, link: '', note: formik?.values?.note ?? ''}}
      />
    </Modal>
  );
};

// USED TO SHARE PRIORITIES WITH OTHER USERS - PROFILE SETTINGS
export const SharePrioritiesModal = ({active, setActive, priorities, type}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <SharePrioritiesModalContent
          active={active}
          setActive={setActive}
          priorities={priorities}
          type={type}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const SharePrioritiesModalContent = ({
  active,
  setActive,
  priorities,
  type,
  setPopup,
}) => {
  const isMounted = useMounted();
  const {createReferralLink} = useSearchParameters();
  const {prettyTag} = useStringFormatter();

  const {
    state: {id, organizations, current_organization, profile},
  } = useAuth();

  const organization = organizations[current_organization] ?? {};
  const priority_type = type[0].toUpperCase() + type.slice(1);

  const [emailFields, setEmailFields] = useState(['email_0']);

  const formik = useFormik({
    initialValues: {
      email_0: '',
    },
    validationSchema: Yup.object().shape(
      emailFields.reduce((acc, field) => {
        acc[field] = Yup.string().email('Must be a valid email').max(255);

        return acc;
      }, {}),
    ),
    onSubmit: async (values, helpers) => {
      try {
        const emails = emailFields.map(field => values[field]);
        for (const email of emails) {
          if (email === undefined) continue;
          const {id: org_id} = organization;
          const base_user = {
            id: email,
            email,
          };

          const link = createReferralLink(email);
          priorities = priorities?.map(x => {
            return prettyTag(x);
          });

          const {success, error} = await sendPrioritiesToEmail(
            base_user,
            link,
            priorities,
            profile,
            type,
          );

          if (success) {
            setPopup({
              on: true,
              message: 'Post shared to: ' + email,
            });
            helpers.resetForm();

            track('shared_priorities', {
              user: id,
              org_id,
              sent_to: email,
              priorities,
            });
            setActive(!active);
            // navigate('/feed');
          }

          if (error) {
            setPopup({
              on: true,
              message: 'Error sending post to: ' + email,
            });

            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
            return;
          }
        }
      } catch (err) {
        if (isMounted()) {
          setPopup({
            on: true,
            message: 'Error. Please reach out to the PublicMind team',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    const lastEmailField = emailFields[emailFields.length - 1];
    if (formik.values[lastEmailField] && !formik.errors[lastEmailField]) {
      setEmailFields([...emailFields, `email_${emailFields.length}`]);
    }
  }, [formik.values, formik.errors]);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex-column align-center padding-bottom16 margin-h16">
        <span className="update-container-special-add">
          <FaArrowUpFromBracket />
        </span>
        <h2 className="invite-modal-title">Share Your New {priority_type}</h2>
        <p className="invite-modal-description">
          This will allow non-members to join this group.
        </p>
      </div>
      <div className="grid-container justify-center">
        {emailFields.map((field, index) => (
          <div key={index} className="grid-7 invite-modal-form-group">
            <FormikTextfield
              formik={formik}
              placeholder=""
              name={field}
              type="text"
              header={`Email ${index + 1} ${index === 0 ? '(Required)' : ''}`}
            />
          </div>
        ))}
      </div>

      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center invite-modal-actions">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <PrimaryButton
            className="invite-external-button"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            EMAIL PRIORITIES
          </PrimaryButton>
        )}
      </div>
      <EmailPreviewModal
        emailType={'sharePriorities'}
        emailProps={{
          profile,
          tags: priorities?.map(x => {
            return prettyTag(x);
          }),
          link: '',
          type,
        }}
      />
    </Modal>
  );
};

// USED TO SEND A NOTE ABOUT A POST TO A USER
export const SendNoteModal = ({active, setActive, post}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <SendNoteModalContent
          active={active}
          setActive={setActive}
          post={post}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const SendNoteModalContent = ({active, setActive, post, setPopup}) => {
  const isMounted = useMounted();
  const {trackRefer, trackInteraction} = useTracker();

  const {
    state: {profile},
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: Yup.object({
      note: Yup.string().trim().required('You must fill out the note'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {note} = values;
        const {success, error} = await sendRecommendSomeoneEmail(
          post.user_id,
          post,
          profile,
          note,
        );

        if (success) {
          setPopup({
            on: true,
            message: 'Note sent',
          });
          helpers.resetForm();

          track('referred_someone', {
            referrer: profile.id,
            referral_sent_to: post.user_id,
            note,
          });

          trackRefer(post?.id);
          trackInteraction({
            target_user: post.user_id,
            content_id: post?.id,
            content_type: 'post',
            action: 'refer',
          });
          setActive(!active);
        }

        if (error) {
          setPopup({
            on: true,
            message: 'Error sending referrral',
          });

          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
          return;
        }
      } catch (err) {
        if (isMounted()) {
          setPopup({
            on: true,
            message: 'Error. Please reach out to the PublicMind team',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex-column align-center padding-bottom16 margin-h16">
        <span className="update-container-special-add">
          <GoCrossReference />
        </span>
        <h2 className="invite-modal-title">Send a Note</h2>
        <p className="invite-modal-description">
          This will send an email to the post owner.
        </p>
      </div>
      <div className="flex flex-column padding-32 pretty-form-group">
        <MultilineFormikTextfield
          className="multiline-input"
          formik={formik}
          placeholder="I was thinking..."
          name="note"
          type="text"
          rows={3}
        />
      </div>

      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center invite-modal-actions">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <button
            className="invite-external-button"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            SEND
          </button>
        )}
      </div>
      <EmailPreviewModal
        emailType={'sendNote'}
        emailProps={{
          profile,
          post,
          note: null,
          previewNotes: formik.values.note,
        }}
      />
    </Modal>
  );
};
