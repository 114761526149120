import {dateFormatter} from '../../api/dates';
import constants from '../../components/constants';

//TODO add project / tag info to email
const generateMeetingRequest = props => {
  const {profile, organization, opportunity, link, previewNotes} = props || {};
  const {
    id: user_id,
    email,
    first_name,
    last_name,
    position,
    bio,
  } = profile || {};
  const {name} = organization || {};
  const {id, data, tags} = opportunity || {};
  const content = data?.find(item => item.user_id === email) ?? null;
  const notes = content?.notes ?? null;

  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src=${
            constants.publicmind_logo
          } alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 8px 20%;">
          <h1 style="text-align: left; font-size: 38px; color: black;">Inbound Match - ${dateFormatter()}</h1>
          <br/>
          <p style="white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 16px; text-align: center;">${
            previewNotes ? previewNotes : notes || '<i>No note</i>'
          }</p>
          <br/>
          <div style="text-align: left; border: 1px solid #ccc; padding: 16px; border-radius: 16px; margin: 8px 0px; background-color: #f4f4f4;">
            <p style="font-size: 18px; color: #75757a;">Request From</p>
            <h4 style="font-size: 24px;">${pretty_name}</h4>
            ${
              user_id
                ? `<p style="text-align: left; color: #75757a;">${
                    position || 'Works'
                  } at ${name || 'undeclared.'}</p>`
                : ''
            }
            ${
              bio
                ? `<p style="text-align: left; color: black;">${bio.slice(
                    0,
                    150,
                  )}...</p>`
                : ''
            }
            <br/><br/>
            <div style="text-align: center;">            
              <a href="${
                window.location.origin
              }/opportunities/detail/${id}?status=ignore" style="padding: 12px 16px; border-radius: 8px; margin: 8px; background-color: transparent; color: red; text-decoration: none; border: 1px solid red; display: inline-block;">Decline</a>
              <a href="${
                window.location.origin
              }/opportunities/detail/${id}?status=connect" style="padding: 12px 16px; border-radius: 8px; margin: 8px; background-color: green; color: white; text-decoration: none; display: inline-block;">Accept</a>
            </div>
          </div>
          <br/>
          <div style="text-align: center;">
            <a href="${
              window.location.origin
            }/opportunities/detail/${id}" style="background-color: #75757a; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">View Details</a>
          </div>
          <br />
          <p style="text-align: center;">Sent from PublicMind</p>
          <br />
          <div style="text-align: center;">
            <a href=${
              constants.help_center
            } style="margin: 0 8px;">Help Center</a>
            <a href=${
              constants.privacy_policy
            } style="margin: 0 8px;">Privacy Policy</a>
            <a href=${
              constants.terms_of_service
            } style="margin: 0 8px;">Terms of Service</a>
          </div>
        </div>
      </body>
    </html>
  `;
};

export default generateMeetingRequest;
