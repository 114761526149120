import constants from '../../components/constants';

const generateGroupInvite = props => {
  const {link = null, profile, group, note} = props || {};

  const {id: user_id, email, first_name, last_name} = profile || {};
  const {name, bio} = group;

  const pretty_name =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src=${
            constants.publicmind_logo
          } alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <table width="100%" cellpadding="20" cellspacing="0" bgcolor="#f4f4f4">
          <tr>
            <td>
             
              <h3 style="text-align: center;">${pretty_name} invited you to join the group ${name}!</h3>
                  ${
                    note
                      ? `<p style="white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 16px; text-align: center;">${note}</p>`
                      : ''
                  }
                 
              <p style="text-align: start; margin: 0 64px; line-height: 2;">
            We’re excited to have you join ${name}. Here’s what you can expect from the group:
              </p>
              <br />              
              <p style="text-align: start; margin: 0 64px; line-height: 2; font-weight: bold;">
                ${bio}
              </p>
              <br/>
              <p style="text-align: start; margin: 0 64px; line-height: 2;">
                Click below to accept the invite.
              </p>
              <br />
              <div style="text-align: center;">
                <a href=${link} style="background-color: #000000; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
                  Join ${name}
                </a>
              </div>
              <br />
              <br />
              <div style="text-align: center;">
                <a href="https://calendly.com/jack-publicmind" style="background-color: #FFF; color: black; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 12px; border: 0.5px solid #333333;">
                  Learn More
                </a>
              </div>
              <br />
              <p style="text-align: start; margin: 0 64px; line-height: 2;">
                If you have any questions or need assistance, please respond to this email.
              </p>
              <br />
              <p style="text-align: start; margin: 0 64px; line-height: 2;">With gratitude,</p>
              <p style="text-align: start; margin: 0 64px; line-height: 2;">The PublicMind Team</p>
            </td>
          </tr>
        </table>
        <br />
        <p style="text-align: center;">Sent from PublicMind</p>
        <br />
        <div style="text-align: center;">
          <a href=${
            constants.help_center
          } style="margin: 0 8px;">Help Center</a>
          <a href=${
            constants.privacy_policy
          } style="margin: 0 8px;">Privacy Policy</a>
          <a href=${
            constants.terms_of_service
          } style="margin: 0 8px;">Terms of Service</a>
        </div>
      </body>
    </html>
  `;
};

export default generateGroupInvite;
