import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import * as Yup from 'yup';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {TooltipRight} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import './ThreadInviteLink.css';

const ThreadInviteLink = () => {
  const {id} = useParams();

  const {
    state: {threads},
    getThread,
    updateThread,
  } = useThreads();
  const {
    state: {profile},
  } = useAuth();

  const thread = threads?.[id];
  const {title, content, subscribers, security_level, logo} = thread || {};
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getThread(id);
      setLoading(false);
    };
    if (id && !thread) {
      load();
    }
  }, [id, thread, getThread]);

  const formik = useFormik({
    initialValues: {
      email: profile?.id || '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email} = values;
        if (subscribers?.includes(email)) {
          helpers.setErrors({email: 'Email already subscribed'});
          return;
        }
        const merged = [...(subscribers || []), email];
        const {success, error} = await updateThread({id, subscribers: merged});
        if (success) {
          setPopup({on: true, message: 'Successfully subscribed!'});
          helpers.resetForm();
        }
        if (error) {
          helpers.setErrors({submit: error});
        }
      } catch (err) {
        helpers.setErrors({submit: err.message});
      }
    },
  });

  if (loading) {
    return (
      <div className="thread-invite-container loading-indicator">
        <SpinningIndicator />
      </div>
    );
  }

  if (!id || !thread) {
    return (
      <div className="thread-invite-container">
        <p className="error-message">What you're looking for doesn't exist.</p>
      </div>
    );
  }
  const getSecurityTitle = () => {
    switch (security_level) {
      case 'secure':
        return (
          <TooltipRight text={'Maximum Security Thread'}>
            <span className="security-icon secure">Secure</span>
          </TooltipRight>
        );
      case 'private':
        return (
          <TooltipRight text={'Invite-only Security Thread'}>
            <span className="security-icon private">Private</span>{' '}
          </TooltipRight>
        );

      case 'open':
      default:
        return (
          <TooltipRight text={'Public Thread'}>
            <span className="security-icon open">Public</span>{' '}
          </TooltipRight>
        );
    }
  };

  return (
    <div className="thread-invite-container">
      <div className="thread-invite-header">
        {getSecurityTitle()}
        <h1 className="thread-invite-title">Join the Conversation</h1>
        <p className="thread-invite-subtitle">
          Subscribe to this thread and stay updated
        </p>
      </div>
      <div className="thread-details">
        <h2 className="thread-details-title">
          {logo} {title}
        </h2>
        <p className="thread-details-content">{content}</p>
      </div>
      <form onSubmit={formik.handleSubmit} className="invite-form">
        <div className="invite-form-group">
          <label htmlFor="email" className="invite-form-label">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="invite-form-input"
            placeholder="Enter your email address"
            disabled={profile?.id ? true : false}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="invite-form-error">{formik.errors.email}</div>
          )}
        </div>
        {formik.errors.submit && (
          <div className="invite-form-error">{formik.errors.submit}</div>
        )}
        <button
          type="submit"
          className="invite-submit-button"
          disabled={formik.isSubmitting}>
          {formik.isSubmitting ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

export default ThreadInviteLink;
