import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToString, formatEventDate} from '../../api/dates';
import tagsJSON from '../../assets/lists/tags.json';
import {ErrorButton} from '../../components/buttons/buttons';
import {FormikArraySearchableDropDown} from '../../components/inputs/drop-down';
import {FileViewer, ProfileImageSimple} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {Modal} from '../../components/modal/modal';
import {useAuth} from '../../hooks/use-auth';
import {useWorkspace} from '../../hooks/use-workspace';
import './styles.css';

const MobileCreatePost = ({active, setActive}) => {
  if (active) {
    return <MobileCreatePostContent active={active} setActive={setActive} />;
  }

  return null;
};

const MobileCreatePostContent = ({active, setActive}) => {
  const navigate = useNavigate();
  const {update} = useIntercom();
  const {manageOpportunities} = useWorkspace();

  const {
    state: {id, profile, current_organization, current_group},
    updateProfile,
  } = useAuth();
  const {createPost} = useWorkspace();

  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      status: 'active',
      org_id: current_organization,
      user_id: id,
      properties: profile?.properties ?? [],
      type: '',
      title: '',
      description: '',
      group_ids: [current_group],
      time_zone: null,
      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,
      location: null,
      media: [],
      inner_tags: [],
      outer_tags: [],
      collab_tags: [],
      asks: [],
      links: [],
    },
    validationSchema: Yup.object()
      .shape({
        title: Yup.string(),
        description: Yup.string(),
        group_ids: Yup.array().min(1, 'You must include a group.'),
        type: Yup.string().required('Type is required'),
      })
      .test(
        'title-or-description',
        'Either title or description is required',
        function (value) {
          const {title, description} = value;
          return title || description;
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {inner_tags, outer_tags, type, collab_tags, group_ids} = values;
        switch (type) {
          case 'request':
            values.outer_tags = values.outer_tags.concat(collab_tags);
            break;
          case 'resource':
            values.inner_tags = values.inner_tags.concat(collab_tags);
            break;
          default:
            values.inner_tags = values.inner_tags.concat(collab_tags);
            values.outer_tags = values.outer_tags.concat(collab_tags);
        }
        delete values.collab_tags;
        const {success, error, data} = await createPost(values);

        if (success) {
          const {id: post_id} = data;
          if (!profile?.post_created) {
            const update = {id, post_created: dateToString()};
            await updateProfile(update);
          }

          formik.resetForm();
          setFile(null);
          setActive(false);
          navigate(`/posts/share/${post_id}`);
          track('post_created', data);
          // const body = {
          //   id,
          //   post_id,
          //   type: 'post',
          //   inner_added: inner_tags || [],
          //   inner_removed: [],
          //   outer_added: outer_tags || [],
          //   outer_removed: [],
          //   group_ids,
          // };
          // manageOpportunities(body, post_id);

          update({
            customAttributes: {
              lastPostDate: new Date().toISOString(),
            },
          });
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <div className="padding-h8">
        <AddProjectModal
          setActive={setActive}
          formik={formik}
          file={file}
          setFile={setFile}
        />
      </div>
    </Modal>
  );
};

const AddProjectModal = ({setActive, formik, file}) => {
  const {
    state: {profile, groups, current_group},
  } = useAuth();

  const {group_ids, profile_image} = profile || {};

  const mapped = group_ids?.length
    ? group_ids.map(id => {
        const {name} = groups?.[id] ?? {};
        return {value: id, key: name};
      })
    : [];

  useEffect(() => {
    formik.setFieldValue('group_ids', [current_group]);

    formik.setFieldValue('type', 'request');
  }, [current_group]);

  const mobile = isMobile;

  return (
    <>
      <div className={mobile ? '' : ''}>
        <div className="flex align-start padding-bottom8 padding-h16">
          <ProfileImageSimple
            data={profile_image}
            style={{height: '60px', width: '60px'}}
          />
        </div>
        <div className="post-container">
          <PostTitleField
            formik={formik}
            placeholder="Title..."
            name="title"
            type="text"
            header=""
          />
          <br />
          <PostDescriptionField
            formik={formik}
            rows={10}
            header=""
            name="description"
            placeholder={'Description...'}
          />
        </div>
        <div className="justify-center padding-top8">
          <div
            className={`post-type-box grid-2 ${
              formik.values.type === 'request' ? 'selected-type-box' : ''
            }`}
            onClick={() => {
              formik.setFieldValue('type', 'request');
            }}>
            <p>REQUEST</p>
          </div>
          <span className="padding-8" />
          <div
            className={`post-type-box grid-2 ${
              formik.values.type === 'resource' ? 'selected-type-box' : ''
            }`}
            onClick={() => {
              formik.setFieldValue('type', 'resource');
            }}>
            <p>RESOURCE</p>
          </div>
          <span className="padding-8" />
          <div
            className={`post-type-box grid-2 ${
              formik.values.type === 'project' ? 'selected-type-box' : ''
            }`}
            onClick={() => {
              formik.setFieldValue('type', 'project');
            }}>
            <p>GENERAL</p>
          </div>
        </div>

        <p className="text-secondary text-left">Group</p>
        <FormikArraySearchableDropDown
          formik={formik}
          name="group_ids"
          placeholder={'Where do you want to post this?'}
          items={mapped}
        />
        <br />

        <div className="">
          {formik.values.type !== 'request' && (
            <div
              className={
                formik.values.type === 'project' ? 'grid-6' : 'grid-12'
              }>
              <FormikArraySearchableDropDown
                formik={formik}
                header="Resources"
                name="inner_tags"
                items={tagsJSON}
                mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
              />
            </div>
          )}
          {formik.values.type !== 'resource' && (
            <div
              className={
                formik.values.type === 'project' ? 'grid-6' : 'grid-12'
              }>
              <FormikArraySearchableDropDown
                formik={formik}
                header="Requests"
                name="outer_tags"
                items={tagsJSON}
                mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
              />
            </div>
          )}
          <br />
        </div>

        <Preview formik={formik} />
        {formik.errors.submit && (
          <p className="error">{formik.errors.submit}</p>
        )}
        <div className="padding-top8 border-bottom" />
        <div className="flex-row justify-center">
          <div />
          {formik.isSubmitting && <SpinningIndicator />}
          <ErrorButton
            onClick={() => {
              setActive(false);
            }}>
            Cancel
          </ErrorButton>
          <button
            type="submit"
            className={
              (!formik.values.title && !formik.values.description) ||
              formik.isSubmitting
                ? ''
                : 'create-opportunity-button'
            }
            disabled={
              (!formik.values.title && !formik.values.description) ||
              formik.isSubmitting
            }
            onClick={() => {
              if (file) {
                formik.setFieldValue('media', [file]);
              } else {
                formik.setFieldValue('media', []);
              }
              formik.handleSubmit();
            }}>
            POST
          </button>
        </div>
      </div>
    </>
  );
};

const Preview = ({formik}) => {
  const {type, asks, media, title, location} = formik.values;

  const pretty = formatEventDate(formik.values);

  switch (type) {
    case 'media':
      return (
        <div className="card-border">
          {media.map(file => {
            const {name, size, type} = file;
            return (
              <div className="grid-container">
                <div className="grid-4">
                  <h4>{name}</h4>
                  <p className="text-left">{type}</p>
                  <p className="text-left">
                    {Math.ceil(size / (1024 * 1024))}MB
                  </p>
                </div>
                <div className="grid-8">
                  <FileViewer
                    type={file.type}
                    url={URL.createObjectURL(file)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    case 'event':
      return (
        <div className="card-border">
          <div className="grid-container">
            <div className="grid-6">
              {title && <h4 className="text-left">{title}</h4>}
              <p className="text-left">{pretty}</p>
              {location && <p className="text-left">{location}</p>}
            </div>
            <div className="grid-6">
              {media.map(file => {
                return (
                  <FileViewer
                    type={file.type}
                    url={URL.createObjectURL(file)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    case 'ask':
      return (
        <div>
          {asks.map((ask, i) => {
            const {title, description, emoji} = ask;
            return (
              <div className="card-border">
                <div className="flex-row justify-between align-center">
                  <h4>
                    {emoji} {title}
                  </h4>
                  <AiOutlineCloseCircle
                    onClick={() => {
                      const removed = [...asks];
                      removed.splice(i, 1);
                      formik.setFieldValue('asks', removed);
                    }}
                  />
                </div>
                <p className="text-left">{description}</p>
              </div>
            );
          })}
        </div>
      );
    default:
      return null;
  }
};

const PostTitleField = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    type = 'text',
    autoComplete,
    ...rest
  } = props;

  return (
    <div className="form-field">
      {header && <label className="form-label">{header}</label>}
      <input
        className="form-input"
        {...rest}
        type={type}
        {...formik.getFieldProps(name)}
        onKeyDown={e => {
          if (onEnter && e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
        autoComplete={autoComplete}
      />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

const PostDescriptionField = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    autofocus,
    type = 'text',
    ...rest
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <div className="form-field">
      {header && <label className="form-label">{header}</label>}
      <textarea
        className="form-textarea"
        ref={inputRef}
        {...rest}
        {...formik.getFieldProps(name)}
        type={type}
        onKeyDown={e => {
          if (onEnter && e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
      />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export default MobileCreatePost;
