import React, {useCallback, useEffect, useState} from 'react';
import {
  RxBox,
  RxCalendar,
  RxCheckbox,
  RxChevronDown,
  RxChevronUp,
  RxCross2,
  RxLightningBolt,
} from 'react-icons/rx';
import {Tab} from '../../components/tab/tab';
import './AIChatCreation.css';
import './ThreadAIPopup.css';

export const IntegratedAIChatPopup = React.memo(({thread, onClose}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [mainSummary, setMainSummary] = useState('');
  const [topActions, setTopActions] = useState([]);
  const [dailySummaries, setDailySummaries] = useState([]);
  const [expandedDay, setExpandedDay] = useState(null);

  const chatContainerRef = React.useRef(null);
  const inputRef = React.useRef(null);

  useEffect(() => {
    // Simulating API fetch for ThreadAIPopup data
    setMainSummary(
      'This week, the team made significant progress on the new feature implementation and agreed on the project timeline. Key discussions revolved around API design and user interface improvements.',
    );
    setTopActions([
      {id: 1, task: 'Review pull request #123', completed: false},
      {id: 2, task: 'Schedule meeting with design team', completed: false},
      {id: 3, task: 'Update project documentation', completed: false},
    ]);
    setDailySummaries([
      {
        id: 1,
        date: '2023-08-15',
        summary:
          'Discussed new feature implementation details. Agreed on using GraphQL for API.',
        actions: ['Create GraphQL schema', 'Set up Apollo Server'],
      },
      {
        id: 2,
        date: '2023-08-14',
        summary:
          'Team agreed on project timeline. Set milestones for next quarter.',
        actions: ['Update Gantt chart', 'Inform stakeholders of new timeline'],
      },
    ]);
  }, [thread]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = useCallback(async () => {
    const trimmedMessage = inputMessage.trim();
    if (trimmedMessage) {
      setMessages(prevMessages => [
        ...prevMessages,
        {text: trimmedMessage, sender: 'user'},
      ]);
      setInputMessage('');
      setIsTyping(true);
      // Simulate AI response
      setTimeout(() => {
        setIsTyping(false);
        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: `This is a simulated AI response based on the thread: ${thread.title}`,
            sender: 'ai',
          },
        ]);
      }, 2000);
    }
  }, [inputMessage, thread]);

  const toggleAction = useCallback(id => {
    setTopActions(prevActions =>
      prevActions.map(action =>
        action.id === id ? {...action, completed: !action.completed} : action,
      ),
    );
  }, []);

  const tabItems = [{tab: 'Chat'}, {tab: 'Overview'}];
  if (thread.ai_assisted) {
    return (
      <div className="ai-chat">
        <div className="ai-chat-header">
          <div className="flex-row">
            <p className="text-secondary text-14 padding-right8">
              Thread Name:
            </p>

            <h3>{thread.title}</h3>
          </div>
          <div className="flex-row">
            <button className="ai-chat-close-button" onClick={onClose}>
              <RxCross2 />
            </button>
          </div>
        </div>
        <Tab items={tabItems} setIndex={setActiveTab} index={activeTab} />
        {activeTab === 0 ? (
          <>
            <ChatMessages
              messages={messages}
              isTyping={isTyping}
              chatContainerRef={chatContainerRef}
            />
            <>
              <ChatInput
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                handleSendMessage={handleSendMessage}
                inputRef={inputRef}
              />
              <ChatActionButtons
                handleSendMessage={handleSendMessage}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
              />
            </>
          </>
        ) : (
          <div className="thread-ai-popup-container">
            <section className="thread-ai-popup-main-summary">
              <h3>Summary</h3>
              <p>{mainSummary}</p>
            </section>
            <section className="thread-ai-popup-top-actions">
              <h4>
                <RxLightningBolt /> Your Actions
              </h4>
              {topActions.map(action => (
                <div key={action.id} className="thread-ai-popup-action-item">
                  <button
                    className={`action-checkbox ${
                      action.completed ? 'completed' : ''
                    }`}
                    onClick={() => toggleAction(action.id)}>
                    {action.completed ? <RxCheckbox /> : <RxBox />}
                  </button>
                  <span className={action.completed ? 'completed' : ''}>
                    {action.task}
                  </span>
                </div>
              ))}
            </section>
            <section className="thread-ai-popup-daily-summaries">
              <h4>
                <RxCalendar /> Daily Summaries
              </h4>
              {dailySummaries.map(day => (
                <div key={day.id} className="thread-ai-popup-day-item">
                  <div
                    className="thread-ai-popup-day-header"
                    onClick={() =>
                      setExpandedDay(prev => (prev === day.id ? null : day.id))
                    }>
                    <span>{day.date}</span>
                    {expandedDay === day.id ? (
                      <RxChevronUp />
                    ) : (
                      <RxChevronDown />
                    )}
                  </div>
                  {expandedDay === day.id && (
                    <div className="thread-ai-popup-day-content">
                      <p>{day.summary}</p>
                      <ul>
                        {day.actions.map((action, index) => (
                          <li key={index}>{action}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </section>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="ai-chat">
        <div className="ai-chat-header ">
          <div className="flex-row">
            <p className="text-secondary text-14 padding-right8">
              Thread Name:
            </p>
            <h3>{thread.title}</h3>
          </div>
          <div className="flex-row">
            <button className="ai-chat-close-button" onClick={onClose}>
              <RxCross2 />
            </button>
          </div>
        </div>

        <div className="thread-ai-popup-container ">
          <section className="thread-ai-popup-main-summary ">
            <h3>Assistant: Currently Unavailable</h3>
            <p className="">
              This space is reserved for your daily thread summary, powered by
              your assistant. When enabled, the assistant offers interactive
              features to enhance your experience:
            </p>
            <ul className="text-14">
              <li>
                Chat with Threads - e.g. "Who is the group is handling x?"
              </li>
              <li>
                Generated Summaries: Heres what happened since you were last
                here...
              </li>
              <li>Automatic Action Items: Never miss an next step</li>
              <li>View historical summaries of status updates</li>
            </ul>
            <p className="">
              To activate these tools and unlock a more dynamic thread
              interaction, please contact your administrator or the PublicMind
              team.
            </p>
          </section>
        </div>
      </div>
    );
  }
});

const ChatMessages = React.memo(({messages, isTyping, chatContainerRef}) => {
  return (
    <div className="ai-chat-container" ref={chatContainerRef}>
      {messages.map((message, index) => (
        <div key={index} className={`ai-chat-message ${message.sender}`}>
          <div>{message.text}</div>
        </div>
      ))}
      {isTyping && <div className="ai-chat-typing-indicator"></div>}
    </div>
  );
});

const ChatInput = React.memo(
  ({inputMessage, setInputMessage, handleSendMessage, inputRef}) => {
    return (
      <div className="ai-chat-input-container">
        <textarea
          ref={inputRef}
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder="Type your message..."
          rows={1}
        />
      </div>
    );
  },
);

const ChatActionButtons = React.memo(
  ({handleSendMessage, inputMessage, setInputMessage}) => {
    return (
      <div className="button-group">
        <div className="left-buttons">
          <button onClick={handleSendMessage} disabled={!inputMessage.trim()}>
            Send
          </button>
        </div>
        <div className="right-buttons">
          <button onClick={() => setInputMessage('')}>Clear</button>
        </div>
      </div>
    );
  },
);

export default IntegratedAIChatPopup;
