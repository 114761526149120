import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaBolt} from 'react-icons/fa6';
import {GoArrowSwitch} from 'react-icons/go';
import {RiCloseFill} from 'react-icons/ri';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {
  convertToDate,
  dateToTimestamp,
  formatWWW_DD_MMM_TTT,
  timeSince,
} from '../../api/dates';
import {
  ConnectButton,
  IgnoreButton,
  SkipButton,
} from '../../components/buttons/buttons';

import {sendConnectionAccepted} from '../../api/email';
import constants from '../../components/constants';
import {ProfileImage} from '../../components/inputs/uploader';
import {OppFeedbackModal} from '../../components/modal/feedback-modal';
import {ScheduleModal} from '../../components/modal/schedule-modal';
import Tooltip from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import {useMessage} from '../../hooks/use-message';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import {useWorkspace} from '../../hooks/use-workspace';

export const OpportunityCard = ({item, onClick}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {sortTags, membersToId} = useCollaborator();
  const {loadProfiles, loadOrgs} = useLoader();
  const {sortOpportunity} = useCollaborator();
  const {trackInteraction} = useTracker();
  const {
    state: {
      id,
      profiles,
      profile,
      current_group,
      organizations,
      current_organization,
    },
  } = useAuth();
  const {
    state: {new_opps, opp_token},
    handleOpportunity,
    getNewOpportunities,
    getPost,
  } = useWorkspace();
  const {createChain, sendMessage} = useMessage();

  const mobile = isMobile || isTablet;

  const [show, setShow] = useState(false);
  const [feedbackActive, setActive] = useState(false);

  const org = organizations?.[current_organization] ?? {};
  const {id: opp_id, data, tags, members, content} = item || {};
  const {user_status, other_status, user_content, other_content} =
    sortOpportunity(item);

  const other_person = profiles?.[other_content?.user_id] ?? {};
  const {first_name, organization_ids} = other_person;

  const active =
    user_status?.status === 'connect' && other_status?.status === 'connect';

  const last_update = timeSince(user_status?.updated);

  // CHECK FOR ACTION IN LINK
  useEffect(() => {
    // IF NO ITEM OR IF THE ACTION ALREADY TAKEN
    if (!item || !!user_status?.status) {
      return;
    }
    const status = searchParams.get('status');
    if (status) {
      if (status === 'ignore') {
        handleOpp(status);
      }
      if (status === 'connect') {
        handleConnect();
      }
    }
  }, [item]);

  // LOAD CONTENT
  useEffect(() => {
    if (other_content) {
      const {user_id, content_id, type} = other_content;
      if (type === 'user') {
        loadProfiles([user_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        getPost(content_id);
      }
    }
    if (user_content) {
      const {user_id, content_id, type} = user_content;
      if (type === 'user') {
        loadProfiles([content_id]);
      }
      if (type === 'post') {
        loadProfiles([user_id]);
        getPost(content_id);
      }
    }
  }, [other_content, user_content]);

  // LOAD ORGS
  useEffect(() => {
    if (other_content) {
      loadOrgs(organization_ids);
    }
  }, [profiles]);

  const handleConnect = async () => {
    // MAP THE NEW DATA
    const mapped_data = data.map(d => {
      const {user_id, status, notes, updated, email_timestamp} = d;
      if (user_id === id) {
        return {
          user_id,
          notes,
          status: 'connect',
          updated: dateToTimestamp(),
          email_timestamp,
        };
      }
      return {user_id, notes, status, updated, email_timestamp};
    });
    // UPDATE OPP
    const update = {
      id: opp_id,
      status: 'active',
      data: mapped_data,
    };
    const {success, error} = await handleOpportunity(update);
    if (success) {
      // IF CONNECTING, CREATE AN EMAIL CHAIN
      if (other_status?.status === 'connect') {
        // SEND ACCEPTED EMAIL
        const email = other_status.user_id;
        sendConnectionAccepted({email, profile, org, item});
        const id = membersToId(members);
        const chain = {
          id,
          type: 'direct',
          members,
          name: 'Direct',
          last_timestamp: dateToTimestamp(),
          last_message: null,
        };
        const {success, error, data: chain_data} = await createChain(chain);
        // SEND MESSAGES FROM OPP
        if (success || chain_data || error === 'Item already exists.') {
          await Promise.all(
            mapped_data
              .sort((a, b) => {
                return b.updated - a.updated;
              })
              .map(async status => {
                const {notes, user_id, updated} = status;
                const prof = profiles?.[user_id] ?? {};
                const {organization_ids} = prof;
                const sender_org = organization_ids?.[0] ?? null;
                // IF A MESSAGE SEND IT
                if (notes && notes.length) {
                  const message = {
                    id: v4(),
                    message_chain: id,
                    content: notes,
                    timestamp: updated || dateToTimestamp(),
                    sender_id: user_id,
                    sender_org,
                    read: null,
                    hyperlinks: [],
                  };
                  const {success, error} = await sendMessage(message);
                }
              }),
          );
          navigate('/messages', {state: {id}});
        } else {
          navigate('/messages');
        }
      }
    }
  };

  const handleOpp = async stat => {
    // MAP DATA
    const mapped_data = data.map(d => {
      const {user_id, notes, status, updated, email_timestamp} = d;
      if (user_id === id) {
        return {
          user_id,
          notes,
          status: stat,
          updated: dateToTimestamp(),
          email_timestamp,
        };
      }
      return {user_id, notes, status, updated, email_timestamp};
    });
    const update = {
      id: opp_id,
      status: stat === 'ignore' ? 'closed' : 'active',
      data: mapped_data,
    };
    // UPDATE OPP
    const {success, error} = await handleOpportunity(update);

    if (success) {
      if (onClick) {
        onClick();
      }
      // SHOW MODAL IF NEEDED
      if (other_status?.status) {
        setActive(stat);
      }
      // IF FEW TAGS LEFT PULL IN MORE
      if (new_opps.length <= 2) {
        const query = {
          filter: {
            status: {eq: 'inactive'},
            members: {contains: id},
            groups: {contains: current_group},
          },
          limit: constants.opportunity_limit,
          nextToken: opp_token,
        };
        await getNewOpportunities(query);
      }
      track('opportunity_interaction', {
        updated_status: stat,
        user_id: id,
        opportunity_id: opp_id,
        opportunity: item,
      });
      trackInteraction({
        target_user: other_content.user_id,
        content_id: opp_id,
        content_type: 'opportunity',
        action: stat,
      });
    }
  };

  useEffect(() => {
    const handleKeydown = async event => {
      if (event.key === 'ArrowLeft') {
        await handleOpp('ignore');
      } else if (event.key === 'ArrowRight') {
        setShow(!show);
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [item]);

  return (
    <div className={mobile ? 'card' : 'card-opportunity width-75 '}>
      <div className="grid-container">
        <div className="grid-12 padding-bottom16">
          <h3>You matched with...</h3>
          <p className="text-secondary"></p>
        </div>
        <div className="grid-5">
          <Content status={user_status} content={user_content} />
        </div>
        <div className="flex grid-1 justify-center align-center">
          <GoArrowSwitch />
        </div>
        <div className="grid-5">
          <Content status={other_status} content={other_content} />
        </div>
      </div>
      {!user_status?.status && (
        <div className="align-center justify-center flex-row">
          <div className="flex-column justify-center align-center">
            <Tooltip text={'Skip this opportunity (Left Arrow)'}>
              <SkipButton
                onClick={async () => {
                  await handleOpp('ignore');
                }}
                className="skip-opportunity-item">
                <RiCloseFill size={'40px'} />
              </SkipButton>
              <p className="text-center text-secondary text-12">(←) Skip</p>
            </Tooltip>
          </div>

          <div className="flex-column justify-center align-center">
            <Tooltip text={`Match with ${first_name || 'them'} (Right Arrow)`}>
              <ConnectButton
                onClick={async () => {
                  setShow(!show);
                }}
                className="create-opportunity-item">
                <FaBolt size={'40px'} />
              </ConnectButton>
              <p className="text-center text-secondary text-12">Match (→)</p>
            </Tooltip>
          </div>
        </div>
      )}
      {active && (
        <div className="flex justify-center">
          <button
            onClick={async () => {
              const update = {id: opp_id, status: 'completed'};
              await handleOpportunity(update);
              if (window.history.length <= 1) {
                navigate('/home');
              } else {
                navigate(-1);
              }
            }}>
            Mark Connection Complete
          </button>
        </div>
      )}
      {user_status?.status === 'connect' && !active && (
        <>
          <div className="flex-column justify-center">
            <IgnoreButton
              onClick={async () => {
                const mapped_data = data.map(d => {
                  const {user_id, notes, status, updated, email_timestamp} = d;
                  if (user_id === id) {
                    return {
                      user_id,
                      notes,
                      status: 'closed',
                      updated: dateToTimestamp(),
                      email_timestamp,
                    };
                  }
                  return {user_id, notes, status, updated, email_timestamp};
                });
                const update = {
                  id: opp_id,
                  status: 'closed',
                  data: mapped_data,
                };
                // UPDATE OPP
                const {success, error} = await handleOpportunity(update);

                await handleOpportunity(update);
                if (window.history.length <= 1) {
                  navigate('/home');
                } else {
                  navigate(-1);
                }
              }}>
              Cancel Request
            </IgnoreButton>
            <p className="text-secondary">Updated: {last_update}</p>
          </div>
        </>
      )}
      <ScheduleModal
        item={item}
        active={show}
        setActive={setShow}
        onClick={onClick}
      />
      <OppFeedbackModal
        item={item}
        active={feedbackActive}
        setActive={setActive}
      />
    </div>
  );
};

const Content = ({status, content}) => {
  const navigate = useNavigate();
  const {loadProfiles, loadPosts} = useLoader();
  const {prettyTag} = useStringFormatter();

  const {type, content_id, user_id} = content;

  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {
    state: {posts},
  } = useWorkspace();
  const tags = [...content?.inner_tags, ...content?.outer_tags] || [];
  const profile = profiles?.[user_id] ?? {};
  const mobile = isMobile || isTablet;

  useEffect(() => {
    if (type === 'user') {
      loadProfiles([content_id]);
    }
    if (type === 'post') {
      loadProfiles([user_id]);
      loadPosts([content_id]);
    }
  }, []);

  if (type === 'user') {
    return (
      <div
        className="bare-border clickable minHeigh20vh"
        onClick={() => {
          if (mobile) {
            return;
          }
          navigate(`/feed/profiles/detail/${content_id}`);
        }}>
        <Profile profile={profile} />
        <div className="border-bottom" />
        <Status status={status} />
        <div className="flex-row-wrap padding-top8">
          {tags.slice(0, 12).map(tag => {
            return (
              <p key={tag} className="bubble-small" onClick={() => {}}>
                {prettyTag(tag)}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  if (type === 'post') {
    const content = posts?.[content_id] ?? {};
    const {title, description} = content;

    return (
      <div
        className="bare-border clickable minHeigh20vh"
        onClick={() => {
          navigate(`/feed/posts/detail/${content_id}`);
        }}>
        <Profile profile={profile} />
        <div className="border-bottom" />
        <p className="text-left text-bold">{title}</p>
        <p className="text-left text-14 text-secondary">
          {description?.length > 144
            ? description.slice(0, 144) + '...'
            : description}
        </p>
        <div className="border-bottom" />
        <Status status={status} />
      </div>
    );
  }

  return null;
};

const Profile = ({profile}) => {
  const {loadOrgs} = useLoader();

  const {
    state: {id: cur_user_id, organizations},
  } = useAuth();

  const {
    id,
    first_name,
    last_name,
    position,
    profile_image,
    organization_ids,
    bio,
  } = profile;
  const org = organizations?.[organization_ids?.[0]] ?? {};
  const {name} = org;

  useEffect(() => {
    loadOrgs(organization_ids);
  }, []);

  return (
    <div>
      <div className="flex-row align-center">
        <ProfileImage
          data={profile_image}
          style={{height: '30px', width: '30px'}}
          onClick={() => {
            // if (mobile) {
            //   return;
            // }
            // navigate(`/feed/profiles/detail/${id}`);
          }}
        />
        <div className="padding-left8">
          <p className="text-left text-bold">
            {first_name} {last_name}
          </p>
          <p className="text-left text-secondary text-14">
            {name && position ? `${position}, ${name}` : position}
          </p>
        </div>
      </div>
      {cur_user_id !== id && (
        <p className="text-left text-14 text-secondary">
          {bio?.length > 144 ? bio.slice(0, 144) + '...' : bio}{' '}
        </p>
      )}
    </div>
  );
};

const Status = ({status}) => {
  const {status: stat, notes, updated} = status;

  const update_date = updated
    ? formatWWW_DD_MMM_TTT(convertToDate(updated * 1000))
    : null;

  return (
    <div>
      <p className="text-left text-14">{notes}</p>
      <p className="text-left text-12 text-secondary">{update_date}</p>
    </div>
  );
};
