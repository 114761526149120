import {useFormik} from 'formik';
import {useState} from 'react';
import {v4} from 'uuid';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {sendGroupInvite} from '../../api/email';
import {setInvite} from '../../api/graphql';
import {useAuth} from '../../hooks/use-auth';
import useSearchParameters from '../../hooks/use-search-parameters';
import useTracker from '../../hooks/use-tracker';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import constants from '../constants';
import EmailPreviewModal from '../email/email-preview';
import {PublicMindLogoLightNoText} from '../images/logos';
import {FormikCheckbox} from '../inputs/checkbox';
import {FormikTextfield, MultilineFormikTextfield} from '../inputs/textfields';
import {Modal} from './modal';

export const InviteGroupModal = ({active, setActive, group_id}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <InviteGroupModalContent
          active={active}
          setActive={setActive}
          group_id={group_id}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const InviteGroupModalContent = ({active, setActive, group_id, setPopup}) => {
  const {createReferralLink} = useSearchParameters();
  const {trackInteraction} = useTracker();

  const {
    state: {id, current_organization, profile, groups},
    getProf,
    inviteMember,
    updateReferral,
    updateGroup,
  } = useAuth();

  const group = groups?.[group_id] ?? {};

  const {name, type, members, admins} = group;

  const editable = type === 'private' && admins?.includes(id);
  const invitable = type === 'public' ? true : admins?.includes(id);
  let title = invitable
    ? `Invite to ${name || 'the group'}`
    : `${
        name || 'This'
      } is a secure group, so only administrators can invite new members.`;

  let subtitle = invitable
    ? 'This will send them an email stating that you vouched for them to join the group.'
    : 'Please contact the group owner to request an invitation.';

  if (!group?.name) {
    title = 'All Groups is not a group.';
    subtitle = 'Select a group on the left to invite new members.';
  }
  const handleInvite = async ({email, admin, helpers, note}) => {
    try {
      if (members?.includes(email)) {
        setPopup({
          on: true,
          message: 'Member already invited.',
        });
        return;
      }
      // CHECK IF CURRENT USER
      const {success, error, prof} = await getProf(email);

      // IF ACTIVE MEMBER SEND AN INVITE
      if (success) {
        const now = dateToTimestamp();
        const invite = {
          id: v4(),
          status: 'pending',
          admin,
          sender_id: id,
          invitee_id: email,
          group_id,
          created: now,
          updated: now,
        };
        await setInvite(invite);
        sendGroupInvite({
          email,
          profile,
          group,
          link: `${constants.root_url}/groups`,
          note,
        });
        setActive(false);
        setPopup({
          on: true,
          message: 'Email invite sent to: ' + email,
        });
        trackInteraction({
          target_user: email,
          content_id: email,
          content_type: 'user',
          action: 'group_invite',
        });
        track('group_invitation_sent', {email, group_id});
        helpers.resetForm();
      } else {
        // IF NEW AND INVITING SEND REFERRAL

        const base_user = {
          id: email,
          email,
          group_ids: [group_id],
          referral_user: id,
        };

        // CREATE USER AND LINK
        const link = createReferralLink(email, group_id);
        const {success, error} = await inviteMember(
          base_user,
          link,
          profile,
          group,
          note,
        );

        // IF SUCCESS
        if (success) {
          // UPDATE GROUP
          const mem = [...(members || [])];
          if (!mem.includes(email)) {
            mem.push(email);
          }

          const {success, error} = await updateGroup({
            id: group_id,
            updated: dateToTimestamp(),
            members: mem,
          });
          // MAKE A REFERRAL
          await updateReferral(
            {
              id: email,
              status: 'invited',
              referral_org: current_organization,
              referral_user: id,
              referral_group: group_id,
            },
            false,
          );
          setPopup({
            on: true,
            message: 'Email invite sent to: ' + email,
          });
          helpers.resetForm();
          trackInteraction({
            target_user: email,
            content_id: email,
            content_type: 'user',
            action: 'invite',
          });
          setActive(false);
          track('invitation_sent', {user: base_user});
        }

        if (error) {
          let pretty_error = error;
          setPopup({
            on: true,
            message: 'Error sending invite to: ' + email,
          });
          if (error === 'Item already exists.') {
            pretty_error = 'A user with this email already exists.';
          }

          helpers.setStatus({success: false});
          helpers.setErrors({submit: pretty_error});
          helpers.setSubmitting(false);
          return;
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      admin: false,
      note: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email, admin, note} = values;

        const trimmed = email?.toLowerCase()?.trim();
        await handleInvite({email: trimmed, admin, note, helpers});
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <div className="invite-modal-header flex-column align-center">
        <PublicMindLogoLightNoText />
        <h2 className="invite-modal-title padding-top8">{title}</h2>
        <p className="text-secondary">{subtitle}</p>
      </div>
      {invitable && (
        <>
          <div className="grid-container justify-center">
            <div className="grid-5 invite-modal-form-group">
              <FormikTextfield
                formik={formik}
                name="email"
                type="text"
                header="Email"
              />
            </div>
          </div>
          <div className="grid-container justify-center">
            <div className="grid-7 invite-modal-form-group">
              <MultilineFormikTextfield
                formik={formik}
                name="note"
                type="text"
                header="Note"
              />
            </div>
          </div>
        </>
      )}
      {editable && (
        <FormikCheckbox formik={formik} label="Invite as admin" name="admin" />
      )}
      {/* TODO add feedback on success */}
      {invitable && (
        <>
          <div className="flex justify-center">
            <button
              type="button"
              className="update-container-special-add"
              disabled={!invitable || formik.isSubmitting}
              onClick={formik.handleSubmit}>
              Send
            </button>
          </div>
          <EmailPreviewModal
            emailType={'sendInvite'}
            emailProps={{
              link: '',
              profile,
              group,
              note: formik?.values?.note ?? '',
            }}
          />
        </>
      )}
    </Modal>
  );
};
