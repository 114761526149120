import React, {useEffect, useState} from 'react';
import {FaBolt} from 'react-icons/fa6';
import {IoMdArrowBack} from 'react-icons/io';
import {useNavigate, useParams} from 'react-router-dom';
import {convertToDate, formatMMDDYYYY} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {TagsRadarChart} from '../../components/charts/radar-chart';
import constants from '../../components/constants';
import {ProfileImage} from '../../components/inputs/uploader';
import {OrganizationItemPreview} from '../../components/item-details/organization-item';
import {PostItem} from '../../components/item-details/post-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {SubscribeModal} from '../../components/modal/schedule-modal';
import {YourReferer} from '../../components/referral/yourReferer';
import {Tab} from '../../components/tab/tab';
import {
  DisplayField,
  ProfileTagDisplay,
} from '../../components/text/display-fields';
import {StatusIcon} from '../../components/widgets/widgets';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import {useNetworking} from '../../hooks/use-networking';
import useStringFormatter from '../../hooks/use-string-formatter';
import useTracker from '../../hooks/use-tracker';
import {useWorkspace} from '../../hooks/use-workspace';
import './styles.css';

const ProfileDetail = ({}) => {
  const {id} = useParams();
  const {loadProfiles, loadOrgs, loadRolodex} = useLoader();
  const navigate = useNavigate();
  const {trackInteraction} = useTracker();

  const {
    state: {id: current_id, profiles, current_group},
  } = useAuth();
  const {
    state: {user_posts, content_opps},
    getUserPosts,
  } = useWorkspace();

  const [loading, setLoading] = useState(false);

  const profile = profiles?.[id] ?? {};
  const {organization_ids, calendar_link} = profile;
  const [index, setIndex] = useState(0);

  const tabs = [
    {tab: 'Requests', index: 0},
    {tab: 'Resources', index: 1},
    // {tab: 'General', index: 2},
  ];

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (id) {
        await loadProfiles([id], {authMode: 'apiKey'});
      }

      const query = {
        status: 'active',
        sortDirection: 'DESC',
        filter: {user_id: {eq: id}, group_ids: {contains: current_group}},
      };

      if (!user_posts?.[id]?.length) {
        await getUserPosts(query, id);
      }
      setLoading(false);
    };

    load();
    loadRolodex();
  }, [id, current_group]);

  useEffect(() => {
    trackInteraction({
      target_user: id,
      content_id: id,
      content_type: 'user',
      action: 'view',
    });
  }, []);

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  if (!id || !profile?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="page-container">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <IoMdArrowBack
            className="clickable"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Header id={id} />
          <div className="margin-l16 padding-top8">
            <Tab items={tabs} index={index} setIndex={setIndex} />
          </div>
          {index === 0 && (
            <RequestProfileDetail id={id} profile={profile} loading={loading} />
          )}
          {index === 1 && (
            <ResourceProfileDetail
              id={id}
              profile={profile}
              loading={loading}
            />
          )}
          {index === 2 && (
            <ProjectProfileDetail id={id} profile={profile} loading={loading} />
          )}
        </>
      )}
    </div>
  );
};

export const RequestProfileDetail = ({
  id,
  profile,
  loading,
  isPublic = false,
}) => {
  const type = 'outer';
  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
              {/* <Posts id={id} type={'request'} isPublic={isPublic} /> */}
            </div>
            <div className="grid-5">
              <ConnectCard id={id} />

              <div className="card">
                <h4>Requests Breakdown</h4>
                <TagsRadarChart
                  tags={profile.outer_tags || []}
                  style={{height: '200px'}}
                />
              </div>
              <BasicInfo id={id} />
              {/* <Organizations id={id} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ResourceProfileDetail = ({
  id,
  profile,
  loading,
  isPublic = false,
}) => {
  const type = 'inner';

  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
              {/* <Posts id={id} type={'resource'} isPublic={isPublic} /> */}
            </div>
            <div className="grid-5">
              <ConnectCard id={id} />
              <div className="card">
                <h4>Resources Breakdown</h4>
                <TagsRadarChart
                  tags={profile.inner_tags || []}
                  style={{height: '200px'}}
                />
              </div>

              <BasicInfo id={id} />
              {/* <Organizations id={id} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ProjectProfileDetail = ({
  id,
  profile,
  loading,
  isPublic = false,
}) => {
  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Posts id={id} type={'project'} isPublic={isPublic} />
            </div>
            <div className="grid-5">
              <ConnectCard id={id} />
              <BasicInfo id={id} />
              {/* <Organizations id={id} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ConnectCard = ({id}) => {
  const {
    state: {id: current_id, profiles},
  } = useAuth();
  const {
    state: {content_opps},
    getOpportunitiesByContent,
  } = useWorkspace();

  const opps = content_opps?.[id];

  const profile = profiles?.[id] ?? {};
  const {calendar_link, first_name} = profile;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  // LOAD OPPS
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const query = {
        filter: {
          and: [
            {members: {contains: current_id}},
            {members: {contains: id}},
            {status: {eq: 'inactive'}},
          ],
        },
        limit: constants.all_items,
      };
      await getOpportunitiesByContent(query, id);
      setLoading(false);
    };
    if (id !== current_id && content_opps[id] === undefined) {
      load();
    }
  }, []);

  if (id === current_id) {
    return null;
  }

  return (
    <div>
      <div className="card">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <>
            {opps?.length ? (
              <div>
                <div className="flex-row align-center">
                  <span className="create-opportunity-item">
                    <FaBolt />
                  </span>
                  <h4>Recommended Opportunities</h4>
                </div>
                <div className="opp-container">
                  {opps?.map(opp_id => {
                    return (
                      <div className="padding-bottom8">
                        <SharedOpps key={opp_id} id={opp_id} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <h4>Stay up-to-date on my priorities and projects</h4>
                <div className="flex justify-center">
                  <button
                    className="create-opportunity-button"
                    onClick={() => {
                      setShow(!show);
                    }}>
                    Subscribe
                  </button>
                </div>
                {current_id && calendar_link ? (
                  <div className="flex-row justify-between align-center">
                    <p className="text-left text-bold">
                      User their personal link.
                    </p>
                    <a
                      href={calendar_link}
                      target="_blank"
                      rel="noopener noreferrer">
                      connect
                    </a>
                  </div>
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
      <SubscribeModal profile={profile} active={show} setActive={setShow} />
    </div>
  );
};

const SharedOpps = ({id}) => {
  const navigate = useNavigate();
  const {sortOpportunity} = useCollaborator();
  const {prettyTag} = useStringFormatter();

  const {
    state: {opportunities},
    getOpportunity,
  } = useWorkspace();

  const item = opportunities?.[id] ?? null;
  const {tags, status} = item || {};

  const {user_status, other_content, user_content, other_status} =
    sortOpportunity(item);

  useEffect(() => {
    if (!item && id) {
      getOpportunity(id);
    }
  }, [id]);

  if (status !== 'inactive') {
    return null;
  }
  return (
    <div
      className=" bare-border shared-opp-container"
      onClick={() => {
        navigate(`/opportunities/detail/${id}`);
      }}>
      <p className="text-secondary text-left">Opportunity Details</p>
      <div className="grid-container">
        {user_content?.type !== 'user' && (
          <div className="grid-10">
            <Content status={user_status} content={user_content} />
          </div>
        )}
        {other_content?.type !== 'user' && (
          <div className="grid-10">
            <Content status={other_status} content={other_content} />
          </div>
        )}
      </div>
      <div className="flex-row-wrap">
        {tags.slice(0, 12).map(tag => {
          return (
            <p key={tag} className="bubble-small" onClick={() => {}}>
              {prettyTag(tag)}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const Content = ({status, content}) => {
  const {loadProfiles, loadPosts} = useLoader();
  const {type, content_id, user_id} = content;
  const {status: stat} = status;

  const {
    state: {profiles},
  } = useAuth();
  const {
    state: {posts},
  } = useWorkspace();

  useEffect(() => {
    if (type === 'user') {
      loadProfiles([content_id]);
    }
    if (type === 'post') {
      loadProfiles([user_id]);
      loadPosts([content_id]);
    }
  }, []);

  const profile = profiles?.[user_id] ?? {};
  const {first_name, last_name, position} = profile;

  if (type === 'user') {
    return (
      <div>
        <p className="text-left text-bold">
          {first_name} {last_name}
        </p>
        <p className="text-left">{position}</p>
      </div>
    );
  }

  if (type === 'post') {
    const content = posts?.[content_id] ?? {};
    const {title, description} = content;

    return (
      <div>
        <p className="text-left text-bold text-2-line">{title}</p>
        <p className="text-left text-14 text-secondary text-3-line">
          {description}
        </p>
      </div>
    );
  }
  return null;
};

export const Header = ({id, isPublic = false}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {
    state: {id: curr_user, profiles, organizations},
  } = useAuth();
  const {
    state: {rolodex, rolodex_content},
    createEntry,
  } = useNetworking();

  const [popup, setPopup] = useState({on: false, message: ''});
  const [note, setNote] = useState(null);

  const profile = profiles?.[id] ?? {};

  const {
    organization_ids,
    position,
    status,
    signup_timestamp,
    email,
    first_name,
    last_name,
  } = profile;

  const active_since = signup_timestamp
    ? formatMMDDYYYY(convertToDate(signup_timestamp * 1000))
    : null;

  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  const pretty_name = prettyName(profile);

  useEffect(() => {
    const current = rolodex.find(rolo_id =>
      rolodex_content?.[rolo_id]?.emails?.includes(id),
    );
    setNote(current);
  }, [rolodex]);

  return (
    <div className="flex-row align-center justify-between margin-l16">
      <div className="flex-column">
        <div className="flex-row align-center">
          <ProfileImage data={profile.profile_image} />
          <div>
            {active_since && (
              <p className="text-left text-secondary margin-h16 text-14">
                Active since {active_since}
              </p>
            )}
            <div className="flex-row align-center margin-h16 ">
              <h2>{pretty_name}</h2>
              <StatusIcon status={status} />
            </div>
            {position && (
              <p className="text-left text-secondary margin-h16 text-14">
                {position}
              </p>
            )}
            {current_org && (
              <p
                className={`text-left text-secondary margin-h16 text-14 ${
                  !isPublic && ' clickable'
                }`}
                onClick={() => {
                  navigate(`/feed/organizations/detail/${current_org.id}`);
                }}>
                {current_org.name}
              </p>
            )}
            <YourReferer cur_id={id} />
            {/* {curr_user && id !== curr_user && !isPublic ? (
              note ? (
                <Tooltip text={`View your notes on ${first_name}`}>
                  <div
                    className="flex-column align-center justify-center margin-h16 clickable"
                    onClick={() => {
                      navigate(`/notes/${note}`);
                    }}>
                    <RxFileText size={'25px'} />
                    <p className="text-secondary text-10">Notes</p>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <Tooltip text={`View your notes on ${first_name}`}>
                    <div
                      className="flex-column align-center margin-h16 clickable"
                      onClick={async () => {
                        const now = dateToTimestamp();

                        const rolodex = {
                          id: v4(),
                          source_user: curr_user,
                          updated: now,
                          created: now,
                          first_name: first_name || null,
                          last_name: last_name || null,
                          emails: [email],
                          job_title: position || null,
                          company: null,
                          locations: [],
                          inner_tags: [],
                          outer_tags: [],
                          social_links: [],
                          notes: [],
                        };

                        await createEntry(rolodex);
                        track('rolodex_entry_created', {
                          user: curr_user,
                          rolodex,
                        });
                        navigate(`/notes/${rolodex.id}`);
                      }}>
                      <RxFilePlus size={'25px'} />
                    </div>
                    <p className="text-secondary text-10">Notes</p>
                  </Tooltip>
                </>
              )
            ) : null} */}
          </div>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

export const BasicInfo = ({id}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio} = profile;
  if (!bio) {
    return null;
  }

  return (
    <div className="card">
      <h4>About</h4>
      <DisplayField header="Bio" content={bio} />
      {/* <DisplayField header="Contact" content={user_id ? email : null} /> */}
    </div>
  );
};

export const Priorities = ({id, type}) => {
  const {
    state: {profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio, inner_tags, outer_tags} = profile;

  return (
    <div>
      <ProfileTagDisplay
        inner_tags={inner_tags}
        outer_tags={outer_tags}
        type={type}
      />
    </div>
  );
};

export const Posts = ({id, type, isPublic = false}) => {
  const {
    state: {user_posts, posts},
  } = useWorkspace();

  const org_content = user_posts?.[id] ?? [];

  const title =
    type === 'request'
      ? 'Specific Requests'
      : type === 'resource'
      ? 'Specific Resources'
      : 'General Asks';

  if (!org_content?.length) {
    return null;
  }

  return (
    <div className="card">
      <h4>{title}</h4>
      {org_content.map(id => {
        if (posts[id]?.type === type) {
          return (
            <PostItem
              key={id}
              item={posts[id]}
              inProfile={true}
              isPublic={isPublic}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
export const Resources = ({id}) => {
  const {
    state: {user_posts, posts},
  } = useWorkspace();

  const org_content = user_posts?.[id] ?? [];

  if (!org_content?.length) {
    return null;
  }

  return (
    <div>
      <div className="margin-h16">
        <h4>Current Projects</h4>
      </div>
      {org_content.map(id => {
        return <PostItem key={id} item={posts[id]} />;
      })}
    </div>
  );
};
export const Requests = ({id}) => {
  const {
    state: {user_posts, posts},
  } = useWorkspace();

  const org_content = user_posts?.[id] ?? [];

  if (!org_content?.length) {
    return null;
  }

  return (
    <div>
      <div className="margin-h16">
        <h4>Current Projects</h4>
      </div>
      {org_content.map(id => {
        return <PostItem key={id} item={posts[id]} />;
      })}
    </div>
  );
};
export const Organizations = ({id}) => {
  const {loadOrgs} = useLoader();
  const {
    state: {id: user, profiles, organizations},
  } = useAuth();

  const {organization_ids} = profiles?.[id] ?? {};

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  return (
    <div className="card">
      <h4>Organizations</h4>
      {organization_ids?.length
        ? organization_ids.map(id => {
            return (
              <OrganizationItemPreview
                key={id}
                disabled={!user}
                item={organizations[id] || {}}
              />
            );
          })
        : null}
    </div>
  );
};

export default ProfileDetail;
