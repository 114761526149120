import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import useTracker from '../../hooks/use-tracker';
import {SimpleTagDisplay} from '../text/display-fields';
import {StatusIcon} from '../widgets/widgets';
import './styles.css';

export const OrganizationItemPreview = ({item, disabled}) => {
  const navigate = useNavigate();

  const {id, name, status} = item;

  return (
    <div
      className="profile-container"
      onClick={() => {
        if (disabled) {
          return;
        }
        navigate(`/feed/organizations/detail/${id}`);
      }}>
      <div className="flex-row align-center text-bold">
        <p>{name}</p>
        <StatusIcon status={status} />
      </div>
    </div>
  );
};

export const JoinOrganizationItem = ({item}) => {
  const navigate = useNavigate();
  const {
    state: {id, profile},
    updateOrganization,
    updateProfile,
    defaultUpdate,
  } = useAuth();

  const {id: org_id, name, owner, members, invitations} = item;

  const connected = profile?.organization_ids?.includes(org_id) ?? false;

  const handleClaim = async () => {
    const mem_ids = [...(members || [])];
    if (!mem_ids.includes(id)) {
      mem_ids.push(id);
    }

    const org_ids = [...(profile?.organization_ids ?? [])];
    if (!org_ids.includes(org_id)) {
      org_ids.push(org_id);
    }

    const org_update = {
      id: org_id,
      members: mem_ids,
    };

    const profile_update = {
      id,
      organization_ids: org_ids,
    };
    await updateOrganization(org_update, true);
    await updateProfile(profile_update);
    defaultUpdate({current_organization: org_id});
    navigate('/create-organization');
  };

  const handleJoin = async () => {
    try {
      const org_update = {
        id: org_id,
        members: [...(members || []), id],
      };
      const profile_update = {
        id,
        organization_ids: [...(profile?.organization_ids ?? []), org_id],
      };
      await updateOrganization(org_update, true);
      await updateProfile(profile_update);
      navigate('/home');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="join-organization-container card">
      <p>{name}</p>
      {connected ? null : owner ? (
        <button onClick={handleJoin}>Join</button>
      ) : (
        <button onClick={handleClaim}>Claim</button>
      )}
    </div>
  );
};

export const DetailedOrganizationItem = ({item}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {trackPage} = useTracker();
  const {getOrgTags} = useCollaborator();

  const {
    state: {current_organization, id: user_id, profiles},
    logEvent,
  } = useAuth();

  const {id, name, status, members, owner} = item || {};
  const [inner, setInner] = useState([]);
  const [outer, setOuter] = useState([]);
  const [main, setMainPerson] = useState(null);

  useEffect(() => {
    trackPage(`search_${id}`, 'search');
  }, []);

  useEffect(() => {
    loadProfiles(members);
  }, [members]);

  useEffect(() => {
    let ownerProfile = profiles[owner];
    let mainPersonName = null;

    if (
      ownerProfile &&
      ownerProfile.first_name &&
      ownerProfile.last_name &&
      ownerProfile.position
    ) {
      mainPersonName =
        ownerProfile.first_name +
        ' ' +
        ownerProfile.last_name +
        ', ' +
        ownerProfile.position;
    }

    setMainPerson(mainPersonName || 'Automated Account');
    const {inner, outer} = getOrgTags(item);
    setInner(inner);
    setOuter(outer);
  }, [members, profiles]);

  if (!item) {
    return null;
  }

  return (
    <div
      className="base-item-container"
      onClick={() => {
        if (id === current_organization) {
          navigate('/settings/organization');
        } else {
          navigate(`/feed/organizations/detail/${id}`);
          logEvent({
            name: 'organization_clicked',
            source_id: current_organization,
            user_id,
            target_ids: [id],
          });
        }
      }}>
      <div className="flex-row align-center">
        <h5 className="text-left">{name}</h5>
        <StatusIcon status={status} />
      </div>
      <p className="text-secondary text-left padding-top8">{main}</p>
      <SimpleTagDisplay inner_tags={inner} outer_tags={outer} />
    </div>
  );
};
