import {v4} from 'uuid';
import {reset} from '../api/analytics';
import {
  authListener,
  changePassword,
  confirmForgotPassword,
  confirmUser,
  createAccount,
  getCurrentAccount,
  login,
  logout,
  sendForgotPasswordCode,
} from '../api/auth';
import {dateToTimestamp} from '../api/dates';
import {sendGroupInvite} from '../api/email';
import {
  fetchGrp,
  fetchInvites,
  fetchOrg,
  fetchProfile,
  fetchProfiles,
  fetchReferral,
  fetchReferrals,
  listGrps,
  listOrgs,
  setEvent,
  setGroup,
  setOrg,
  setProfile,
  setReferral,
  setWaitlist,
  updateGrp,
  updateInvite,
  updateOrg,
  updateRef,
  updateUserProfile,
} from '../api/graphql';
import {getFileURL, uploadFile} from '../api/s3';
import createDataContext from './create-data-context';

const authReducer = (state, action) => {
  const {
    id,
    group,
    org,
    prof,
    ids,
    orgs,
    profiles,
    groups,
    filter,
    nextToken,
    source,
    sources,
    source_id,
    query,
    set_current,
    referral,
    update,
  } = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'updateProfile':
      return {
        ...state,
        profile: {...state.profile, ...action.payload.update},
      };
    case 'updateGroup':
      const upda = {...state.groups[id], ...group};
      return {
        ...state,
        groups: {...state.groups, [id]: upda},
      };
    case 'setGroup':
      const updatedGroup = {...state.groups[id], ...group};
      return {
        ...state,
        groups: {...state.groups, [id]: updatedGroup},
        current_group: state.current_group ? state.current_group : id,
      };
    case 'setGroups':
      return {...state, groups: {...state.groups, ...groups}};
    case 'updateOrg':
      const upd = {
        ...state.organizations[id],
        ...org,
      };
      return {
        ...state,
        current_organization: set_current ? id : state.current_organization,
        organizations: {
          ...state.organizations,
          [id]: upd,
        },
      };
    case 'setOrg':
      const updatedOrg = {
        ...state.organizations[id],
        ...org,
      };
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [id]: updatedOrg,
        },
      };
    case 'setOrgs':
      return {...state, organizations: {...state.organizations, ...orgs}};
    case 'searchOrgs':
      return {
        ...state,
        organizations: {...state.organizations, ...orgs},
        org_filter: filter,
        org_search: ids,
        org_token: nextToken,
      };
    case 'paginateOrgs':
      return {
        ...state,
        organizations: {...state.organizations, ...orgs},
        org_search: [...state.org_search, ...ids],
        org_token: nextToken,
      };
    case 'setProf':
      const updatedProf = {...state.profiles[id], ...prof};
      return {...state, profiles: {...state.profiles, [id]: updatedProf}};
    case 'addSearch':
      return {...state, queries: [query, ...state.queries]};
    case 'paginateProfiles':
      return {
        ...state,
        profiles: {...state.profiles, ...profiles},
        profile_token: nextToken,
        profile_search: [...state.profile_search, ...ids],
        profile_filter: filter,
        profiles_loaded: true,
      };
    case 'searchProfiles':
      return {
        ...state,
        profiles: {...state.profiles, ...profiles},
        profile_token: nextToken,
        profile_search: ids,
        profile_filter: filter,
        profiles_loaded: true,
      };
    case 'paginateGroups':
      return {
        ...state,
        groups: {...state.groups, ...groups},
        group_token: nextToken,
        group_search: [...state.group_search, ...ids],
        group_filter: filter,
        groups_loaded: true,
      };
    case 'searchGroups':
      return {
        ...state,
        groups: {...state.groups, ...groups},
        group_token: nextToken,
        group_search: ids,
        group_filter: filter,
        groups_loaded: true,
      };
    case 'setReferral':
      return {...state, referrals: [referral, ...state.referrals]};
    case 'updateInvitation':
      const {status, id: group_id} = update;
      let mapped = [...state.invitations];

      if (status === 'pending') {
        mapped = mapped.map(item => {
          if (item.id === group_id) {
            return {...item, ...update};
          }
          return item;
        });
      } else {
        mapped = mapped.filter(item => item.id !== group_id);
      }
      return {...state, invitations: mapped};
    default:
      return state;
  }
};

const fetchFullProfile = async (id, options) => {
  try {
    const prof = await fetchProfile(id, options);
    if (prof) {
      const {profile_image} = prof;
      // FETCH THE PROFILE URL
      if (profile_image) {
        const {key} = profile_image;
        const {expiresAt, url} = await getFileURL(key);
        prof.profile_image = {...profile_image, url};
      }
      return prof;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
};

const fetchFullQueryProfile = async (id, options) => {
  try {
    const query = {filter: {sub: {contains: id}}, limit: 1000};
    const {items} = await fetchProfiles(query, options);
    if (items.length) {
      const prof = items[0];
      const {profile_image} = prof;
      // FETCH THE PROFILE URL
      if (profile_image) {
        const {key} = profile_image;
        const {expiresAt, url} = await getFileURL(key);
        prof.profile_image = {...profile_image, url};
      }
      return prof;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
};

const fetchFullOrg = async (id, options) => {
  try {
    const org = await fetchOrg(id, options);
    if (org) {
      const {profile_image} = org;
      // FETCH THE PROFILE URL
      if (profile_image) {
        const {key} = profile_image;
        const {expiresAt, url} = await getFileURL(key);
        org.profile_image = {...profile_image, url};
      }
      return org;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
};

const fetchFullGroup = async (id, options) => {
  try {
    const org = await fetchGrp(id, options);
    if (org) {
      const {image} = org;
      // FETCH THE PROFILE URL
      if (image) {
        const {key} = image;
        const {expiresAt, url} = await getFileURL(key);
        org.image = {...image, url};
      }
      return org;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
};

// FETCHES THE DATA ASSOCIATED WITH THE USER - PROFILE, ORGS, GROUPS
const fetchData = async (username, sub) => {
  try {
    const profile = await fetchFullProfile(username);

    if (!profile) {
      return null;
    }

    if (profile && !profile.sub) {
      await updateUserProfile({id: username, sub});
    }

    const organizations = {};
    const groups = {};
    let current_organization = null;
    let current_group = 'all';
    // IF PART OF AN ORGANIZATION - FETCH THEM
    if (profile?.organization_ids?.length) {
      current_organization = profile.organization_ids[0];
      await Promise.all(
        profile.organization_ids.map(async id => {
          const org = await fetchFullOrg(id);
          return (organizations[id] = org);
        }),
      );
    }

    if (profile?.group_ids?.length) {
      // current_group = profile.group_ids[0];
      await Promise.all(
        profile.group_ids.map(async id => {
          const group = await fetchFullGroup(id);
          return (groups[id] = group);
        }),
      );
    }
    return {
      profile,
      organizations,
      current_organization,
      groups,
      current_group,
    };
  } catch (err) {
    throw err;
  }
};

const defaultUpdate = dispatch => update => {
  try {
    dispatch({type: 'default', payload: update});
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const init = dispatch => async () => {
  try {
    dispatch({
      type: 'default',
      payload: {processing: true},
    });
    const current = await getCurrentAccount();

    const {username, userId} = current;

    const response = await fetchData(username, userId);
    if (!response) {
      dispatch({
        type: 'default',
        payload: {
          id: username,
          auth: current,
          processing: false,
        },
      });
      return {success: true};
    }
    const {
      profile,
      organizations,
      current_organization,
      groups,
      current_group,
    } = response;

    dispatch({
      type: 'default',
      payload: {
        id: username,
        auth: current,
        profile,
        organizations,
        current_organization,
        groups,
        current_group,
        processing: false,
      },
    });

    updateLastSeen(dispatch, {
      org_id: current_organization,
      user_id: username,
    });
    return {success: true};
  } catch (err) {
    dispatch({
      type: 'default',
      payload: {processing: false},
    });
    return handleErrors(err, dispatch, true);
  }
};

const listener = dispatch => async () => {
  try {
    const callback = async ({event, data, message}) => {
      console.log(event, data, message);
      switch (event) {
        case 'signInWithRedirect':
          // TODO:
          break;
        case 'signedIn':
          dispatch({
            type: 'default',
            payload: {processing: true},
          });
          const {username, userId} = data;
          const response = await fetchData(username, userId);
          if (!response) {
            dispatch({
              type: 'default',
              payload: {
                id: username,
                auth: data,
                processing: false,
              },
            });
            return;
          }
          const {
            profile,
            organizations,
            current_organization,
            groups,
            current_group,
          } = response;

          dispatch({
            type: 'default',
            payload: {
              id: username,
              auth: data,
              profile,
              organizations,
              current_organization,
              groups,
              current_group,
              processing: false,
            },
          });

          updateLastSeen(dispatch, {
            org_id: current_organization,
            user_id: username,
          });
          break;
        case 'signedOut':
          // RESET MIXPANEL ON LOG OUT
          reset();
          dispatch({
            type: 'default',
            payload: defaultValues,
          });
          break;
        case 'signIn_failure':
          const {name, code} = data;
          if (name === 'UserNotConfirmedException') {
          }
          break;
        default:
          return;
      }
    };
    authListener(callback);
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const signup = dispatch => async (email, password, referral) => {
  try {
    const {referral_org, referral_user, referral_group} = referral || {};
    const attributes = {
      email,
    };

    const {isSignUpComplete, userId, nextStep} = await createAccount(
      email,
      password,
      attributes,
    );

    const profile = {
      email,
      id: email,
      status: 'signup',
    };

    // IF REFERRED BY A GROUP, UPDATE THE PROFILE
    if (referral_group) {
      // FETCH THE PROFILE
      const user = await fetchProfile(email, {authMode: 'apiKey'});

      // APPEND THE GROUP
      if (user) {
        const {group_ids} = user;
        if (!group_ids?.includes(referral_group)) {
          group_ids.push(referral_group);
        }
        profile.group_ids = group_ids;
      } else {
        profile.group_ids = [referral_group];
      }
    }

    // UPDATE PROFILE IN DB
    const update = await updateOrCreateProfile(profile);
    const payload = {update};
    dispatch({type: 'updateProfile', payload});
    dispatch({
      type: 'default',
      payload: {processing: true},
    });
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const signin = dispatch => async (email, password) => {
  try {
    const {isSignedIn, nextStep} = await login(email, password);
    const profile = await fetchProfile(email);

    dispatch({
      type: 'default',
      payload: {processing: true},
    });

    return {
      success: true,
      error: null,
      isSignedIn,
      nextStep,
      profile,
    };
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const addWaitlist = dispatch => async waitlist => {
  try {
    await setWaitlist(waitlist);

    return {
      success: true,
      error: null,
    };
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateOrCreateOrganization = async org => {
  try {
    await updateOrg(org);
    return org;
  } catch (err) {
    try {
      const default_org = {
        id: v4(),
        name: 'N/A',
        query_name: 'N/A',
        status: 'unactivated',
        type: null,
        specific_type: null,
        website: null,
        address: null,
        bio: null,
        members: [],
        profile_image: null,
        last_seen: dateToTimestamp(),
        children: [],
        parent: null,
        ...org,
      };

      await setOrg(default_org);
      return default_org;
    } catch (err) {
      throw err;
    }
  }
};

const updateOrCreateProfile = async profile => {
  try {
    await updateUserProfile(profile, {authMode: 'apiKey'});

    return profile;
  } catch (err) {
    try {
      const default_user = {
        id: v4(),
        email: null,
        sub: null,
        first_name: null,
        last_name: null,
        phone: null,
        position: null,
        bio: null,
        profile_image: null,
        status: 'unactivated',
        organization_ids: [],
        group_ids: [],
        outer_tags: [],
        inner_tags: [],
        social_links: [],
        referral_user: null,
        referral_org: null,
        post_created: null,
        expiration: null,
        subscription: null,
        last_seen: dateToTimestamp(),
        ...profile,
      };

      await setProfile(default_user, {authMode: 'apiKey'});

      return default_user;
    } catch (err) {
      throw err;
    }
  }
};

const confirmAccount = dispatch => async (email, password, code) => {
  try {
    // CONFIRM WITH AWS
    const {isSignUpComplete, nextStep} = await confirmUser(email, code);
    if (isSignUpComplete) {
      // SIGN IN SO CAN ACCESS DYNAMO
      const signup_timestamp = dateToTimestamp();
      const profile = {
        email,
        id: email,
        status: 'confirmed',
        signup_timestamp,
      };

      // UPDATE PROFILE IN DB
      const update = await updateOrCreateProfile(profile);
      const payload = {update};
      dispatch({type: 'updateProfile', payload});
      if (password) {
        try {
          const {isSignedIn, nextStep} = await login(email, password);
          return {success: true, error: null, nextStep: '/introduce-yourself'};
        } catch (error) {
          return {success: true, error: null, nextStep: '/signin'};
        }
      } else {
        return {success: true, error: null, nextStep: '/signin'};
      }
    } else {
      return {success: false, error: null};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

// UPDATE DYNAMO ATTRIBUTES
const updateProfile = dispatch => async update => {
  try {
    const {id, profile_image} = update;

    // IF UPDATING THE PROFILE IMAGE
    if (profile_image) {
      // UPLOAD TO S3
      const {name, type, size} = profile_image;
      const key = `${id}/${name}`;
      const response = await uploadFile(key, profile_image, {
        accessLevel: 'public',
        contentType: type,
        onProgress: ({transferredBytes, totalBytes}) => {},
      });

      const profile = await updateOrCreateProfile({
        ...update,
        profile_image: {key, type, size},
      });

      const payload = {
        update: {
          ...profile,
          profile_image: {
            key,
            type,
            size,
            url: URL.createObjectURL(profile_image),
          },
        },
      };
      dispatch({type: 'updateProfile', payload});
      return {success: true, error: null, data: {profile}};
    } else {
      // DEFAULT UPDATE
      const profile = await updateOrCreateProfile(update);

      const payload = {update: profile};
      dispatch({type: 'updateProfile', payload});
      return {success: true, error: null, data: {profile}};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateOrganization = dispatch => async (update, set_current) => {
  try {
    const {id, profile_image} = update;
    if (profile_image) {
      // UPLOAD TO S3
      const {name, type, size} = profile_image;
      const key = `${id}/${name}`;
      const response = await uploadFile(key, profile_image, {
        accessLevel: 'public',
        contentType: type,
        onProgress: ({transferredBytes, totalBytes}) => {},
      });

      const org = await updateOrCreateOrganization({
        ...update,
        profile_image: {key, type, size},
      });
      const payload = {
        org: {
          ...org,
          profile_image: {
            key,
            type,
            size,
            url: URL.createObjectURL(profile_image),
          },
        },
        id,
        set_current,
      };
      dispatch({type: 'updateOrg', payload});
      return {success: true, error: null, data: {org}};
    } else {
      const org = await updateOrCreateOrganization(update);
      const payload = {org, id, set_current};
      dispatch({type: 'updateOrg', payload});
      return {success: true, error: null, data: {org}};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

// HELPER FUNCTION THAT TRACKS APP USAGE
const updateLastSeen = async (dispatch, updates) => {
  try {
    const {org_id, user_id} = updates;
    const last_seen = dateToTimestamp();
    // HANDLE ORG
    if (org_id) {
      const update = {id: org_id, last_seen};
      await updateOrg(update);
      const payload = {org: update, id: org_id};
      dispatch({type: 'setOrg', payload});
    }
    // HANDLE USER
    if (user_id) {
      const update = {id: user_id, last_seen};
      await updateUserProfile(update);
      const payload = {update};
      dispatch({type: 'updateProfile', payload});
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const inviteMember = dispatch => async (member, link, profile, group, note) => {
  try {
    const {email} = member;
    const {first_name, last_name, other_email} = profile;

    // ID MUST BE PASSED IN
    const base_user = {
      email: null,
      sub: null,
      first_name: null,
      last_name: null,
      phone: null,
      position: null,
      bio: null,
      profile_image: null,
      status: 'unactivated',
      organization_ids: [],
      group_ids: [],
      outer_tags: [],
      inner_tags: [],
      social_links: [],
      referral_user: null,
      referral_org: null,
      post_created: null,
      expiration: null,
      subscription: null,
      last_seen: null,
      ...member,
    };
    await setProfile(base_user);

    const {success, error} = await sendGroupInvite({
      email,
      profile,
      group,
      link,
      note,
    });

    return {success, error};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const createGroup = dispatch => async group => {
  try {
    const {id, image} = group;
    if (image) {
      // UPLOAD TO S3
      const {name, type, size} = image;
      const key = `${id}/${name}`;
      const response = await uploadFile(key, image, {
        accessLevel: 'public',
        contentType: type,
        onProgress: ({transferredBytes, totalBytes}) => {},
      });

      await setGroup({
        ...group,
        image: {key, type, size},
      });

      const payload = {
        id,
        group: {
          ...group,
          image: {
            key,
            type,
            size,
            url: URL.createObjectURL(image),
          },
        },
      };

      dispatch({type: 'setGroup', payload});
    } else {
      await setGroup(group);
      const payload = {id, group};
      dispatch({type: 'setGroup', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateGroup = dispatch => async update => {
  try {
    const {id, image} = update;
    if (image) {
      // UPLOAD TO S3
      const {name, type, size} = image;
      const key = `${id}/${name}`;
      const response = await uploadFile(key, image, {
        accessLevel: 'public',
        contentType: type,
        onProgress: ({transferredBytes, totalBytes}) => {},
      });

      await updateGrp({
        ...update,
        image: {key, type, size},
      });

      const payload = {
        id,
        group: {
          ...update,
          image: {
            key,
            type,
            size,
            url: URL.createObjectURL(image),
          },
        },
      };
      dispatch({type: 'updateGroup', payload});
      return {success: true, error: null};
    } else {
      await updateGrp(update);
      const payload = {group: update, id};
      dispatch({type: 'updateGroup', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getGroup = dispatch => async id => {
  try {
    const group = await fetchGrp(id);
    if (group) {
      const payload = {id, group};
      dispatch({type: 'setGroup', payload});
      return {success: true, error: null, group};
    } else {
      const error = 'Group does not exist.';
      const payload = {error};
      dispatch({type: 'default', payload});
      return {success: false, error};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getOrg = dispatch => async (id, options) => {
  try {
    const org = await fetchFullOrg(id, options);
    if (org) {
      const payload = {id, org};
      dispatch({type: 'setOrg', payload});
      return {success: true, error: null, org};
    } else {
      const error = 'Organization does not exist.';
      const payload = {error};
      dispatch({type: 'default', payload});
      return {success: false, error};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getProf = dispatch => async (id, options) => {
  try {
    const prof = await fetchFullProfile(id, options);
    if (prof) {
      const payload = {id, prof};
      dispatch({type: 'setProf', payload});
      return {success: true, error: null, prof};
    } else {
      const error = 'Profile does not exist.';
      const payload = {error};
      dispatch({type: 'default', payload});
      return {success: false, error};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const queryProfile = dispatch => async (id, options) => {
  try {
    const prof = await fetchFullQueryProfile(id, options);
    if (prof) {
      const payload = {id: prof.id, prof};
      dispatch({type: 'setProf', payload});
      return {success: true, error: null, prof};
    } else {
      const error = 'Profile does not exist.';
      const payload = {error};
      dispatch({type: 'default', payload});
      return {success: false, error};
    }
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const searchOrgs = dispatch => async (filter_obj, options, paginate) => {
  try {
    // RESET ON NEW SEARCHES
    if (!paginate) {
      dispatch({type: 'default', payload: {org_search: []}});
    }

    const filter = objectToFilter(filter_obj);
    const {limit} = options || {};
    const {items, nextToken} = await listOrgs({...options, filter});

    let all_items = [...items];
    let token = nextToken;
    let i = 0;

    while (all_items.length < limit && token && i < 100) {
      // TODO: pass in results as they come
      const {items, nextToken} = await listOrgs({
        limit,
        nextToken: token,
        filter,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const orgs = {};
    const ids = all_items.map(org => {
      const {id} = org;
      orgs[id] = org;
      return id;
    });

    const payload = {ids, orgs, filter: filter_obj, nextToken: token};

    if (paginate) {
      dispatch({type: 'paginateOrgs', payload});
    } else {
      dispatch({type: 'searchOrgs', payload});
    }

    return {success: true, error: null, data: {ids, nextToken: token}};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const searchProfiles = dispatch => async (filter_obj, options) => {
  try {
    const {limit, nextToken} = options || {};
    if (!nextToken) {
      dispatch({
        type: 'default',
        payload: {profile_search: [], profile_filter: filter_obj},
      });
    } else {
      dispatch({type: 'default', payload: {profile_filter: filter_obj}});
    }

    const filter = profileToFilter(filter_obj);

    const {items, nextToken: first_token} = await fetchProfiles({
      ...options,
      filter,
    });

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    while (all_items.length < limit && token && i < 100) {
      // TODO: pass in results as they come
      const {items, nextToken} = await fetchProfiles({
        limit,
        nextToken: token,
        filter,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const profiles = {};
    const ids = await Promise.all(
      all_items.map(async profile => {
        const {id, profile_image} = profile;

        // FETCH THE PROFILE URL
        if (profile_image) {
          const {key} = profile_image;
          const {expiresAt, url} = await getFileURL(key);
          profile.profile_image = {...profile_image, url};
        }
        profiles[id] = profile;
        return id;
      }),
    );

    const payload = {ids, profiles, filter: filter_obj, nextToken: token};

    if (nextToken) {
      dispatch({type: 'paginateProfiles', payload});
    } else {
      dispatch({type: 'searchProfiles', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const searchGroups = dispatch => async (filter_obj, options) => {
  try {
    const {limit, nextToken} = options || {};
    if (!nextToken) {
      dispatch({
        type: 'default',
        payload: {group_search: [], group_filter: filter_obj},
      });
    } else {
      dispatch({type: 'default', payload: {group_filter: filter_obj}});
    }

    const filter = groupToFilter(filter_obj);

    const {items, nextToken: first_token} = await listGrps({
      ...options,
      filter,
    });

    let all_items = [...items];
    let token = first_token;
    let i = 0;

    while (all_items.length < limit && token && i < 100) {
      const {items, nextToken} = await listGrps({
        limit,
        nextToken: token,
        filter,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    const groups = {};
    const ids = await Promise.all(
      all_items.map(async profile => {
        const {id, image} = profile;

        // FETCH THE PROFILE URL
        if (image) {
          const {key} = image;
          const {expiresAt, url} = await getFileURL(key);
          profile.image = {...image, url};
        }
        groups[id] = profile;
        return id;
      }),
    );

    const payload = {ids, groups, filter: filter_obj, nextToken: token};

    if (nextToken) {
      dispatch({type: 'paginateGroups', payload});
    } else {
      dispatch({type: 'searchGroups', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateOrCreateReferral = async referral => {
  try {
    await updateRef(referral, {authMode: 'apiKey'});

    return referral;
  } catch (err) {
    try {
      const default_user = {
        id: null,
        status: null,
        referral_org: null,
        referral_user: null,
        ...referral,
      };

      await setReferral(default_user, {authMode: 'apiKey'});

      return default_user;
    } catch (err) {
      throw err;
    }
  }
};

const updateReferral = dispatch => async (ref, own) => {
  try {
    const {id, status, referral_org, referral_user} = ref;
    const referral = {
      id,
      status,
      referral_org,
      referral_user,
      timestamp: dateToTimestamp(),
    };
    await updateOrCreateReferral(referral);
    if (own) {
      // IF OWN REFERRAL SET AS SUCH
      const payload = {referral};
      dispatch({type: 'default', payload});
    } else {
      // IF REFERRING OTHERS ADD TO LIST
      const payload = {id, referral};
      dispatch({type: 'setReferral', payload});
    }
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getReferral = dispatch => async id => {
  try {
    const referral = await fetchReferral(id);
    const payload = {referral};
    dispatch({type: 'default', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getReferrals = dispatch => async params => {
  try {
    const {items: referrals, nextToken} = await fetchReferrals(params);

    const payload = {referrals};
    dispatch({
      type: 'default',
      payload,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const getInvitations = dispatch => async params => {
  try {
    const {items: invitations, nextToken} = await fetchInvites(params);

    const payload = {invitations, invitations_loaded: true};
    dispatch({
      type: 'default',
      payload,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateInvitation = dispatch => async update => {
  try {
    const {id} = update;
    await updateInvite(update);

    const payload = {id, update};
    dispatch({
      type: 'updateInvitation',
      payload,
    });
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const forgotPassword = dispatch => async username => {
  try {
    await sendForgotPasswordCode(username);
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updatePassword = dispatch => async (old_password, new_password) => {
  try {
    await changePassword(old_password, new_password);
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const confirmForgottenPassword = dispatch => async data => {
  try {
    const {email, code, password} = data;
    await confirmForgotPassword(email, code, password);
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const signOut = dispatch => async () => {
  try {
    await logout();
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const logEvent = dispatch => async event => {
  try {
    const id = v4();
    const timestamp = dateToTimestamp();
    await setEvent({id, timestamp, ...event});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const default_org_filter = {
  type: '',
  query_name: '',
  status: '',
};

const default_profile_filter = {
  email: '',
  first_name: '',
  last_name: '',
  status: '',
  inner_tags: [],
  outer_tags: [],
};

const default_group_filter = {
  name: '',
  bio: '',
};

const defaultValues = {
  processing: true,
  id: null,
  auth: null,
  profile: null,
  profiles: {},
  organizations: {},
  groups: {},
  current_organization: null,
  current_group: null,
  invitations: [],
  invitations_loaded: false,
  referrals: [],
  org_filter: default_org_filter,
  org_search: [],
  org_token: null,
  orgs_loaded: false,
  profile_filter: default_profile_filter,
  profile_search: [],
  profile_token: null,
  profiles_loaded: false,
  group_filter: default_group_filter,
  group_search: [],
  group_token: null,
  groups_loaded: false,
  error: null,
  referral: null,
};

const funcs = {
  addWaitlist,
  confirmAccount,
  confirmForgottenPassword,
  createGroup,
  defaultUpdate,
  forgotPassword,
  getGroup,
  getInvitations,
  getOrg,
  getProf,
  getReferral,
  getReferrals,
  init,
  inviteMember,
  listener,
  logEvent,
  queryProfile,
  searchGroups,
  searchProfiles,
  searchOrgs,
  signOut,
  signin,
  signup,
  updateGroup,
  updateInvitation,
  updateOrganization,
  updatePassword,
  updateProfile,
  updateReferral,
};

export const {Provider, Context} = createDataContext(
  authReducer,
  funcs,
  defaultValues,
);

const handleErrors = (err, dispatch, hidden) => {
  const {data, errors, message, name} = err;
  if (!hidden) {
    // console.log('AUTH ERROR', name, message);
    console.log('AUTH ERROR', err);
    // console.log('Error keys:', Object.keys(err));
    // console.log('Error details:', JSON.stringify(err, null, 2));
  }
  let error = 'Something went wrong.';

  if (name) {
    switch (name) {
      case 'NotAuthorizedException':
      case 'UsernameExistsException':
      case 'UserNotFoundException':
      case 'userAlreadyAuthenticatedException':
      case 'CodeMismatchException':
        error = err.message;
        break;
      default:
        break;
    }
  }

  const top_error = errors && errors[0];
  if (top_error) {
    switch (top_error.errorType) {
      case 'DynamoDB:ConditionalCheckFailedException':
        error = 'Item already exists.';
        break;
      default:
        break;
    }
  }

  dispatch({
    type: 'default',
    payload: {error},
  });
  return {success: false, error};
};

const objectToFilter = obj => {
  const filter = {and: []};
  const fields = Object.keys(obj);
  fields.forEach(field => {
    const values = obj[field];

    // IGNORE NULL
    if (!values) {
      return;
    }

    switch (field) {
      case 'query_name':
        filter.and.push({[field]: {contains: values.toUpperCase()}});
        break;
      case 'type':
      case 'status':
        filter.and.push({[field]: {eq: values}});
        break;
      default:
        return;
    }
  });
  return filter;
};

const profileToFilter = obj => {
  const and = [];
  const or = [];
  const fields = Object.keys(obj);
  fields.forEach(field => {
    const values = obj[field];

    // IGNORE NULL
    if (!values) {
      return;
    }

    switch (field) {
      case 'email':
      case 'first_name':
      case 'last_name':
        and.push({[field]: {contains: values}});
        break;
      case 'status':
        and.push({[field]: {eq: values}});
        break;
      case 'tags':
      case 'collaborators':
      case 'opportunities':
      case 'skills':
      case 'degrees':
      case 'majors':
        values.forEach(val => {
          and.push({tags: {contains: val}});
        });
        break;
      case 'inner_tags':
      case 'outer_tags':
        values.forEach(val => {
          and.push({[field]: {contains: val}});
        });
        break;
      case 'group_ids':
        and.push({[field]: {contains: values}});
        break;
      // case 'properties':
      //   values.forEach(val => {
      //     or.push({[field]: {contains: val}});
      //   });
      //   break;
      default:
        return;
    }
  });

  const filter = {};
  if (and.length) {
    filter.and = and;
  }
  if (or.length) {
    filter.or = or;
  }
  return filter;
};

const groupToFilter = obj => {
  const filter = {and: []};
  const fields = Object.keys(obj);
  fields.forEach(field => {
    const values = obj[field];

    // IGNORE NULL
    if (!values) {
      return;
    }

    switch (field) {
      case 'name':
      case 'bio':
        filter.and.push({[field]: {contains: values}});
        break;
      default:
        return;
    }
  });
  return filter;
};
