import {useFormik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import {sendEmail} from '../../api/email';
import negativeFeedbackTemplate from '../../assets/html/negative-opp-feedback';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useWorkspace} from '../../hooks/use-workspace';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {ErrorButton} from '../buttons/buttons';
import {MultilineFormikTextfield} from '../inputs/textfields';
import {Modal} from './modal';

export const OppFeedbackModal = ({item, active, setActive}) => {
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  return (
    <>
      {active ? (
        <OppFeedbackModalContent
          item={item}
          active={active}
          setActive={setActive}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const OppFeedbackModalContent = ({item, active, setActive, setPopup}) => {
  const {sortOpportunity} = useCollaborator();
  const {
    state: {id, profile},
  } = useAuth();
  const {updateOpportunity} = useWorkspace();

  const {id: opp_id, members, content, data} = item || {};

  const {user_status, other_status, user_content, other_content} =
    sortOpportunity(item);

  const formik = useFormik({
    initialValues: {
      notes: '',
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {notes} = values;
        // IF NO NOTES IGNORE
        if (!notes || !notes.length) {
          return;
        }
        // MAP THE NEW DATA
        const mapped_data = data.map(d => {
          const {
            user_id,
            status,
            notes: cur_note,
            updated,
            email_timestamp,
          } = d;
          if (user_id === id) {
            return {
              user_id,
              notes,
              status,
              updated: dateToTimestamp(),
              email_timestamp,
            };
          }
          return {user_id, notes: cur_note, status, updated, email_timestamp};
        });
        // UPDATE OPP
        const update = {
          id: opp_id,
          data: mapped_data,
        };
        const {success, error} = await updateOpportunity(update);
        if (success) {
          setActive(null);
          setPopup({on: true, message: 'Success!'});
          // SEND EMAILS
          const email = other_content.user_id;
          const html = negativeFeedbackTemplate({
            status: active,
            notes,
            profile,
          });
          if (active === 'ignore') {
            const msg = {
              to: email,
              subject: 'Connection Request Denied',
              text: 'Connection Request Denied',
              html: html,
            };
            await sendEmail(msg);
          }
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!item) {
    return null;
  }

  return (
    <Modal active={active} setActive={setActive} disabled_dismiss={true}>
      <div>
        <h4>Additional Feedback</h4>
        <p className="text-left text-secondary text-14">
          Add any notes that affected your choice.
        </p>
      </div>
      <MultilineFormikTextfield
        formik={formik}
        placeholder=""
        name="notes"
        header="Feedback"
      />
      <div className="flex-row justify-center">
        <ErrorButton
          onClick={() => {
            setActive(null);
          }}>
          Skip
        </ErrorButton>
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};
