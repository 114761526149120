import {useCallback, useState} from 'react';
import {RxCopy, RxPencil1, RxShare1, RxTrash} from 'react-icons/rx';
import {EmojiInput} from '../../components/inputs/emoji-input';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';

export const ContentActions = ({item, onQuote, setEdit, edit, onReply}) => {
  const {id, content, parent, owner_id, reactions} = item;
  const {deleteThreadContent, updateContent} = useThreads();
  const {
    state: {id: user_id, profiles},
  } = useAuth();

  const user = profiles?.[owner_id] ?? {};
  const [showEmoji, setShowEmoji] = useState(false);

  const {first_name, last_name, position} = user;

  const owner = owner_id === user_id;

  const {copyString} = useUtilities();

  const handleCopy = useCallback(() => {
    copyString(content);
  }, [content, copyString]);

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this reply?')) {
      console.log('Delete content:', item.id);
      deleteThreadContent({id: item.id});
    }
  };
  const handleShare = () => {
    console.log('Share content:', item.id);
  };

  return (
    <div className="content-actions">
      {!parent && (
        <button className="action-button" type="submit" onClick={onReply}>
          Reply
        </button>
      )}
      {parent && (
        <button
          className="action-button"
          type="submit"
          onClick={() =>
            onQuote(
              item.parent,
              `@[${first_name} ${last_name}](/feed/profiles/detail/${owner_id}) `,
            )
          }>
          Reply
        </button>
      )}

      <EmojiInput
        setEmoji={async emoji => {
          const cleaned = [...(reactions || [])].filter(
            reaction => reaction.user_id !== user_id,
          );
          cleaned.push({reaction: emoji, user_id});
          await updateContent({id, reactions: cleaned});
        }}
        setShowPicker={setShowEmoji}
        showPicker={showEmoji}
      />
      <button className="action-button" onClick={handleShare}>
        <RxShare1 />
      </button>
      <button className="action-button" onClick={handleCopy}>
        <RxCopy />
      </button>

      {owner && (
        <>
          <button className="action-button" onClick={() => setEdit(!edit)}>
            <RxPencil1 />
          </button>
          <button className="action-button" onClick={handleDelete}>
            <RxTrash />
          </button>
        </>
      )}
    </div>
  );
};
