import React from 'react';
import {RxMagicWand, RxMagnifyingGlass} from 'react-icons/rx';
import {VscMention} from 'react-icons/vsc';
import './ThreadButtons.css';

const ThreadButtons = ({index, setIndex, setIsAIChatOpen, isAIChatOpen}) => {
  return (
    <div className="thread-buttons-container">
      <div className="inset-button-group" data-active={index}>
        <button
          className={`inset-button ${index === 'mentions' ? 'active' : ''}`}
          onClick={() => setIndex('mentions')}
          aria-label="Mentions">
          <VscMention />
        </button>
        <button
          className={`inset-button ${index === 'search' ? 'active' : ''}`}
          onClick={() => setIndex('search')}
          aria-label="Search">
          <RxMagnifyingGlass />
        </button>
        <div className="inset-button-slider"></div>
      </div>
      <button
        className={`ai-button ${isAIChatOpen ? 'active' : ''}`}
        onClick={() => {
          setIsAIChatOpen(!isAIChatOpen);
        }}
        aria-label="Assistant">
        <RxMagicWand />
      </button>
    </div>
  );
};

export default ThreadButtons;
