import tagsJSON from '../assets/schemas/tags-schema.json';
import constants from '../components/constants';
import {useAuth} from './use-auth';
import {useWorkspace} from './use-workspace';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const {
    state: {id, current_group, profiles, profile},
  } = useAuth();
  const {
    state: {
      active_opps,
      direct_opps,
      closed_opps,
      opportunities,
      interacted_loaded,
    },
    getTypedOpportunities,
  } = useWorkspace();

  const membersToId = members => {
    const ids = members.sort().join('_');
    return ids;
  };

  const membersToOther = collab => {
    if (!collab) {
      return {name: null, other: null};
    }
    const {type, name, members} = collab;

    if (id && type === 'direct') {
      const other = members.find(user_id => user_id !== id);
      if (other) {
        const other_org = profiles[other];
        if (other_org) {
          const {first_name, last_name, email} = other_org;
          const pretty =
            first_name || last_name
              ? `${first_name || ''} ${last_name || ''}`
              : email;
          return {name: pretty, other};
        }
        return {name: other, other};
      }
      return {name, other: null};
    }
    return {name, other: null};
  };

  const sortOpportunity = opp => {
    if (!opp) {
      return {
        user_status: null,
        other_status: null,
        user_content: null,
        other_content: null,
      };
    }
    const {data, content} = opp;

    const user_status = data?.find(stat => stat.user_id === id) ?? null;
    const other_status = data?.find(stat => stat.user_id !== id) ?? null;
    const user_content = content?.find(cont => cont.user_id === id);
    const other_content = content?.find(cont => cont.user_id !== id);

    return {user_status, other_status, user_content, other_content};
  };

  const sortOpportunities = opps => {
    if (!opps || !opps?.length) {
      return [];
    }

    return opps.sort((a_id, b_id) => {
      const opp_a = opportunities[a_id];
      const opp_b = opportunities[b_id];
      const res_a = sortOpportunity(opp_a);
      const res_b = sortOpportunity(opp_b);
      const a_val = Math.max(
        res_a.user_status.updated || 0,
        res_a.other_status.updated || 0,
      );
      const b_val = Math.max(
        res_b.user_status.updated || 0,
        res_b.other_status.updated || 0,
      );
      return b_val - a_val;
    });
  };

  const sortTags = (inner, outer) => {
    const {inner_tags, outer_tags} = profile || {};
    // if (!inner?.length || !outer?.length) {
    //   return {similar: [], excluded: [], sorted: []};
    // }

    // if (!inner_tags?.length || !outer_tags?.length) {
    //   return {similar: [], excluded: array, sorted: array};
    // }

    const inner_similar = [];
    const inner_excluded = [];
    const outer_similar = [];
    const outer_excluded = [];
    inner?.forEach(tag => {
      if (outer_tags?.includes(tag) && !inner_similar?.includes(tag)) {
        inner_similar.push(tag);
      } else {
        if (!inner_excluded?.includes(tag)) {
          inner_excluded.push(tag);
        }
      }
    });

    outer?.forEach(tag => {
      if (inner_tags?.includes(tag) && !outer_similar?.includes(tag)) {
        outer_similar.push(tag);
      } else {
        if (!outer_excluded?.includes(tag)) {
          outer_excluded.push(tag);
        }
      }
    });

    return {
      inner_similar,
      inner_excluded,
      inner_sorted: [...inner_similar, ...inner_excluded],
      outer_similar,
      outer_excluded,
      outer_sorted: [...outer_similar, ...outer_excluded],
    };
  };

  const compareUpdatedTags = (inner, outer) => {
    const {inner_tags, outer_tags} = profile;

    const {added: inner_added, removed: inner_removed} = tagDifferences(
      inner_tags,
      inner,
    );
    const {added: outer_added, removed: outer_removed} = tagDifferences(
      outer_tags,
      outer,
    );

    return {inner_added, inner_removed, outer_added, outer_removed};
  };

  const tagDifferences = (start, end) => {
    try {
      const added = [];
      const removed = [];
      // Find removed items
      start.forEach(tag => {
        if (!end.includes(tag)) {
          removed.push(tag);
        }
      });

      // Find added items
      end.forEach(tag => {
        if (!start.includes(tag)) {
          added.push(tag);
        }
      });
      return {added, removed};
    } catch (err) {
      return {added: [], removed: []};
    }
  };

  const tagToObject = tag => {
    const value = tagsJSON[tag];

    if (value) {
      return value;
    }
    return null;
  };

  const findUpdatedToday = () => {
    // Get today's date at 00:00 hours.
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    // Convert startOfDay to Unix timestamp (in seconds)
    const startOfDayUnix = Math.floor(startOfDay.getTime() / 1000);

    // Filter objects that have any 'data' updated today.
    return Object.entries(opportunities)
      .filter(([key, obj]) => {
        return obj.data.some(dataItem => {
          // Check if 'updated' exists and is from today
          return dataItem.updated && dataItem.updated >= startOfDayUnix;
        });
      })
      .map(([key, obj]) => obj); // Return the objects, not the entries.
  };

  const loadOpps = async () => {
    const query = {
      filter: {
        status: {ne: 'inactive'},
        members: {contains: id},
        groups: {contains: current_group},
      },
      limit: constants.all_items,
    };
    if (!interacted_loaded) {
      await getTypedOpportunities(query);
    }
  };

  const categorizeOpps = () => {
    const defaults = {
      incoming: {
        internal: {
          count: 0,
          ids: [],
        },
        external: {
          count: 0,
          ids: [],
        },
      },
      pending: {
        internal: {
          count: 0,
          ids: [],
        },
        external: {
          count: 0,
          ids: [],
        },
      },
      active: {
        ignore: {
          count: 0,
          ids: [],
        },
        connect: {
          count: 0,
          ids: [],
        },
        later: {
          count: 0,
          ids: [],
        },
      },
    };

    // SORT ACTIVE OPPS
    active_opps.forEach(opp_id => {
      const {user_status, other_status} = sortOpportunity(
        opportunities[opp_id],
      );

      const incoming = other_status?.status ?? null;
      const outgoing = user_status?.status ?? null;

      // IF BOTH
      if (incoming && outgoing) {
        if (incoming === 'ignore' || outgoing === 'ignore') {
          defaults.active.ignore.count += 1;
          defaults.active.ignore.ids.push(opp_id);
        } else if (incoming === 'connect' && outgoing === 'connect') {
          defaults.active.connect.count += 1;
          defaults.active.connect.ids.push(opp_id);
        } else {
          defaults.active.later.count += 1;
          defaults.active.later.ids.push(opp_id);
        }
      } else if (outgoing) {
        // PENDING
        if (outgoing !== 'connect') {
          return;
        }
        defaults.pending.internal.count += 1;
        defaults.pending.internal.ids.push(opp_id);
      } else if (incoming) {
        // INCOMING
        if (incoming !== 'connect') {
          return;
        }
        defaults.incoming.internal.count += 1;
        defaults.incoming.internal.ids.push(opp_id);
      }
    });

    // SORT CLOSED OPPS
    direct_opps.forEach(opp_id => {
      const {user_status, other_status} = sortOpportunity(
        opportunities[opp_id],
      );

      const incoming = other_status?.status ?? null;
      const outgoing = user_status?.status ?? null;

      // IF BOTH
      if (incoming && outgoing) {
        if (incoming === 'ignore' || outgoing === 'ignore') {
          defaults.active.ignore.count += 1;
          defaults.active.ignore.ids.push(opp_id);
        } else if (incoming === 'connect' && outgoing === 'connect') {
          defaults.active.connect.count += 1;
          defaults.active.connect.ids.push(opp_id);
        } else {
          defaults.active.later.count += 1;
          defaults.active.later.ids.push(opp_id);
        }
      } else if (outgoing) {
        // PENDING
        if (outgoing !== 'connect') {
          return;
        }
        defaults.pending.external.count += 1;
        defaults.pending.external.ids.push(opp_id);
      } else if (incoming) {
        // INCOMING
        if (incoming !== 'connect') {
          return;
        }
        defaults.incoming.external.count += 1;
        defaults.incoming.external.ids.push(opp_id);
      }
    });

    defaults.incoming.internal.ids = sortOpportunities(
      defaults.incoming.internal.ids,
    );
    defaults.incoming.external.ids = sortOpportunities(
      defaults.incoming.external.ids,
    );

    return defaults;
  };

  const getOrgTags = org => {
    try {
      const {members} = org;

      const sum = {};
      const inner = [];
      const outer = [];
      if (!members?.length) {
        return {inner: [], outer: []};
      }
      members.forEach(id => {
        const profile = profiles?.[id] ?? {};
        const {inner_tags, outer_tags} = profile;
        if (inner_tags?.length) {
          inner_tags.forEach(tag => {
            if (!inner.includes(tag)) {
              inner.push(tag);
            }
            if (sum[tag] === undefined) {
              sum[tag] = {tag, count: 0};
            }
            sum[tag].count += 1;
          });
        }
        if (outer_tags?.length) {
          outer_tags.forEach(tag => {
            if (!outer.includes(tag)) {
              outer.push(tag);
            }
            if (sum[tag] === undefined) {
              sum[tag] = {tag, count: 0};
            }
            sum[tag].count += 1;
          });
        }
      });
      return {inner, outer};
    } catch (err) {
      return {inner: [], outer: []};
    }
  };

  return {
    membersToId,
    membersToOther,
    sortOpportunity,
    sortOpportunities,
    sortTags,
    tagToObject,
    compareUpdatedTags,
    tagDifferences,
    findUpdatedToday,
    loadOpps,
    categorizeOpps,
    getOrgTags,
  };
};
