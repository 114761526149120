import React from 'react';
import {RxEnter} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useWorkspace} from '../../hooks/use-workspace';
import './styles.css';

export const GroupItem = ({item, index, clickable}) => {
  const navigate = useNavigate();
  const {capitalize} = useStringFormatter();
  const {id, name, bio, members, tags, image, type} = item;
  const {
    state: {id: user_id, profile, current_group},
    updateProfile,
    defaultUpdate,
  } = useAuth();
  const {defaultUpdate: update} = useWorkspace();
  const {group_ids} = profile || {};
  const initial = group_ids?.[0] === id;
  const joinable = !members?.includes(user_id);

  const prettyTypes = type => {
    switch (type) {
      case 'public':
        return 'Private';
      default:
        return capitalize(type);
    }
  };

  const handleSetDefault = async e => {
    e.stopPropagation();
    const grps = [...(group_ids || [])];
    const [removedItem] = grps.splice(index, 1);
    grps.unshift(removedItem);
    await updateProfile({id: user_id, group_ids: grps});
    track('default_group_updated', {group_id: id});
    const active = current_group === id;
    if (!active) {
      defaultUpdate({
        current_group: id,
        profiles_loaded: false,
        profile_token: null,
        orgs_loaded: false,
        org_token: null,
      });
      update({
        posts_loaded: false,
        post_token: null,
        feed_loaded: false,
        feed_token: null,
        new_opps_loaded: false,
        opp_token: null,
        feed: [],
        post_feed: [],
        user_posts: {},
      });
      track('current_group_updated', {group_id: id});
    }
  };

  return (
    <div
      className="group-item"
      onClick={() => clickable && navigate(`/groups/detail/${id}`)}>
      {image?.url && (
        <div
          className="group-item__image"
          style={{backgroundImage: `url(${image.url})`}}></div>
      )}
      <div className="group-item__content">
        <h3 className="group-item__name">{name}</h3>
        <p className="group-item__bio">{bio}</p>
        <div className="group-item__info">
          <span>Members: {members?.length ?? 0}</span>
          <span>Group Type: {prettyTypes(type || 'public')}</span>
        </div>
        <div className="group-item__tags">
          {tags.slice(0, 12).map(tag => (
            <span key={tag} className="group-item__tag">
              {tag}
            </span>
          ))}
        </div>
      </div>
      {clickable && (
        <button
          className="group-item__details"
          onClick={() => navigate(`/groups/detail/${id}`)}>
          <RxEnter size={'15px'} />
        </button>
      )}
      {!!index && !initial && (
        <button className="group-item__set-default" onClick={handleSetDefault}>
          Set Group as Default
        </button>
      )}
      {/* {joinable && (
        <div>
          <button
            className="group-item__details"
            onClick={() => {
              // TODO:
            }}>
            Request to Join
          </button>
        </div>
      )} */}
    </div>
  );
};
