import React, {useEffect} from 'react';
import {Mention, MentionsInput} from 'react-mentions';
import constants from '../../components/constants';
import {MultipleFileUploader} from '../../components/inputs/uploader';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import './ChatInput.css';

const ChatInput = React.memo(
  ({
    formik,
    thread_id,
    inputRef,
    isExpanded,
    setIsExpanded,
    showFileUploader,
    files,
    setFiles,
  }) => {
    const {prettyName} = useStringFormatter();
    const {
      state: {threads},
    } = useThreads();
    const {
      state: {profiles},
    } = useAuth();
    const {subscribers} = threads?.[thread_id] ?? {};

    const handleKeyDown = e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        formik.handleSubmit();
      }
    };

    const handleMention = (id, display) => {
      const {mentions} = formik.values;

      if (!mentions) {
        return;
      }
      if (!mentions?.includes(id)) {
        formik.setFieldValue('mentions', [...mentions, id]);
      }
    };

    useEffect(() => {
      setIsExpanded(false);
    }, [setIsExpanded, thread_id]);

    return (
      <div
        className="reply-popup-container"
        onClick={() => setIsExpanded(true)}>
        <MentionsInput
          ref={inputRef}
          value={formik.values.content}
          onChange={(event, newValue, newPlainTextValue, mentions) => {
            formik.setFieldValue('content', newValue);
          }}
          onKeyDown={handleKeyDown}
          placeholder={
            isExpanded ? 'Type your reply...' : 'Click to add a comment...'
          }
          rows={isExpanded ? 5 : 1}
          style={{height: isExpanded ? '128px' : '28px'}}
          className="mentions-input">
          <Mention
            trigger="@"
            data={subscribers?.map(email => {
              const profile = profiles?.[email];
              return {id: email, display: prettyName(profile)};
            })}
            appendSpaceOnAdd={true}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused,
            ) => (
              <div
                className={`mentions__suggestions__item ${
                  focused ? 'mentions__suggestions__item--focused' : ''
                }`}>
                <span className="mentions__suggestions__item__display">
                  {suggestion?.display}
                </span>
                <span className="mentions__suggestions__item__id">
                  {suggestion?.id?.split('@')?.[0]}
                </span>
              </div>
            )}
            onAdd={handleMention}
            markup="@[__display__](/feed/profiles/detail/__id__)"
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        {showFileUploader && (
          <MultipleFileUploader
            files={files}
            setFiles={setFiles}
            types={[
              ...constants.image_mime_types,
              ...constants.video_mime_types,
              ...constants.document_mime_types,
              ...constants.compressed_mime_types,
              ...constants.audio_mime_types,
            ]}
            limit={constants.file_20mb}
          />
        )}
      </div>
    );
  },
);

export default ChatInput;
