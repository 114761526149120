import {useNavigate} from 'react-router-dom';
import {track} from '../api/analytics';
import constants from '../components/constants';
import {useAuth} from './use-auth';
import useCollaborator from './use-collaborator';
import {useWorkspace} from './use-workspace';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const navigate = useNavigate();
  const {tagToObject} = useCollaborator();
  const {searchProfiles} = useAuth();
  const {searchPosts} = useWorkspace();

  const easySearch = (type, inner_tags, outer_tags) => {
    const user_options = {limit: constants.org_search_limit};
    const post_options = {
      status: 'active',
      sortDirection: 'DESC',
      limit: 10,
      nextToken: null,
    };

    switch (type) {
      case 'post':
        const post_filter = {
          description: '',
          inner_tags,
          outer_tags,
        };
        searchPosts(post_filter, post_options);
        track('search', {
          source: 'posts',
          filter: post_filter,
          options: post_options,
          quick: true,
        });
        navigate('/feed', {state: {start_index: 0}});
        break;
      case 'user':
        const user_filter = {
          email: '',
          first_name: '',
          last_name: '',
          status: '',
          inner_tags,
          outer_tags,
        };

        searchProfiles(user_filter, user_options);
        track('search', {
          source: 'profile',
          filter: user_filter,
          options: user_options,
          quick: true,
        });
        navigate('/feed', {state: {start_index: 1}});
        break;
      case 'organization':
      default:
        return;
    }
  };

  return {easySearch};
};
