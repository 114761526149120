import React from 'react';
import {IoIosCheckmarkCircle} from 'react-icons/io';
import {TooltipNoShadow} from '../tooltip/tooltip';

import {
  RxComponent1,
  RxComponentBoolean,
  RxComponentPlaceholder,
} from 'react-icons/rx';
import {useAuth} from '../../hooks/use-auth';
import {Label} from '../item-details/labels';
import './styles.css';

export const StatusIcon = ({status}) => {
  switch (status) {
    case 'complete':
      return (
        <TooltipNoShadow text={'Verified Member'}>
          <div className="margin-h8">
            <RxComponent1 size={'20px'} color="#4743c5" />
          </div>
        </TooltipNoShadow>
      );
    case 'unactivated':
    case 'unauthenticated':
      return (
        <TooltipNoShadow text={'Inactive'}>
          <div className="margin-h8">
            <RxComponentPlaceholder size={'20px'} opacity={0.2} />
          </div>
        </TooltipNoShadow>
      );
    case 'in_progress':
    case 'confirmed':
    default:
      return (
        <TooltipNoShadow text={'Pending Member'}>
          <div className="margin-h8">
            <RxComponentBoolean size={'20px'} opacity={0.5} />
          </div>
        </TooltipNoShadow>
      );
  }
};

export const StatusIconNoMargin = ({status}) => {
  if (status === 'complete') {
    return <IoIosCheckmarkCircle color="blue" />;
  }

  return null;
};

export const GroupsIcon = ({group_ids, isPublic, inProfile}) => {
  const {
    state: {groups},
  } = useAuth();

  return (
    <div className="flex-row-wrap">
      {group_ids.map(group_id => {
        const {name} = groups?.[group_id] ?? {};

        if (!isPublic && !inProfile) {
          return <Label label={name} color={'#4657c0'} key={group_id} />;
        }
        return null;
      })}
    </div>
  );
};
