import constants from '../../components/constants';

const sendDirectMessageHTML = props => {
  const {message, profile, link} = props || {};
  const {content} = message || {};
  const {first_name, last_name, email} = profile || {};

  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <br/>
        <div style="text-align: center;">
          <img src="${constants.publicmind_logo}" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 0 auto; max-width: 600px; background-color: #f4f4f4; padding: 20px; border-radius: 8px;">
          <h3 style="text-align: center;">New Direct Message from ${pretty_name}</h3>
          <br />
          <p style="font-weight: 500; text-align: center; line-height: 1.6; color: black;">
            ${content}
          </p>
          <br />
                    <br />

          <div style="text-align: center;">
            <a href="${link}" style="background-color: #000000; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
              Open Message
            </a>
          </div>
          <br />
        </div>
        <br />
        <p style="text-align: center;">Sent from PublicMind</p>
        <br />
        <div style="text-align: center;">
          <a href="${constants.help_center}" style="margin: 0 8px; color: #75757a; text-decoration: none;">Help Center</a>
          <a href="${constants.privacy_policy}" style="margin: 0 8px; color: #75757a; text-decoration: none;">Privacy Policy</a>
          <a href="${constants.terms_of_service}" style="margin: 0 8px; color: #75757a; text-decoration: none;">Terms of Service</a>
        </div>
      </body>
    </html>
  `;
};

export default sendDirectMessageHTML;
