import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {RxCross2, RxEnterFullScreen, RxExitFullScreen} from 'react-icons/rx';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import {sendPriorityThreadContent} from '../../api/email';
import {SimpleDisplayModal} from '../../components/modal/modal';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import ChatActionButtons from './chat-action-buttons';
import ChatInput from './chat-input';
import './ReplyToComment.css';

const ReplyToComment = ({id, parent, onClose, isOpen, quoteText}) => {
  const {
    state: {id: user_id, profile},
  } = useAuth();
  const {
    state: {threads, thread_subs},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const {copyString} = useUtilities();
  const [isFullScreen, setIsFullScreen] = useState(false); // New state for full-screen mode
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      mentions: [],
      media: [],
      scheduled: null,
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {status, scheduled, mentions} = values;
        const now = dateToTimestamp();
        const content = {
          id: v4(),
          thread_id: id,
          parent,
          ...values,
          created: now,
          updated: now,
        };

        if (files?.length) {
          content.media = files;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          helpers.resetForm();
          setFiles([]);
          onClose();
          if (mentions?.length) {
            await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              threadTitle: threads?.[id].title,
            });
          }
          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          // TODO: Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isOpen && inputRef.current) {
      setIsExpanded(true);
      if (quoteText) {
        const emailRegex = /@\[[^\]]+\]\(\/feed\/profiles\/detail\/([^)]+)\)/;
        const match = quoteText.match(emailRegex);
        if (match) {
          const email = match[1];
          formik.setFieldValue('mentions', [...formik.values.mentions, email]);
        }
        formik.setFieldValue('content', `${quoteText}\n\n`);
      }
    }
  }, [isOpen, quoteText]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const extractNameFromMention = mentionText => {
    const match = mentionText.match(/@\[(.*?)\]/);
    return match ? match[1] : 'Thread';
  };
  const replyContent = (
    <div
      className={`${
        isFullScreen ? 'modal-reply-thread' : 'content-reply-popup'
      } ${isExpanded ? 'expanded' : ''}`}>
      <div className="popup-header">
        <h3>
          Reply to {quoteText ? extractNameFromMention(quoteText) : 'Thread'}
        </h3>{' '}
        <div className="flex-row">
          <button className="expand-button" onClick={toggleFullScreen}>
            {isFullScreen ? <RxExitFullScreen /> : <RxEnterFullScreen />}
          </button>
          {!isFullScreen && (
            <button className="close-button" onClick={onClose}>
              <RxCross2 />
            </button>
          )}
        </div>
      </div>
      <div className="popup-content">
        <ChatInput
          thread_id={id}
          formik={formik}
          inputRef={inputRef}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          quoteText={quoteText}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
        />
      </div>
      <ChatActionButtons
        formik={formik}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
      />
    </div>
  );

  return (
    <>
      {!isFullScreen && replyContent}
      <SimpleDisplayModal active={isFullScreen} setActive={setIsFullScreen}>
        {replyContent}
      </SimpleDisplayModal>
    </>
  );
};

export const EditCommentReply = ({comment, onClose, isOpen}) => {
  const {updateContent} = useThreads();

  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const {copyString} = useUtilities();
  const [isFullScreen, setIsFullScreen] = useState(false); // New state for full-screen mode
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState(comment.media ?? []);

  const [popup, setPopup] = useState({on: false, message: ''});

  const formik = useFormik({
    initialValues: {
      content: comment.content ?? '',
      media: comment.media ?? [],
      priority: comment.priority ?? false,
      // metions: comment.mentions,
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = comment;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        if (files?.length) {
          update.media = files;
        }

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setIsExpanded(false);
          onClose();

          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isOpen && inputRef.current) {
      setIsExpanded(true);
    }
  }, [isOpen]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const replyContent = (
    <div
      className={`${
        isFullScreen ? 'modal-reply-thread' : 'content-reply-popup'
      } ${isExpanded ? 'expanded' : ''}`}>
      <div className="popup-header">
        <h3>Edit</h3>
        <div className="flex-row">
          <button className="expand-button" onClick={toggleFullScreen}>
            {isFullScreen ? <RxExitFullScreen /> : <RxEnterFullScreen />}
          </button>
          {!isFullScreen && (
            <button className="close-button" onClick={onClose}>
              <RxCross2 />
            </button>
          )}
        </div>
      </div>
      <div className="popup-content">
        <ChatInput
          thread_id={comment.thread_id}
          formik={formik}
          inputRef={inputRef}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          quoteText={''}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
        />
      </div>
      <ChatActionButtons
        formik={formik}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
        isEdit={true}
      />
    </div>
  );

  return (
    <>
      {!isFullScreen && replyContent}
      <SimpleDisplayModal active={isFullScreen} setActive={setIsFullScreen}>
        {replyContent}
      </SimpleDisplayModal>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export default ReplyToComment;
