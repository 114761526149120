import React, {useEffect, useState} from 'react';
import {GoPerson} from 'react-icons/go';
import {useNavigate} from 'react-router-dom';
import {timeSince} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import useMessenger from '../../hooks/use-collaborator';
import useLoader from '../../hooks/use-loader';
import {BadgeDotRelative} from '../badge/badge';
import Tooltip from '../tooltip/tooltip';
import {Label} from './labels';
import './styles.css';

export const ChainItem = props => {
  const navigate = useNavigate();
  const {item, active, ...rest} = props;
  const {loadProfiles} = useLoader();
  const {
    state: {id, profile, profiles, groups},
  } = useAuth();
  const {membersToOther} = useMessenger();

  const {last_message, last_timestamp, last_sender} = item;

  const [unopened, setUnopened] = useState(0);

  useEffect(() => {
    if (last_sender !== id) setUnopened(1);
  }, [last_sender]);

  const pretty_updated_date = timeSince(last_timestamp);
  const {group_ids: cur_groups} = profile || {};

  const {name: pretty_name, other} = membersToOther(item);
  const [groupTag, setGroupTag] = useState(null);

  useEffect(() => {
    loadProfiles([other]);
  }, [other]);

  useEffect(() => {
    const {group_ids: other_groups} = profiles?.[other] || {};

    if (!other_groups || !cur_groups) {
      return;
    }
    let shared_groups = cur_groups?.filter(x => {
      return other_groups?.includes(x);
    });
    let primary_group = shared_groups?.[0] || '';
    if (groups?.[primary_group]) {
      setGroupTag(groups[primary_group]?.name || null);
    }
  }, [cur_groups, groups, other, profile, profiles]);
  if (!item) {
    return null;
  }

  return (
    <div className={`chain-container ${active && 'active'}`} {...rest}>
      <div className="flex-row justify-between align-center">
        {groupTag ? <Label label={groupTag} color={'#4657c0'} /> : <div />}

        <Tooltip text={'View Profile'}>
          <span className="flex-column align-center padding-right4">
            <GoPerson
              onClick={e => {
                e.stopPropagation();
                navigate(`/feed/profiles/detail/${other}`);
              }}
              size={'18px'}
              style={{marginRight: '4px'}}
              className="clickable"
            />
            <p className="text-secondary text-10">{'Profile'}</p>
          </span>
        </Tooltip>
      </div>
      <BadgeDotRelative active={unopened} />
      <p className="text-left text-secondary text-10">{pretty_updated_date}</p>

      <p className="text-left">{pretty_name}</p>

      <p className="text-left text-secondary">
        {last_message || 'No Messages'}
      </p>
    </div>
  );
};
