import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {FormikSearchableDropDown} from '../inputs/drop-down';
import {FormikTextfield} from '../inputs/textfields';
import {SpinningIndicator} from '../loading/loading-indicator';
import {Modal} from './modal';

export const AddLinkModal = ({active, setActive}) => {
  if (active) {
    return <AddLinkModalContent active={active} setActive={setActive} />;
  }

  return null;
};

const AddLinkModalContent = ({active, setActive}) => {
  const {generateOfficialURL} = useStringFormatter();
  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      text: '',
      url: '',
      type: '',
    },
    validationSchema: Yup.object({
      text: Yup.string().required(),
      url: Yup.string()
        .trim()
        .matches(/^(https?:\/\/)?([\w\d-]+\.)+\/?/, 'Please enter a valid URL'),
      type: Yup.string().required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {text, url, type} = values;
        const official = generateOfficialURL(url);
        const {social_links} = profile;
        const update_links = [
          ...(social_links || []),
          {text, url: official, type},
        ];
        await updateProfile({id, social_links: update_links});
        setActive(false);
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="pretty-form-group">
        <h4>Add a Personal Link</h4>
        <p className="text-left text-secondary">
          Connect your social profiles to help others find you.
        </p>
        <div className="grid-container">
          <div className="grid-6">
            <FormikTextfield
              formik={formik}
              placeholder=""
              name="text"
              header="Display Text"
            />
          </div>
          <div className="grid-6">
            <FormikSearchableDropDown
              formik={formik}
              header="Link Type"
              name="type"
              items={[
                {key: 'Calendly', value: 'calendly'},
                {key: 'LinkedIn', value: 'linkedin'},
                {key: 'Twitter', value: 'Twitter'},
                {key: 'Other', value: 'other'},
                // TODO: add more link types here
              ]}
            />
          </div>
        </div>
        <FormikTextfield
          formik={formik}
          placeholder=""
          name="url"
          header="URL"
        />
        {formik.errors.submit && (
          <p className="error">{formik.errors.submit}</p>
        )}
        <div className="flex justify-center">
          {formik.isSubmitting ? (
            <SpinningIndicator />
          ) : (
            <button
              className="button-container"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.handleSubmit();
              }}>
              ADD LINK
            </button>
          )}
        </div>
      </span>
    </Modal>
  );
};

export default AddLinkModal;
