import React, {useEffect, useMemo, useState} from 'react';
import {RxBell, RxChevronRight, RxDotFilled} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import {timeSince} from '../../api/dates';
import {Textfield} from '../../components/inputs/textfields';
import ThreadButtons from '../../components/inputs/ThreadButtons';
import {ProfileImage} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import MagicMarkdown from '../../components/markdown/magic-markdown';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {
  checkPermissionStatus,
  requestNotificationPermission,
} from '../../utils/utils';
import IntegratedAIChatPopup from './AIChat';
import './styles.css';
import ThreadDetail from './thread-detail';

const ThreadsOverview = () => {
  const {thread_id, content_id} = useParams();

  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {
    state: {id: userId, groups, current_group},
  } = useAuth();
  const {
    state: {
      thread_ids,
      threads,
      threads_loaded,
      thread_subs,
      subscriptions,
      thread_content,
    },
    getThreads,
    getThreadSubscriptions,
    attachThreadListener,
    getContents,
  } = useThreads();

  const [current, setCurrent] = useState(thread_id || null);
  const [focusedContent, setContent] = useState(content_id || null);
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [index, setIndex] = useState(2);

  const [isAIChatOpen, setIsAIChatOpen] = useState(false);

  const [searched_ids, setSearched] = useState([]);
  const [search, setSearch] = useState('');
  const [searchThreads, setSearchThreads] = useState('');
  const thread = threads?.[current] ?? {};

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const query = {
        filter: {
          subscribers: {contains: userId},
          status: {eq: 'active'},
        },
      };
      const {success, data} = await getThreads(query);
      await getThreadSubscriptions({
        user_id: userId,
      });
      setLoading(false);
      setLoadingContent(true);
      if (success && data?.length) {
        if (!thread_id) {
          setCurrent(data[0]);
        }
        // LOAD ALL CONTENTS FOR THREADS
        await Promise.all(
          data.map(thread_id => {
            const query = {thread_id, filter: {status: {eq: 'active'}}};
            return getContents(query, thread_id);
          }),
        );
      }
      setLoadingContent(false);
    };
    // LOAD IN DATA
    if (!threads_loaded) {
      load();
    } else {
      // setCurrent(thread_ids?.[0] ?? null);
    }
  }, []);

  // ATTACH LISTENERS
  useEffect(() => {
    if (!thread_ids?.length) {
      return;
    }

    thread_ids.forEach(thread_id => {
      if (subscriptions[thread_id] === undefined) {
        attachThreadListener(
          {filter: {thread_id: {eq: thread_id}, status: {eq: 'active'}}},
          thread_id,
          userId,
        );
      }
    });
  }, [thread_ids]);

  useEffect(() => {
    // LOAD GROUPS MEMBERS
    if (current) {
      const {group_id} = thread;
      const {members} = groups?.[group_id] ?? {};

      loadProfiles(members);
    }
  }, [current]);

  useEffect(() => {
    if (search) {
      const keys = Object.keys(thread_content);
      const filtered = keys.filter(content_id => {
        const {content} = thread_content?.[content_id] ?? {};

        return content?.includes(search);
      });

      setSearched(filtered);
    } else {
      setContent([]);
    }
  }, [search]);

  const groupedThreads = useMemo(() => {
    const groups = {
      owned: [],
      admin: [],
      subscribed: [],
      direct_messages: [],
    };

    thread_ids
      .filter(thread_id => {
        const thread = threads?.[thread_id] ?? {};

        if (
          searchThreads &&
          !thread?.title?.includes(searchThreads) &&
          !thread?.content?.includes(searchThreads)
        ) {
          return false;
        }

        if (current_group === 'all') {
          return true;
        }

        if (thread?.group_id === current_group) {
          return true;
        }

        return false;
      })
      .forEach((threadId, i) => {
        if (i && !current) {
          setCurrent(threadId);
        }
        const thread = threads[threadId];
        const {owner_id, admins, subscribers, type} = thread;
        if (type === 'direct') {
          groups.direct_messages.push(threadId);
        } else {
          if (owner_id === userId) {
            groups.owned.push(threadId);
          } else if (admins?.includes(userId)) {
            groups.admin.push(threadId);
          } else if (subscribers?.includes(userId)) {
            groups.subscribed.push(threadId);
          }
        }
      });

    const sorted = {};
    Object.keys(groups).forEach(key => {
      const ids = groups[key];
      const sort = ids.sort((a, b) => {
        const a_thread = threads?.[a] ?? {};
        const b_thread = threads?.[b] ?? {};
        const a_sub = thread_subs?.[a] ?? {};
        const b_sub = thread_subs?.[b] ?? {};
        const a_priority = a_sub?.priority;
        const b_priority = b_sub?.priority;

        // If both have priority, sort by priority timestamp
        if (a_priority && b_priority) {
          return b_priority - a_priority;
        }

        // If only one has priority, it should come first
        if (a_priority) return -1;
        if (b_priority) return 1;

        // If neither has priority, sort based on thread's updated field
        return b_thread?.updated - a_thread?.updated;
      });
      sorted[key] = sort;
    });

    return sorted;
  }, [thread_ids, userId, current_group, thread_subs, searchThreads]);

  return (
    <div className="page-container">
      <div className="threads-overview">
        <div className="threads-container">
          <div className="threads-list">
            <div className="threads-header">
              <h4>Threads</h4>
              <button
                className="add-thread-button"
                onClick={() => navigate('/threads/create')}>
                <p className="text-secondary text-10">New Thread</p>
              </button>
            </div>
            <div className="mention-menu-group">
              <ThreadButtons
                index={index}
                setIndex={setIndex}
                setIsAIChatOpen={setIsAIChatOpen}
                isAIChatOpen={isAIChatOpen}
              />
            </div>
            <div className="thread-items">
              {loading ? (
                <SpinningIndicator />
              ) : (
                <>
                  <PinnedThreadGroup
                    title="Pinned Threads"
                    threadIds={[
                      ...groupedThreads.owned,
                      ...groupedThreads.admin,
                      ...groupedThreads.subscribed,
                      ...groupedThreads.direct_messages,
                    ]}
                    current={current}
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    index={index}
                  />

                  {groupedThreads.owned.length > 0 && (
                    <ThreadGroup
                      title="Your Threads"
                      threadIds={groupedThreads.owned}
                      current={current}
                      setCurrent={setCurrent}
                      setIndex={setIndex}
                      index={index}
                    />
                  )}
                  {groupedThreads.admin.length > 0 && (
                    <ThreadGroup
                      title="Administrator"
                      threadIds={groupedThreads.admin}
                      current={current}
                      setCurrent={setCurrent}
                      setIndex={setIndex}
                      index={index}
                    />
                  )}
                  {groupedThreads.subscribed.length > 0 && (
                    <ThreadGroup
                      title="Collaborator"
                      threadIds={groupedThreads.subscribed}
                      current={current}
                      setCurrent={setCurrent}
                      setIndex={setIndex}
                      index={index}
                    />
                  )}
                  {groupedThreads.direct_messages.length > 0 && (
                    <ThreadGroup
                      title="Direct Threads"
                      threadIds={groupedThreads.direct_messages}
                      current={current}
                      setCurrent={setCurrent}
                      setIndex={setIndex}
                      index={index}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="thread-detail ">
            {/* <Textfield
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search all threads..."
              onFocus={() => {
                setIndex('search');
              }}
            />
            <br /> */}
            {index === 'mentions' && (
              <Mentions
                setCurrent={setCurrent}
                setIndex={setIndex}
                setContent={setContent}
              />
            )}
            {index === 'search' && (
              <Search
                setCurrent={setCurrent}
                setIndex={setIndex}
                setContent={setContent}
                content_ids={searched_ids}
              />
            )}
            {index === 2 && (
              <ThreadDetail
                id={current}
                focused_content={focusedContent}
                setFocusedContent={setContent}
                setCurrent={setCurrent}
              />
            )}
          </div>
        </div>
      </div>

      {isAIChatOpen && (
        <IntegratedAIChatPopup
          thread={thread}
          onClose={() => setIsAIChatOpen(false)}
        />
      )}
    </div>
  );
};

const PinnedThreadGroup = ({
  title,
  threadIds,
  current,
  setCurrent,
  setIndex,
  index,
}) => {
  const {
    state: {profile},
  } = useAuth();
  const {
    state: {threads, thread_subs},
  } = useThreads();
  const {prettyName} = useStringFormatter();

  const formatThreadTitle = (threadTitle, admins) => {
    if (!threadTitle.includes(prettyName(profile))) {
      return threadTitle;
    }
    const names = threadTitle.split(' and ').map(name => name.trim());
    const filteredNames = names.filter(name => {
      return name !== prettyName(profile);
    });

    if (filteredNames.length === 0) {
      // If all names were filtered out (shouldn't happen in normal cases), return the original title
      return threadTitle;
    } else if (filteredNames.length === 1) {
      // If only one name remains, return it
      return filteredNames[0];
    } else {
      // If multiple names remain (in case of identical names), join them
      return filteredNames.join(' and ');
    }
  };

  return (
    <div className="thread-group">
      <h5 className="thread-group__title">{title}</h5>
      {threadIds.map(threadId => {
        let curThread = threads[threadId];
        const sub = thread_subs?.[threadId] ?? null;
        if (!sub?.priority) {
          return null;
        } else {
          return (
            <PinnedThreadItem
              key={threadId}
              item={threads[threadId]}
              formatThreadTitle={formatThreadTitle(
                curThread.title,
                curThread.admins,
              )}
              onClick={() => {
                setIndex(2);
                setCurrent(threadId);
                const permissions = checkPermissionStatus();
                if (permissions === 'default') {
                  requestNotificationPermission();
                }
              }}
              isActive={index === 2 && current === threadId}
            />
          );
        }
      })}
    </div>
  );
};
const ThreadGroup = ({
  title,
  threadIds,
  current,
  setCurrent,
  setIndex,
  index,
}) => {
  const {
    state: {profile},
  } = useAuth();
  const {
    state: {threads, thread_subs},
  } = useThreads();
  const {prettyName} = useStringFormatter();

  const formatThreadTitle = (threadTitle, admins) => {
    if (!threadTitle.includes(prettyName(profile))) {
      return threadTitle;
    }
    const names = threadTitle.split(' and ').map(name => name.trim());
    const filteredNames = names.filter(name => {
      return name !== prettyName(profile);
    });

    if (filteredNames.length === 0) {
      // If all names were filtered out (shouldn't happen in normal cases), return the original title
      return threadTitle;
    } else if (filteredNames.length === 1) {
      // If only one name remains, return it
      return filteredNames[0];
    } else {
      // If multiple names remain (in case of identical names), join them
      return filteredNames.join(' and ');
    }
  };

  return (
    <div className="thread-group">
      <h5 className="thread-group__title">{title}</h5>
      {threadIds.map(threadId => {
        let curThread = threads[threadId];

        return (
          <ThreadItem
            key={threadId}
            item={threads[threadId]}
            formatThreadTitle={formatThreadTitle(
              curThread.title,
              curThread.admins,
            )}
            onClick={() => {
              setIndex(2);
              setCurrent(threadId);
              const permissions = checkPermissionStatus();
              if (permissions === 'default') {
                requestNotificationPermission();
              }
            }}
            isActive={index === 2 && current === threadId}
          />
        );
      })}
    </div>
  );
};

const Search = ({setIndex, setCurrent, setContent}) => {
  const {
    state: {thread_content, threads},
  } = useThreads();

  const [search, setSearch] = useState();
  const [content_ids, setContentIDS] = useState([]);

  useEffect(() => {
    if (search) {
      const keys = Object.keys(thread_content);
      const filtered = keys.filter(content_id => {
        const {content} = thread_content?.[content_id] ?? {};

        return content?.includes(search);
      });

      setContentIDS(filtered);
    } else {
      setContent([]);
    }
  }, [search]);

  return (
    <div className="mentions-container pretty-form-group">
      <Textfield
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Search all threads..."
      />
      <br />
      <div className="mentions-list">
        {content_ids.map(content_id => {
          return (
            <MentionItem
              key={content_id}
              item={thread_content[content_id]}
              thread={threads[thread_content[content_id].thread_id]}
              onClick={() => {
                setIndex(2);
                setCurrent(thread_content[content_id].thread_id);
                setContent(content_id);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const Mentions = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {thread_content, threads},
  } = useThreads();
  const [mentionIds, setMentionIds] = useState([]);

  useEffect(() => {
    const keys = Object.keys(thread_content);
    const mentioned = keys
      .filter(content_id => {
        const {mentions} = thread_content?.[content_id] ?? {};
        return mentions?.includes(id);
      })
      .sort((a, b) => {
        const a_content = thread_content?.[a] ?? {};
        const b_content = thread_content?.[b] ?? {};
        return b_content?.created - a_content.created;
      });
    setMentionIds(mentioned);
  }, [thread_content, id]);

  return (
    <div className="mentions-container">
      <h3 className="mentions-header">
        {/* <RxBell className="mentions-icon" /> */}
        Your Mentions
      </h3>
      {mentionIds.length ? (
        <div className="mentions-list">
          {mentionIds.map(content_id => (
            <MentionItem
              key={content_id}
              item={thread_content[content_id]}
              thread={threads[thread_content[content_id].thread_id]}
              onClick={() => {
                setIndex(2);
                setCurrent(thread_content[content_id].thread_id);
                setContent(content_id);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="mentions-empty">
          <RxBell className="mentions-empty-icon" />
          <p>No mentions yet</p>
          <p>When someone mentions you in a thread, you'll see it here.</p>
        </div>
      )}
    </div>
  );
};

const MentionItem = ({item, thread, onClick}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();

  const {
    state: {profiles},
  } = useAuth();
  const {owner_id, content, created} = item;
  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {title: threadTitle} = thread;

  useEffect(() => {
    if (!user) {
      loadProfiles([owner_id]);
    }
  }, []);
  return (
    <div className="mention-item" onClick={onClick}>
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{height: '32px', width: '32px'}}
          onClick={() => {
            navigate(`/feed/profiles/detail/${owner_id}`);
          }}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {threadTitle}
          </h3>

          <p className="mention-time">{timeSince(created)} ago</p>
        </div>
      </div>
      <p className="content-body">
        <MagicMarkdown content={content} />
      </p>
      <div className="mention-footer">
        <span className="mention-view">View thread</span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
};

const PinnedThreadItem = ({item, onClick, isActive, formatThreadTitle}) => {
  const {id, title, content, updated, last_sender, logo} = item;

  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  const new_content =
    user_id !== last_sender && (!sub?.last_read || sub?.last_read < updated);

  return (
    <div
      className={`thread-item ${isActive ? 'active' : ''}`}
      onClick={onClick}>
      {new_content ? <RxDotFilled size={'25px'} color="red" /> : null}
      <span className={new_content ? 'text-bold padding-left8' : ''}>
        <p className="thread-item__title">
          {logo} {formatThreadTitle}
        </p>
      </span>
    </div>
  );
};

const ThreadItem = ({item, onClick, isActive, formatThreadTitle}) => {
  const {id, title, content, updated, last_sender, logo} = item;

  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  const new_content =
    user_id !== last_sender && (!sub?.last_read || sub?.last_read < updated);

  if (sub?.priority) {
    return null;
  }

  return (
    <div
      className={`thread-item ${isActive ? 'active' : ''}`}
      onClick={onClick}>
      {new_content ? <RxDotFilled size={'25px'} color="red" /> : null}
      <span className={new_content ? 'text-bold padding-left8' : ''}>
        <p className="thread-item__title">
          {logo} {formatThreadTitle}
        </p>
      </span>
    </div>
  );
};

export default ThreadsOverview;
