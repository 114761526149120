import React, {useEffect, useState} from 'react';
import {
  RxBell,
  RxCross2,
  RxFile,
  RxInfoCircled,
  RxMagicWand,
  RxPerson,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {ProfileImage} from '../../components/inputs/uploader';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import './styles.css';

const ThreadInfoPopup = ({isOpen, onClose, setCurrent, thread}) => {
  const {capitalize, prettyTag, prettyName, prettyPosition} =
    useStringFormatter();
  const navigate = useNavigate();

  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {
    state: {thread_ids},
    updateThread,
    defaultUpdate,
  } = useThreads();

  const [activeTab, setActiveTab] = useState('about');
  const [loading, setLoading] = useState(false);

  const {title, admins, subscribers, owner_id, content, security_level} =
    thread;

  const isAdmin = admins?.includes(id) || false;

  const {loadProfiles} = useLoader();

  useEffect(() => {
    const fetchMissingProfiles = async () => {
      const allMembers = [owner_id, ...admins, ...subscribers];
      const missingProfiles = allMembers.filter(
        memberId => !profiles[memberId],
      );
      if (missingProfiles.length > 0) {
        setLoading(true);
        await loadProfiles(missingProfiles);
        setLoading(false);
      }
    };

    if (isOpen && (admins.length > 0 || subscribers.length > 0)) {
      fetchMissingProfiles();
    }
  }, [isOpen, owner_id, admins, subscribers, profiles, loadProfiles]);

  if (!isOpen) return null;

  const renderMemberList = (memberList, title) => (
    <div className="thread-info-member-group">
      <h4>{title}</h4>
      {memberList.length === 0 && (
        <p className="text-secondary">No subscribers</p>
      )}
      {memberList.map(memberId => {
        const member = profiles[memberId] || {};
        const cur_pretty_name = prettyName(member);
        const {position, organization_ids} = member;
        const current_org = organization_ids?.length
          ? organizations?.[organization_ids[0]]
          : null;
        const org_name = prettyPosition(current_org);
        return (
          <div
            key={memberId}
            className="thread-info-member-item"
            onClick={() => navigate(`/feed/profiles/detail/${memberId}`)}>
            <ProfileImage
              data={member.profile_image}
              style={{height: '32px', width: '32px'}}
            />
            <div className="thread-info-member-details">
              <span className="thread-info-member-name">{cur_pretty_name}</span>
              <span className="thread-info-member-position">
                {position}
                {org_name ? `, ${org_name}` : null}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );

  const getSecurityTitle = () => {
    switch (security_level) {
      case 'secure':
        return <span className="security-icon secure">Secure</span>;
      case 'private':
        return <span className="security-icon private">Private</span>;
      case 'open':
      default:
        return <span className="security-icon open">Public</span>;
    }
  };
  const getSecurityDescription = () => {
    switch (security_level) {
      case 'secure':
        return (
          <p>
            Secure threads are ideal for sensitive information exchange. These
            threads are non-discoverable and accessible only to explicitly
            authorized participants. Audit logs track all interactions.
          </p>
        );
      case 'private':
        return (
          <p>
            Private threads offer controlled access through invitation only.
            While still encrypted, they remain hidden from public view and
            searches. Suitable for confidential discussions that don't involve
            highly sensitive data.
          </p>
        );
      case 'open':
      default:
        return (
          <p>
            Public threads promote collaboration with anyone. Discoverable by
            authorized platform users and shareable with anyone, they allow for
            broader participation in your projects. Ideal for general
            discussions and knowledge sharing.
          </p>
        );
    }
  };
  const renderAboutTab = () => {
    const owner = profiles[owner_id] || {};
    const ownerName = prettyName(owner);

    return (
      <div className="thread-info-about">
        <div className="thread-info-description">
          <h4>{getSecurityTitle()} Thread Security Level</h4>
          {getSecurityDescription()}
        </div>
        <div className="thread-info-description">
          <h4>Description</h4>
          <p>{content}</p>
        </div>
        <div className="thread-info-owner">
          <h4>Thread Owner</h4>
          <div
            className="thread-info-member-item"
            onClick={() => navigate(`/feed/profiles/detail/${owner_id}`)}>
            <ProfileImage
              data={owner.profile_image}
              style={{height: '32px', width: '32px'}}
            />
            <div className="thread-info-member-details">
              <span className="thread-info-member-name">{ownerName}</span>
              <span className="thread-info-member-position">
                {owner?.position}
              </span>
            </div>
          </div>
        </div>
        {owner_id !== id && (
          <div className="flex-row justify-end">
            <button
              className="thread-info-button  margin-h8"
              onClick={async () => {
                const filtered_subscribers = subscribers?.filter(
                  user_id => user_id !== id,
                );
                const filtered_admins = admins?.filter(
                  user_id => user_id !== id,
                );
                const update = {
                  id,
                  subscribers: filtered_subscribers,
                  admins: filtered_admins,
                };

                await updateThread(update);

                const filtered_threads = thread_ids.filter(
                  thread_id => thread_id !== thread.id,
                );
                const removed = {thread_ids: filtered_threads};
                defaultUpdate(removed);
                setCurrent(null);
                onClose();
              }}>
              Leave Thread
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="thread-info-overlay" onClick={onClose}>
      <div className="thread-info-popup" onClick={e => e.stopPropagation()}>
        <div className="thread-info-header">
          <h3>{title} - Details</h3>
          <button className="thread-info-close-button" onClick={onClose}>
            <RxCross2 />
          </button>
        </div>
        <div className="thread-info-tabs">
          <button
            className={`thread-info-tab-button ${
              activeTab === 'about' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('about')}>
            <RxInfoCircled />
          </button>
          <button
            className={`thread-info-tab-button ${
              activeTab === 'members' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('members')}>
            <RxPerson />
          </button>
          <button
            className={`thread-info-tab-button ${
              activeTab === 'files' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('files')}>
            <RxFile />
          </button>
          <button
            className={`thread-info-tab-button ${
              activeTab === 'edit' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('edit')}>
            <RxBell />
          </button>
          <button
            className={`thread-info-tab-button ${
              activeTab === 'assistant' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('assistant')}>
            <RxMagicWand />
          </button>
        </div>
        <div className="thread-info-content">
          {loading ? (
            <p>Loading information...</p>
          ) : (
            <>
              {activeTab === 'about' && renderAboutTab()}
              {activeTab === 'members' && (
                <div className="thread-info-members-list">
                  {renderMemberList(admins, 'Administrators')}
                  {renderMemberList(subscribers, 'Collaborators')}
                </div>
              )}
            </>
          )}
          {activeTab === 'files' && <FileSettings thread={thread} />}
          {activeTab === 'edit' && <NotificationSettings thread={thread} />}
          {activeTab === 'assistant' && <AssistantSettings thread={thread} />}
        </div>
        <div className="thread-info-button-group">
          <div className="thread-info-left-buttons">
            <button onClick={onClose}>Close</button>
          </div>
          <div className="thread-info-right-buttons">
            <button disabled>
              <RxFile />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const FileSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      {/* <div className="grid-8"> */}
      <p className="text-secondary text-center">Files coming soon</p>
      {/* </div> */}
      {/* <div className="grid-4"></div> */}
    </div>
  );
};
const NotificationSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      {/* <div className="grid-8"> */}
      <p className="text-secondary text-center">
        Notification settings coming soon
      </p>
      {/* </div> */}
      {/* <div className="grid-4"></div> */}
    </div>
  );
};

const AssistantSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      <p className="text-secondary text-center">
        Thread Assistants available on request.
      </p>
      <p className="text-secondary text-center">
        Reach out to the PublicMind team to enable for this thread.
      </p>
    </div>
  );
};

export default ThreadInfoPopup;
