import React, {useEffect, useState} from 'react';
import {useAuth} from '../../hooks/use-auth';
import {SpinningIndicator} from '../loading/loading-indicator';
import {generateSSOToken} from '../../api/canny';

const BoardToken = '98b2aa39-1045-38bd-4d5b-5e1a7f4a37be';
const CannyWidget = () => {
  const {
    state: {id, profile, email},
  } = useAuth();
  const [ssoToken, setSsoToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const userData = {
          email: profile.email,
          id: profile.sub,
          name: `${profile.first_name} ${profile.last_name}`,
        };

        const data = await generateSSOToken(JSON.stringify(userData));
        setSsoToken(data.token);
      } catch (error) {
        console.error('Error fetching SSO token:', error);
      }
    };

    fetchToken();
  }, [id, profile, email]);

  useEffect(() => {
    if (ssoToken) {
      setLoading(true);
      const script = document.createElement('script');
      script.src = 'https://canny.io/sdk.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.Canny('render', {
          boardToken: BoardToken, // Replace with your actual board token
          basePath: '/settings/feedback',
          ssoToken: ssoToken,
          onLoadCallback: () => setLoading(false),
          theme: 'light',
        });
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [ssoToken]);

  //TODO: add button to go to full canny roadmap
  return (
    <div>
      {loading && <SpinningIndicator />}
      <div data-canny />

      <div className="flex-column align-center">
        <button
          onClick={() => window.open('https://publicmind.canny.io', '_blank')}>
          Full Roadmap + Feature Requests
        </button>
        <p className="text-secondary padding-bottom16">
          Note this link is view only. You can only interact with our Roadmap on
          this page.
        </p>
      </div>
    </div>
  );
};

export default CannyWidget;
