import React, {useEffect, useState} from 'react';
import {RxLockClosed, RxLockOpen1, RxLockOpen2, RxShare1} from 'react-icons/rx';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {dateToTimestamp, readableDateandTime, timeSince} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import constants from '../../components/constants';
import CreateComment from '../../components/inputs/create-comment';
import {SortDropdown} from '../../components/inputs/drop-down';
import ReplyToComment from '../../components/inputs/reply-to-comment';
import {Textfield} from '../../components/inputs/textfields';
import {ProfileImage} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {
  EditThreadModal,
  EditThreadPermissionsModal,
} from '../../components/modal/thread-modals';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';
import './styles.css';
import ThreadMessageGroup from './thread-message-group';
import ThreadInfoPopup from './ThreadInfoPopup';

const ThreadDetail = ({id, focused_content, setFocusedContent, setCurrent}) => {
  if (!id) {
    return null;
  }

  return (
    <ThreadDetailContent
      id={id}
      focused_content={focused_content}
      setFocusedContent={setFocusedContent}
      setCurrent={setCurrent}
    />
  );
};

const ThreadDetailContent = ({
  id,
  focused_content,
  setFocusedContent,
  setCurrent,
}) => {
  const {copyString} = useUtilities();
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {threads, content_ids, thread_content, thread_subs},
    getContents,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const thread = threads?.[id] ?? {};
  const created_date = readableDateandTime(thread?.updated);
  const sub = thread_subs?.[id] ?? null;

  const {
    title,
    content,
    admins,
    subscribers,
    updated,
    last_sender,
    last_content,
    type,
    security_level,
    ai_assisted,
    logo,
  } = thread;

  const editable = admins?.includes(user_id) ?? false;
  const writable = true; //subscribers?.includes(user_id) ?? false

  const message_ids = content_ids?.[id] ?? [];

  const [initialSub, setInitial] = useState({...(sub || {})});
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState(null);
  const [curQuote, setCurQuote] = useState(null);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState({value: 'recent', label: 'Recent'});
  const [sortedContent, setContent] = useState([]);
  const [search, setSearch] = useState('');
  const [aiEnabled, setAiEnabled] = useState(ai_assisted || false);
  const [popup, setPopup] = useState({on: false, message: ''});

  const setQuote = (contentId, text) => {
    setCurQuote(text);
    setReply(contentId);
  };

  // HANDLE SORTING AND FILTERING
  useEffect(() => {
    const first_content = Object.keys(message_ids)
      .filter(parent_id => {
        if (!search) {
          return true;
        }
        let contains = 0;
        const parent = thread_content?.[parent_id];
        const replies = message_ids[parent_id];
        if (parent?.content?.includes(search)) {
          contains += 1;
        }
        if (replies?.length) {
          replies?.forEach(reply_id => {
            const reply = thread_content?.[reply_id] ?? {};

            if (reply?.content?.includes(search)) {
              contains += 1;
            }
          });
        }
        return contains;
      })
      .sort((a, b) => {
        const a_content = thread_content?.[a] ?? {};
        const b_content = thread_content?.[b] ?? {};

        const a_priority = a_content?.priority ? 1 : 0;
        const b_priority = b_content?.priority ? 1 : 0;

        const findRelevance = i => {
          const i_content = thread_content?.[i] ?? {};
          const replies = message_ids[i];
          let sum = 0;

          if (i_content.owner_id === user_id) {
            sum += 1;
          }

          if (replies?.length) {
            replies.forEach(reply_id => {
              const reply = thread_content?.[reply_id] ?? {};

              if (reply.owner_id === user_id) {
                sum += 1;
              }
            });
          }
          return sum;
        };

        const sortTime = () => {
          const a_created = a_content?.created;
          const b_created = b_content?.created;
          if (a_created === undefined && b_created === undefined) {
            return 0;
          }

          // If a_created is missing, b should come first
          if (a_created === undefined) {
            return 1;
          }

          // If b_created is missing, a should come first
          if (b_created === undefined) {
            return -1;
          }
          return b_content?.created - a_content.created;
        };

        switch (sort.value) {
          case 'active':
            if (a_priority !== b_priority) {
              return b_priority - a_priority;
            }

            const a_relevance = findRelevance(a);
            const b_relevance = findRelevance(b);

            // If relevance scores are different, sort by relevance
            if (b_relevance !== a_relevance) {
              return b_relevance - a_relevance;
            }
            return sortTime();
          default:
            if (a_priority !== b_priority) {
              return b_priority - a_priority;
            }
            return sortTime();
        }
      });
    setContent(first_content);
  }, [content_ids?.[id], sort, search]);

  // LOAD IN CONTENT - SHOULD ALWAY BE PRE-LOADED NOW
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const query = {thread_id: id};
      await getContents(query, id);
      setLoading(false);
    };

    if (id && content_ids[id] === undefined) {
      load();
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    // SAVE THE INITIAL SUB BEFORE UPDATING IT
    setInitial(sub);

    const new_content = !sub?.last_read || sub?.last_read < updated;

    // IF NOT ACTIVE OR NO NEW CONTENT TO HAVE SEEN IGNORE
    if (!new_content) {
      return;
    }

    if (sub) {
      const update = {id: sub.id, thread_id: id, last_read: dateToTimestamp()};

      updateThreadSubscription(update);
    } else {
      const created = {
        id: v4(),
        user_id,
        thread_id: id,
        status: null,
        last_read: dateToTimestamp(),
      };

      createThreadSubscription(created);
    }
  }, [id]);

  // IF THE LAST SENDER UPDATE THE INITIAL SUB VALUE
  useEffect(() => {
    if (last_sender === user_id) {
      setInitial(sub);
    }
  }, [last_sender, last_content, sub?.last_read]);

  return (
    <>
      <div className="thread-header">
        <h3>
          {logo} {title}
        </h3>

        <div className="flex-row">
          {/* <button
            className="thread-info-button"
            onClick={() => setIsInfoPopupOpen(true)}>
            <RxBell />
          </button> */}

          <button
            className="thread-info-button  margin-h8"
            onClick={() => setIsInfoPopupOpen(true)}>
            About
          </button>
          <button
            className="thread-info-button"
            onClick={async () => {
              const update = {
                id: sub?.id,
                thread_id: id,
                priority: sub?.priority ? null : `${dateToTimestamp()}`,
              };

              await updateThreadSubscription(update);
            }}>
            {sub?.priority ? 'Unpin' : 'Pin'}
          </button>
        </div>
      </div>
      <p className="thread-content">
        {content?.slice(0, 250)} {content?.length > 250 ? '...' : ''}
      </p>
      <div className="thread-actions">
        {security_level === 'secure' || type === 'direct' ? (
          <RxLockClosed
            className="security-icon-small secure"
            onClick={() => setIsInfoPopupOpen(true)}
          />
        ) : security_level === 'private' ? (
          <RxLockOpen1
            className="security-icon-small private"
            onClick={() => setIsInfoPopupOpen(true)}
          />
        ) : (
          <RxLockOpen2
            className="security-icon-small open"
            onClick={() => setIsInfoPopupOpen(true)}
          />
        )}
        {editable && (
          <>
            <button className="thread-action-button" disabled>
              <RxShare1 />
            </button>
            {type === 'thread' && (
              <button
                className="thread-action-button"
                disabled={!editable}
                onClick={() => setEditPermissions(!editPermissions)}>
                Add People
              </button>
            )}
            {(true || security_level === 'open') && (
              <button
                className="thread-action-button"
                onClick={() => {
                  const link = `${constants.root_url}/thread/invite/${id}`;
                  copyString(link);
                  setPopup({
                    on: true,
                    message: 'Invite Link Copied',
                  });
                }}>
                Copy Invite Link
              </button>
            )}
            {editable && type !== 'direct' && (
              <button
                className="thread-action-button"
                onClick={() => setEdit(!edit)}>
                Edit Thread
              </button>
            )}
            {editable && (
              <button
                className="thread-action-button"
                onClick={() => setIsInfoPopupOpen(true)}>
                Assistant: {aiEnabled ? 'On' : 'Off'}
              </button>
            )}
          </>
        )}
        <p className="text-secondary text-10">Last Edit: {created_date}</p>
      </div>
      {writable && <CreateComment id={id} />}
      <div className="sort-section">
        <label>Sort by:</label>
        <SortDropdown
          currentSort={sort}
          onSortChange={newSort => setSort(newSort)}
        />
        <div className="margin-h8">
          <span className="search-section">
            <Textfield
              value={search}
              type="text"
              placeholder="Search..."
              onChange={e => setSearch(e.target.value)}
              header=""
            />
          </span>
        </div>
      </div>
      <div className="thread-message-container">
        {loading && <SpinningIndicator />}
        {sortedContent.map(content_id => (
          <ThreadMessageGroup
            key={content_id}
            contentId={content_id}
            messageIds={message_ids[content_id]?.sort((a, b) => {
              const a_content = thread_content?.[a] ?? {};
              const b_content = thread_content?.[b] ?? {};
              // OLDEST FIRST
              return a_content?.created - b_content.created;
            })}
            threadContent={thread_content}
            onReply={setReply}
            onQuote={setQuote}
            focusedContent={focused_content}
            sub={initialSub}
            setFocusedContent={setFocusedContent}
          />
        ))}
      </div>
      {writable && reply && (
        <ReplyToComment
          id={id}
          parent={reply}
          onClose={() => {
            setReply(null);
            setCurQuote(null);
          }}
          isOpen={!!reply}
          quoteText={curQuote}
        />
      )}
      <ThreadInfoPopup
        isOpen={isInfoPopupOpen}
        onClose={() => setIsInfoPopupOpen(false)}
        files={[]} // You'll need to implement file fetching logic
        thread={thread}
        setCurrent={setCurrent}
      />
      <EditThreadModal active={edit} setActive={setEdit} thread={thread} />
      <EditThreadPermissionsModal
        active={editPermissions}
        setActive={setEditPermissions}
        thread={thread}
      />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export const ContentContent = ({item}) => {
  const navigate = useNavigate();
  const {
    state: {id, profiles},
  } = useAuth();
  const {copyString} = useUtilities();
  const {content, parent, owner_id} = item;
  const [edit, setEdit] = useState(false);
  const owner = owner_id === id;
  const timeSinceString = timeSince(item?.updated);
  const created_date = readableDateandTime(item?.created);

  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {first_name, last_name, position} = user;

  return (
    <div>
      <div className="content-header">
        <ProfileImage
          data={profile_image}
          style={{height: '32px', width: '32px'}}
          onClick={() => {
            navigate(`/feed/profiles/detail/${owner_id}`);
          }}
        />
        <div className="user-info">
          <span className="flex-column">
            <span className="flex-row">
              <h3 className="user-name">{`${first_name} ${last_name}`}</h3>

              <p className="content-time">{timeSinceString}</p>
            </span>

            <p className="user-position">{owner ? 'Thread Owner' : ''}</p>
          </span>
        </div>
      </div>
      <div className="content-body">
        <ReactMarkdown children={content} />
      </div>
    </div>
  );
};

export default ThreadDetail;
