import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';

export const YourReferer = ({cur_id}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();

  const {
    state: {profiles, referral},
    getReferral,
  } = useAuth();

  useEffect(() => {
    getReferral(cur_id);
  }, []);

  useEffect(() => {
    if (referral?.referral_user) {
      loadProfiles([referral?.referral_user]);
    }
  }, [referral]);

  const {referral_user} = referral || {};
  const {first_name, last_name} = profiles?.[referral_user] ?? {};
  const pretty =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`
      : referral_user;

  return (
    <>
      {!!referral ? (
        <span
          key={referral_user}
          onClick={() => {
            navigate(`/feed/profiles/detail/${referral_user}`);
          }}>
          <p
            className="text-left text-bold margin-h16 text-14 clickable"
            key={referral_user}>
            Vouched for by {pretty}
          </p>
        </span>
      ) : (
        <p className="text-left text-bold margin-h16 text-14">
          Invited By: PublicMind Team
        </p>
      )}
    </>
  );
};
